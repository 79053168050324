import { call, put, race, takeEvery, delay } from 'redux-saga/effects';
import api from '../api';
import { postLog } from '../api/apis/logApi';
import modules from '../state';

const LOCAL_STORAGE_UUID = 'e_iv';

function saveLog(error) {
    try {
        if (error?.response?.status?.toString()?.substring(0, 1) !== '4') {
            // only non user specific errors
            const config = { ...(error?.config || {}), headers: '-' }; // headers should be removed
            postLog(localStorage.getItem(LOCAL_STORAGE_UUID), error, config);
        }
        return undefined;
    } catch {
        return undefined;
    }
}

// worker Saga: will be fired on API_CALL_REQUESTED connectivity.actions
function* callApi(callName, args, timeoutMS) {
    let winner;
    let request;
    try {
        request = call(api.calls[callName], args);
        winner = yield race({
            requestResult: request,
            requestTimeout: delay(timeoutMS),
        });
        if (winner.requestTimeout) {
            const error = new Error('Request timeout');
            error.connectivityError = true;
            return { error };
        }

        if (winner.requestResult === null) {
            const error = new Error('Request failed');
            error.connectivityError = true;
            return { error };
        }

        return winner?.requestResult?.data || winner?.requestResult;
    } catch (error) {
        saveLog(error);
        return { error: error?.response?.data?.error };
    }
}

function* startApiCall(action) {
    const { callName, timeoutMS } = action.payload;
    const args = { ...action.payload.args };

    yield put(modules.connectivity.actions.announceApiCall(action.payload.actions.REQUESTED, args));
    const { data, error } = yield* callApi(callName, args, timeoutMS);

    if (error) {
        yield put(modules.connectivity.actions.failApiCall(action.payload.actions.FAILED, args, error));
    } else {
        yield put(modules.connectivity.actions.fulfillApiCall(action.payload.actions.SUCCEEDED, args, data));
    }
}

/*
 Starts startApiCall on each dispatched `API_CALL_REQUESTED` action.
 */
function* watchCallRequests() {
    yield takeEvery(modules.connectivity.actions.API_CALL_REQUESTED, startApiCall);
}

// single entry point to start all Sagas at once
export function* connectivitySaga() {
    yield watchCallRequests();
}
