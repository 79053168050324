import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { LoginWithEmail } from '../LoginWithEmail';
import SocialLogin from '../SocialLogin';
import { DontHaveAccount } from '../DontHaveAccount';

export const Login = props => {
    const { fields, onLogin, onGoToForgotPassword, onGoToSignUp, error, isLoading } = props;
    return (
        <LoginWithEmail
            error={error}
            isLoading={isLoading}
            fields={fields}
            onLogin={onLogin}
            onGoToForgotPassword={onGoToForgotPassword}
        />
    );
};

Login.propTypes = {
    error: PropTypes.string,
    fields: PropTypes.shape({
        email: PropTypes.any,
        password: PropTypes.any,
    }),
    isLoading: PropTypes.bool,
    onGoToForgotPassword: PropTypes.func,
    onGoToSignUp: PropTypes.func,
    onLogin: PropTypes.func,
};
