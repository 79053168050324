import { requestActions } from '../actionsCreator';
import { announceApiCall, requestApiCall } from '../connectivity/connectivityActions';
import { MODULE_NAME } from './notificationConstants';
import api from '../../api';

/**
 * Constants
 */

export const OPEN_NOTIFICATIONS = 'OPEN_NOTIFICATIONS';
export const CLOSE_NOTIFICATIONS = 'CLOSE_NOTIFICATIONS';

export const OPEN_ASYNCNOTIFICATIONS = 'OPEN_ASYNCNOTIFICATIONS';

export const CHAT_MESSAGE_SEND = 'ong.chat.message.send';
export const CHAT_MESSAGE_DELETE = 'ong.chat.message.delete';

// Notifications
export const NOTIF_USER_POST_CREATE = 'ong.user_post.create';
export const NOTIF_INVITE_FRIEND_CREATE = 'ong.invite.friend.create';
export const NOTIF_GROUP_POST_CREATE = 'ong.group_post.create';
export const NOTIF_GROUP_POST_COMMENT_CREATE = 'ong.comment.group.post.created';
export const NOTIF_VOTE = 'ong.vote.voted';
export const NOTIF_SHARE = 'ong.user_post.repost';
export const NOTIF_USER_POST_COMMENT = 'ong.comment.user.post.created';
export const INVITE_GROUP_CREATE = 'ong.invite.group.create';
export const APPLY_ICO_BOUNTY = 'ong.company.ico.bounty.apply';
export const AIRDROP_PARTICIPATE = 'ong.company.ico.airdrop.participate';
export const AIRDROP_PARTICIPATE_CHANGE_STATUS = 'ong.company.ico.airdrop.participate_change_status';
export const NOTIF_BLOCKCHAIN_PROCESSING_RESPONSE = 'ong.blockchain.processing.response';
export const NOTIF_INVITE_ACCEPTED = 'ong.follower.invite.accepted';

export const WITHDRAW_ONG = 'WITHDRAW_ONG';
export const PUBLISH_POST_TO_SOMEE_BLOCKCHAIN = 'PUBLISH_POST_TO_SOMEE_BLOCKCHAIN';
export const VOTE_ON_POST_ON_SOMEE_BLOCKCHAIN = 'VOTE_ON_POST_ON_SOMEE_BLOCKCHAIN';
export const POWER_UP_ON_SOMEE_BLOCKCHAIN = 'POWER_UP_ON_SOMEE_BLOCKCHAIN';
export const POWER_DOWN_ON_SOMEE_BLOCKCHAIN = 'POWER_DOWN_ON_SOMEE_BLOCKCHAIN';
export const TRANSFER_SOMEE_TO_ONG = 'TRANSFER_SOMEE_TO_ONG';
export const TRANSFER_ONG_TO_SOMEE = 'TRANSFER_ONG_TO_SOMEE';
export const TRANSFER_ONG_TO_ETHEREUM = 'TRANSFER_ONG_TO_ETHEREUM';
export const TRANSFER_ETHEREUM_TO_ONG = 'TRANSFER_ETHEREUM_TO_ONG';
export const TRANSFER_SOMEE_TO_ETHEREUM = 'TRANSFER_SOMEE_TO_ETHEREUM';
export const TRANSFER_ETHEREUM_TO_SOMEE = 'TRANSFER_ETHEREUM_TO_SOMEE';

export const NEW_NOTIFICATION = 'ws-counters';

export const GET_NOTIFICATIONS = requestActions(MODULE_NAME, 'GET_NOTIFICATIONS');
export const PATCH_NOTIFY_NOTIFICATIONS = requestActions(MODULE_NAME, 'PATCH_NOTIFY_NOTIFICATIONS');
export const UPDATE_FOLLOW_REQUEST = requestActions(MODULE_NAME, 'UPDATE_FOLLOW_REQUEST');

/**
 * Creators
 */

export const notify = ({ text, type }) => announceApiCall(OPEN_NOTIFICATIONS, { text, type });
export const notifyAsync = ({ actionType, meta, origin }) =>
    announceApiCall(OPEN_ASYNCNOTIFICATIONS, { actionType, meta, origin });
export const hide = () => announceApiCall(CLOSE_NOTIFICATIONS);
export const getNotifications = ({ page = 0 }) => requestApiCall(api.names.getNotifications, { page }, GET_NOTIFICATIONS);
export const patchMarkNotifications = () => requestApiCall(api.names.patchMarkNotifications, { }, PATCH_NOTIFY_NOTIFICATIONS);
export const updateFollowRequest = ({ inviteId, action, notification }) => 
    requestApiCall(api.names.updateFollowRequest, { inviteId, action, notification }, UPDATE_FOLLOW_REQUEST);
