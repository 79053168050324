import produce from 'immer';
import { getErrorFrom } from '../utils';
import * as actions from './notificationActions';
import * as authActions from '../auth/authActions';

const initialState = {
    visible: false,
    type: 'info',
    message: '',
    isAsync: false,
    asyncParams: {},
    asyncType: '',
    notifications: {
        countNewNotifications: 0,
        isLoading: false,
        list: [],
        error: undefined,
        page: 1,
        hasMore: true,
        loadedOnce: false,
    },
};

export const reducer = produce((state = initialState, { type, payload }) => {
    const args = payload?.args;
    switch (type) {
        case actions.OPEN_NOTIFICATIONS: {
            state.visible = true;
            state.type = args.type || 'info';
            state.message = args.text;
            state.isAsync = false;
            break;
        }
        case actions.OPEN_ASYNCNOTIFICATIONS: {
            state.visible = true;
            state.type = 'info';
            state.isAsync = true;
            const { actionType, origin } = payload.args;
            state.asyncParams = { name: origin.username };
            switch (actionType) {
                case actions.NOTIF_USER_POST_CREATE:
                    state.asyncType = 'userpostCreate';
                    break;
                case actions.NOTIF_INVITE_FRIEND_CREATE:
                    state.asyncType = 'friendCreate';
                    break;
                case actions.NOTIF_GROUP_POST_CREATE:
                    state.asyncType = 'grouppostCreate';
                    break;
                case actions.NOTIF_GROUP_POST_COMMENT_CREATE:
                    state.asyncType = 'grouppostComment';
                    break;
                case actions.CHAT_MESSAGE_SEND:
                    state.asyncType = 'messageSend';
                    break;
                case actions.NOTIF_VOTE: {
                    state.asyncType = 'postVote';
                    break;
                }
                case actions.NOTIF_USER_POST_COMMENT: {
                    state.asyncType = 'userpostComment';
                    break;
                }
                case actions.INVITE_GROUP_CREATE: {
                    state.asyncType = 'groupInvite';
                    break;
                }
                case actions.NOTIF_INVITE_ACCEPTED:
                    state.asyncType = 'inviteAccepted';
                    break;
                default:
                    state.visible = false;
                    break;
            }
            break;
        }
        case actions.CLOSE_NOTIFICATIONS: {
            state.visible = false;
            state.type = 'info';
            state.message = '';
            break;
        }
        case actions.GET_NOTIFICATIONS.SUCCEEDED: {
            const data = payload?.data;
            if (data?.unreadCnt?.notify !== undefined)
                state.notifications.countNewNotifications = data?.unreadCnt?.notify;

            if (data && data.list && data.list.length > 0) {
                // Workaround to remove followerinvite after accepted
                data.list = data.list.filter((current, index) => {
                    const previous = data.list[index - 1];
                    const wasAccepted =
                        current?.modelType === 'followerinvite' &&
                        previous?.modelType === 'userfollow' &&
                        current?.entity?.id === previous?.entity?.id;
                    return !wasAccepted;
                });
                const isFirstPage = args.page === 1;
                data.list[data.list.length - 1].position = isFirstPage ? 0 : state.notifications.list.length;
                state.notifications.list = isFirstPage ? data.list : [...state.notifications.list, ...data.list];
            } else {
                state.notifications.hasMore = false;
            }

            state.notifications.isLoading = false;
            state.notifications.error = undefined;
            break;
        }
        case actions.GET_NOTIFICATIONS.REQUESTED: {
            state.notifications.page = payload.args.page;
            state.notifications.isLoading = true;
            state.notifications.error = undefined;
            state.notifications.loadedOnce = true;
            break;
        }
        case actions.GET_NOTIFICATIONS.FAILED: {
            state.notifications.isLoading = false;
            state.notifications.error = getErrorFrom(payload.error);
            break;
        }
        case authActions.LOGOUT: {
            for (const propertyName in state) {
                if (Object.prototype.hasOwnProperty.call(state, propertyName)) {
                    delete state[propertyName];
                }
            }
            for (const propertyName in initialState) {
                if (Object.prototype.hasOwnProperty.call(initialState, propertyName)) {
                    state[propertyName] = initialState[propertyName];
                }
            }
            break;
        }
        default:
            return state;
    }
    return state;
});

export default reducer;
