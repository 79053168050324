import PropTypes from 'prop-types';
import React from 'react';
import styles from './SocialButton.module.scss';

const icons = {
    facebook: (
        <svg
            className={styles.svg}
            width="12"
            height="24"
            viewBox="0 0 12 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 8.25H7.5V5.25C7.5 4.422 8.172 3.75 9 3.75H10.5V0H7.5C5.0145 0 3 2.0145 3 4.5V8.25H0V12H3V24H7.5V12H10.5L12 8.25Z"
                fill="white"
            />
        </svg>
    ),
    linkedin: (
        <svg
            className={styles.svg}
            width="22"
            height="21"
            viewBox="0 0 22 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.89901 6.74762H0.673929C0.486413 6.74762 0.334473 6.89963 0.334473 7.08708V20.6604C0.334473 20.848 0.486413 20.9999 0.673929 20.9999H4.89901C5.08652 20.9999 5.23846 20.848 5.23846 20.6604V7.08708C5.23846 6.89963 5.08652 6.74762 4.89901 6.74762Z"
                fill="white"
            />
            <path
                d="M2.78802 0C1.25069 0 0 1.24934 0 2.78497C0 4.32128 1.25069 5.57109 2.78802 5.57109C4.32413 5.57109 5.57381 4.32121 5.57381 2.78497C5.57387 1.24934 4.32413 0 2.78802 0Z"
                fill="white"
            />
            <path
                d="M15.6458 6.41028C13.9489 6.41028 12.6945 7.13977 11.9336 7.96865V7.08709C11.9336 6.89964 11.7817 6.74763 11.5941 6.74763H7.54789C7.36038 6.74763 7.20844 6.89964 7.20844 7.08709V20.6605C7.20844 20.848 7.36038 20.9999 7.54789 20.9999H11.7637C11.9513 20.9999 12.1032 20.848 12.1032 20.6605V13.9448C12.1032 11.6818 12.7179 10.8001 14.2954 10.8001C16.0135 10.8001 16.15 12.2135 16.15 14.0612V20.6605C16.15 20.848 16.3019 21 16.4894 21H20.7068C20.8944 21 21.0463 20.848 21.0463 20.6605V13.2153C21.0463 9.85026 20.4047 6.41028 15.6458 6.41028Z"
                fill="white"
            />
        </svg>
    ),
    civic: (
        <svg
            className={styles.svg}
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.6282 15.0899C16.1877 14.8191 16.6589 14.3992 16.9883 13.8778C17.3176 13.3565 17.4919 12.7547 17.4913 12.1407C17.4913 10.3289 16.0039 8.8606 14.1707 8.8606C12.3374 8.8606 10.8493 10.3288 10.8493 12.1405C10.8486 12.7547 11.023 13.3568 11.3526 13.8782C11.6822 14.3996 12.1539 14.8194 12.7138 15.0898V19.1408H15.628V15.0899H15.6282ZM14.1708 24.5C8.31014 24.5 3.54281 19.7901 3.54281 14C3.54281 8.20988 8.31085 3.50084 14.1708 3.50084C18.9532 3.50084 23.0069 6.63684 24.3369 10.9371H27.9999C26.5865 4.67838 20.9316 0 14.1708 0C6.34456 0 0 6.26794 0 14C0 21.7321 6.34456 28 14.1708 28C20.9316 28 26.5865 23.3216 28 17.0628H24.3371C23.0061 21.364 18.9532 24.5 14.1708 24.5Z"
                fill="white"
            />
        </svg>
    ),
    google: (
        <svg
            className={styles.svg}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.64543 12.2182C5.64543 11.0102 5.97423 9.87869 6.54545 8.90981V4.79999H2.48507C0.873536 6.91842 0 9.50023 0 12.2182C0 14.9361 0.873536 17.5179 2.48507 19.6364H6.54545V15.5265C5.97423 14.5576 5.64543 13.4261 5.64543 12.2182Z"
                fill="#FBBD00"
            />
            <path
                d="M11.9543 18.3508L9.16364 21.1754L11.9543 24C14.6091 24 17.1308 23.1259 19.2 21.5133V17.4545H15.19C14.2354 18.0282 13.1255 18.3508 11.9543 18.3508Z"
                fill="#0F9D58"
            />
            <path
                d="M6.69617 15.2727L2.61816 19.311C2.93861 19.7231 3.28755 20.1178 3.66512 20.4917C5.94972 22.754 8.98725 24 12.2182 24V18.3853C9.87346 18.3853 7.81844 17.1351 6.69617 15.2727Z"
                fill="#31AA52"
            />
            <path
                d="M24 11.9015C24 11.1747 23.9327 10.4467 23.7999 9.73751L23.6925 9.16364H11.7818V14.7629H17.5784C17.0155 15.8576 16.15 16.7507 15.1021 17.359L19.2169 21.3818C19.6373 21.0654 20.0399 20.7208 20.4213 20.3479C22.7291 18.0918 24 15.0921 24 11.9015Z"
                fill="#3C79E6"
            />
            <path
                d="M16.4758 7.36578L16.9724 7.85455L20.9455 3.94421L20.4489 3.45544C18.1849 1.22718 15.1748 0 11.973 0L9.16364 2.76505L11.973 5.5301C13.6739 5.5301 15.273 6.18201 16.4758 7.36578Z"
                fill="#CF2D48"
            />
            <path
                d="M12.2182 5.61471V0C8.98726 0 5.94975 1.24595 3.66511 3.50826C3.28754 3.88215 2.93861 4.27682 2.61816 4.68899L6.69615 8.72727C7.81846 6.86492 9.87347 5.61471 12.2182 5.61471Z"
                fill="#EB4132"
            />
        </svg>
    ),
};

const SocialButton = props => (
    <button className={`${styles.socialButton} ${styles[props.template]} ${props.className}`}>
        {icons[props.template]} <span className={styles.text}>{props.template}</span>
    </button>
);

SocialButton.propTypes = {
    className: PropTypes.string,
    template: PropTypes.string,
};

export default SocialButton;
