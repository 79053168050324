/* eslint-disable array-callback-return */
import produce from 'immer';
import * as actions from './groupActions';
import { getErrorFrom } from '../utils';

const initialState = {
    isLoading: true,
    error: undefined,
    count: 0,
    success: false,
    groups: [],
    hasMore: true,
    page: 1,
    categories: [],
    subCategories: [],
    createGroup: {
        isLoading: false,
    },
    editGroup: {
        isLoading: false,
    },
    suggestions: {
        isLoading: true,
        error: undefined,
        count: 0,
        success: false,
        groups: [],
        hasMore: true,
        page: 1,
    },
    invitations: {
        isLoading: true,
        error: undefined,
        count: 0,
        success: false,
        groups: [],
        hasMore: true,
        page: 1,
    },
    specificGroup: {
        isLoading: true,
        error: undefined,
        success: false,
        group: {},
        uploadProfileLoading: false,
        uploadBackgroundLoading: false,
    },
    members: {
        isLoading: true,
        error: undefined,
        success: false,
        members: [],
        admins: [],
        allMembers: [],
        allAdmins: [],
        hasMore: true,
        page: 1,
        count: 0,
        allCount: 0,
    },
    membersToInvite: {
        isLoading: true,
        error: undefined,
        success: false,
        members: [],
        hasMore: true,
        page: 1,
    },
};

export const reducer = produce((state = initialState, { type, payload }) => {
    const data = payload?.data;
    switch (type) {
        case actions.SEND_GROUP_INVITATION.SUCCEEDED: {
            const userIndex = state.membersToInvite.members?.findIndex(item => item.id === payload?.args?.userId);

            if (userIndex !== -1) {
                state.membersToInvite.members[userIndex].inviteStatus = 'out';
            }
            break;
        }
        case actions.LIST_USER_TO_INVITE.REQUESTED: {
            if (payload?.args?.reload) {
                state.membersToInvite.members = [];
            }
            state.membersToInvite.page = payload.args.page;

            state.membersToInvite.isLoading = true;
            state.membersToInvite.error = undefined;
            state.membersToInvite.success = false;
            state.membersToInvite.hasMore = true;

            break;
        }
        case actions.LIST_USER_TO_INVITE.SUCCEEDED: {
            state.membersToInvite.isLoading = false;
            state.membersToInvite.error = undefined;
            state.membersToInvite.success = true;

            if (data?.list && data?.list?.length > 0) {
                data.list[data?.list?.length - 1].position = data.list.length;
                state.membersToInvite.members = [...state.membersToInvite.members, ...data?.list];
            } else {
                state.membersToInvite.hasMore = false;
            }
            break;
        }
        case actions.INVITE_ACTION.SUCCEEDED: {
            state.invitations.groups = state.invitations.groups?.filter(
                item => item?.group?.id !== payload?.args?.groupId
            );
            state.invitations.count -= 1;

            if (state?.specificGroup?.group?.id) {
                state.specificGroup.group.role = 'member';
                state.specificGroup.group.inviteStatus = null;
            }

            break;
        }
        case actions.HIDE: {
            if (payload?.type === 'suggestions') {
                state.suggestions.groups = state.suggestions.groups?.filter(item => item?.id !== payload?.groupId);
                state.suggestions.count -= 1;
            }

            if (payload?.type === 'invitations') {
                state.invitations.groups = state.invitations.groups?.filter(
                    item => item?.group?.id !== payload?.groupId
                );
                state.invitations.count -= 1;
            }
            break;
        }
        case actions.CLEAN_SPECIFIC_GROUP: {
            state.specificGroup.isLoading = true;
            state.specificGroup.error = undefined;
            state.specificGroup.success = false;
            state.specificGroup.uploadProfileLoading = false;
            state.specificGroup.uploadBackgroundLoading = false;
            state.specificGroup.group = {};
            state.members.allMembers = [];
            state.members.allAdmins = [];
            state.members.admins = [];
            state.members.count = 0;
            state.members.allCount = 0;
            state.members.members = [];
            state.members.page = 1;
            state.members.hasMore = true;
            break;
        }
        case actions.UPLOAD_PROFILE_PHOTO.REQUESTED: {
            state.specificGroup.uploadProfileLoading = true;
            break;
        }
        case actions.UPLOAD_PROFILE_PHOTO.SUCCEEDED: {
            state.specificGroup.uploadProfileLoading = false;

            state.specificGroup.group = { ...state.specificGroup.group, avatar: { key: data?.fileId } };
            break;
        }
        case actions.BLOCK_UNBLOCK_MEMBER.SUCCEEDED: {
            const userIndex = state.members.members?.findIndex(item => item?.user?.id === payload?.args?.userId);

            if (userIndex !== -1) {
                state.members.members[userIndex].blocked = !state.members.members[userIndex].blocked;
            }
            break;
        }
        case actions.CHANGE_USER_ROLE.SUCCEEDED: {
            const role = payload.args?.role;

            const userIndex = state.members.members?.findIndex(item => item?.user?.id === payload?.args?.userId);
            const user = state.members.members?.find(item => item?.user?.id === payload?.args?.userId);

            if (userIndex !== -1) {
                state.members.members[userIndex].type = role;

                if (role === 'moderator') {
                    state.members.admins.push(user);
                    state.members.allAdmins.push(user);
                } else {
                    state.members.admins = state.members.admins.filter(item => item.user?.id !== payload?.args?.userId);
                    state.members.allAdmins = state.members.allAdmins.filter(
                        item => item.user?.id !== payload?.args?.userId
                    );
                }
            }
            break;
        }
        case actions.UPLOAD_PROFILE_PHOTO.FAILED: {
            state.specificGroup.uploadProfileLoading = false;
            break;
        }
        case actions.UPLOAD_BACKGROUND_PHOTO.REQUESTED: {
            state.specificGroup.uploadBackgroundLoading = true;
            break;
        }
        case actions.UPLOAD_BACKGROUND_PHOTO.SUCCEEDED: {
            state.specificGroup.uploadBackgroundLoading = false;
            state.specificGroup.group = { ...state.specificGroup.group, cover: { key: data?.fileId } };
            break;
        }
        case actions.DELETE_MEMBER.SUCCEEDED: {
            const removedUserId = payload?.args?.userId;

            state.members.members = state.members.members.filter(item => item.user?.id !== removedUserId);
            state.members.allMembers = state.members.allMembers.filter(item => item.user?.id !== removedUserId);

            state.members.count -= 1;
            state.members.allCount -= 1;

            if (payload?.args?.leave) {
                state.specificGroup.group.role = null;
            }
            break;
        }
        case actions.GET_GROUP_MEMBERS.REQUESTED: {
            if (payload?.args?.reload) {
                state.members.members = [];
                state.members.count = 0;
            }

            if (payload?.args?.reloadAllMembers) {
                state.members.allMembers = [];
                state.members.allAdmins = [];
                state.members.allCount = 0;
            }

            state.members.isLoading = true;
            state.members.success = false;
            state.members.page = payload.args.page;
            state.members.hasMore = true;
            break;
        }
        case actions.GET_GROUP_MEMBERS.SUCCEEDED: {
            if (payload?.args?.reload) {
                state.members.members = [];
                state.members.admins = [];
            }

            if (payload?.args?.reloadAllMembers) {
                state.members.allMembers = [];
                state.members.allAdmins = [];
            }

            const admins = [];
            [...state.members.allAdmins, ...data?.list]?.map(item => {
                if (item.type === 'owner' || item.type === 'admin' || item.type === 'moderator') {
                    admins.push(item);
                }
            });

            if (payload?.args?.searchTerm === '') {
                state.members.allAdmins = admins;
            }

            state.members.count = data?.count;

            state.members.admins = admins;

            state.members.isLoading = false;
            state.members.error = undefined;
            state.members.success = true;

            if (data?.list && data?.list?.length > 0) {
                data.list[data?.list?.length - 1].user.position = data.list.length;
                state.members.members = [...state.members.members, ...data?.list];
                if (payload?.args?.reloadAllMembers) {
                    state.members.allMembers = [...state.members.allMembers, ...data?.list];
                    state.members.allCount = data?.count;
                }
            } else {
                state.members.hasMore = false;
            }
            break;
        }
        case actions.GET_SPECIFIC_GROUP.REQUESTED: {
            state.specificGroup.isLoading = true;
            state.specificGroup.error = undefined;
            state.specificGroup.success = false;
            state.specificGroup.group = {};
            state.members.allMembers = [];
            state.members.allAdmins = [];
            state.members.admins = [];
            state.members.count = 0;
            state.members.allCount = 0;
            state.members.members = [];
            state.members.page = 1;
            state.members.hasMore = true;
            break;
        }
        case actions.GET_SPECIFIC_GROUP.SUCCEEDED: {
            state.specificGroup.error = undefined;
            state.specificGroup.success = true;
            if (data) {
                state.specificGroup.group = data;
                state.specificGroup.isLoading = false;
            }
            break;
        }
        case actions.JOIN_GROUP.SUCCEEDED: {
            const groupId = payload?.args?.groupId;

            state.suggestions.groups = state.suggestions?.groups?.filter(item => item.id !== groupId);
            state.suggestions.count -= 1;

            if (payload?.args?.group?.privacy === 1) {
                state.specificGroup.group.inviteStatus = 'out';
                return;
            }

            state.specificGroup.group.role = 'member';
            break;
        }
        case actions.LEAVE_GROUP.SUCCEEDED: {
            const groupId = payload.args?.groupId;

            state.groups = state.groups.filter(item => item.id !== groupId);

            if (payload.args?.leave) {
                state.specificGroup.group.role = null;
            }
            break;
        }
        case actions.CREATE_GROUP.REQUESTED: {
            state.createGroup.isLoading = true;
            break;
        }
        case actions.CREATE_GROUP.SUCCEEDED: {
            state.createGroup.isLoading = false;
            break;
        }
        case actions.CREATE_GROUP.FAILED: {
            state.createGroup.isLoading = false;
            break;
        }
        case actions.EDIT_GROUP.REQUESTED: {
            state.editGroup.isLoading = true;
            break;
        }
        case actions.EDIT_GROUP.SUCCEEDED: {
            state.editGroup.isLoading = false;

            state.specificGroup.group.postGroup = payload?.args?.post_group;
            break;
        }
        case actions.EDIT_GROUP.FAILED: {
            state.editGroup.isLoading = false;
            break;
        }
        case actions.GET_GROUPS_CATEGORIES.SUCCEEDED: {
            const groupsList = data?.lists?.groupCategories;
            const categories = [];
            const subCategories = [];

            groupsList?.map(item => {
                categories.push({ value: item?.category, label: item?.category });
                subCategories.push(item);
            });

            state.categories = categories;
            state.subCategories = subCategories;

            break;
        }
        case actions.GET_GROUPS.REQUESTED:
            if (payload?.args?.reload) {
                state.groups = [];
                state.count = 0;
            }
            state.isLoading = true;
            state.error = undefined;
            state.success = false;
            state.page = payload.args.page;
            state.hasMore = true;
            break;
        case actions.GET_GROUPS.SUCCEEDED:
            if (payload?.args?.reload) {
                state.groups = [];
            }

            state.isLoading = false;
            state.error = undefined;
            state.success = true;
            state.count = data.count;

            if (data?.list && data?.list?.length > 0) {
                data.list[data?.list?.length - 1].position = data.list.length;
                state.groups = [...state.groups, ...data?.list];
                state.hasMore = true;
            } else {
                state.hasMore = false;
            }
            break;
        case actions.GET_GROUPS_SUGGESTIONS.REQUESTED: {
            if (payload?.args?.reload) {
                state.suggestions.groups = [];
                state.suggestions.count = 0;
            }
            state.suggestions.isLoading = true;
            state.suggestions.error = undefined;
            state.suggestions.success = false;
            state.suggestions.page = payload.args.page;
            state.suggestions.hasMore = true;
            break;
        }
        case actions.GET_GROUPS_SUGGESTIONS.SUCCEEDED: {
            if (payload?.args?.reload) {
                state.suggestions.groups = [];
            }

            state.suggestions.isLoading = false;
            state.suggestions.error = undefined;
            state.suggestions.success = true;
            state.suggestions.count = data.count;

            if (data?.list && data?.list?.length > 0) {
                data.list[data?.list?.length - 1].position = data.list.length;
                state.suggestions.groups = [...state.suggestions.groups, ...data?.list];
                state.suggestions.hasMore = true;
            } else {
                state.suggestions.hasMore = false;
            }
            break;
        }
        case actions.GET_GROUPS_INVITATION.REQUESTED: {
            if (payload?.args?.reload) {
                state.invitations.groups = [];
                state.invitations.count = 0;
            }
            state.invitations.isLoading = true;
            state.invitations.error = undefined;
            state.invitations.success = false;
            state.invitations.page = payload.args.page;
            state.invitations.hasMore = true;
            break;
        }
        case actions.GET_GROUPS_INVITATION.SUCCEEDED: {
            if (payload?.args?.reload) {
                state.invitations.groups = [];
            }

            state.invitations.isLoading = false;
            state.invitations.error = undefined;
            state.invitations.success = true;
            state.invitations.count = data.count;

            if (data?.list && data?.list?.length > 0) {
                data.list[data?.list?.length - 1].group.position = data.list.length;
                state.invitations.groups = [...state.invitations.groups, ...data?.list];

                state.invitations.hasMore = true;
            } else {
                state.invitations.hasMore = false;
            }
            break;
        }
        default:
            return state;
    }
    return state;
});

export default reducer;
