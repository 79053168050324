import { MODULE_NAME } from './albumConstants';
import { requestActions } from '../actionsCreator';
import { requestApiCall } from '../connectivity/connectivityActions';
import api from '../../api';

export const LIST_ALBUMS = requestActions(MODULE_NAME, 'LIST_ALBUMS');
export const CREATE_ALBUM = requestActions(MODULE_NAME, 'CREATE_ALBUM');
export const EDIT_ALBUM = requestActions(MODULE_NAME, 'EDIT_ALBUM');
export const DELETE_ALBUM = requestActions(MODULE_NAME, 'DELETE_ALBUM');
export const SELECT_ALBUM = 'SELECT_ALBUM';
export const DESELECT_ALBUM = 'DESELECT_ALBUM';
export const LIST_PHOTOS = requestActions(MODULE_NAME, 'LIST_PHOTOS');
export const UPLOAD_PHOTO = requestActions(MODULE_NAME, 'UPLOAD_PHOTO');
export const DELETE_PHOTO = requestActions(MODULE_NAME, 'DELETE_PHOTO');

export const listAlbums = ({ userId, page, searchText, reload = false }) =>
    requestApiCall(api.names.listAlbums, { userId, page, searchText, reload }, LIST_ALBUMS);
export const createAlbum = ({ title, privacy }) =>
    requestApiCall(api.names.createAlbum, { title, privacy }, CREATE_ALBUM);
export const editAlbum = ({ albumId, title, privacy }) =>
    requestApiCall(
        api.names.editAlbum,
        { albumId, title, privacy, notification: { text: 'AlbumEditModal.editSuccess' } },
        EDIT_ALBUM
    );
export const deleteAlbum = ({ albumId }) =>
    requestApiCall(
        api.names.deleteAlbum,
        { albumId, notification: { text: 'AlbumDeleteModal.deleteSuccess' } },
        DELETE_ALBUM
    );
export const selectAlbum = ({ albumId, albumName }) => ({
    type: SELECT_ALBUM,
    payload: { albumId, albumName },
});
export const deselectAlbum = () => ({
    type: DESELECT_ALBUM,
});
export const listPhotos = ({ userId, albumId, page, reload = false }) =>
    requestApiCall(api.names.listPhotos, { userId, albumId, page, reload }, LIST_PHOTOS);
export const uploadPhoto = file => requestApiCall(api.names.uploadPhoto, { file }, UPLOAD_PHOTO);
export const deletePhoto = photoKey =>
    requestApiCall(
        api.names.deletePhoto,
        { photoKey, notification: { text: 'AlbumPhotoDeleteModal.deleteSuccess' } },
        DELETE_PHOTO
    );
