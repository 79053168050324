import client from '../client';

/**
 * This request is made directly by the ErrorBoundary controller and
 * saga failures watcher, in case something goes wrong.
 * The main reason for that is that if something on Redux is broken
 * it can block the log request.
 */
export const postLog = (uuid = 'NotDefined', error, errorInfo) => {
    let navigatorDetails = '';
    let logText = error;

    try {
        const navigatorCopy = {};
        // eslint-disable-next-line no-restricted-syntax
        for (const i in window.navigator) {
            if (Object.prototype.hasOwnProperty.call(window.navigator, i)) {
                navigatorCopy[i] = window.navigator[i];
            }
        }
        navigatorDetails = JSON.stringify(navigatorCopy);
    } catch {
        return undefined;
    }

    try {
        const errorDetails = error.stack ? { stack: error?.stack, message: error?.message, name: error?.name } : error;

        const details = {
            error: { errorDetails, errorInfo },
            navigator: navigatorDetails,
        };
        logText = JSON.stringify(details);
    } catch {
        return undefined;
    }

    if (window.location.host !== 'localhost:3000')
        client.post('/api/logger/log', { uuid, logText });
    return undefined;
};
