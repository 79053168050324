import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import './styles/index.scss';
import 'foundation-sites/dist/css/foundation.min.css';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { store, persistor, history } from './store';
import { getIntl } from './intl';

const { messages, locale } = getIntl();

// TODO if prod send to data dog, if dev print it
// console.log = () => {};
// console.error = () => {};
// console.warning = () => {};

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <IntlProvider locale={locale} messages={messages} onError={() => {}}>
                    <ConnectedRouter history={history}>
                        <App />
                    </ConnectedRouter>
                </IntlProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
