import client from '../client';

export const getFeed = ({ page }) => client.get(`/api/post/news?page=${page}&per_page=10`);
export const upvote = ({ postId }) => client.post(`api/vote/${postId}/like`, {});
export const downvote = ({ postId }) => client.post(`api/vote/${postId}/dislike`, {});
export const savePost = ({ postType, postId, postAction }) =>
    client.post(`api/bookmark/${postType}/${postId}/${postAction}`, {});
export const deletePost = ({ postType, postId }) => client.delete(`api/post/${postType}/${postId}`, {});

// New Post
export const createNewPost = ({ postType, entityId, post, preview, gif }) => {
    if (preview?.title && gif) {
        return client.post(`api/post/${postType}/${entityId}`, {
            post,
            previewName: preview?.title,
            previewDescription: preview?.description,
            previewUrl: preview?.url,
            previewType: preview?.type,
            previewImage: preview?.image,
            previewCode: preview?.code,
            previewProviderName: preview?.providerName,
            previewWidth: preview?.width,
            previewHeight: preview?.height,
            gif_link: gif,
        });
    }
    if (preview?.title && !gif) {
        return client.post(`api/post/${postType}/${entityId}`, {
            post,
            previewName: preview?.title,
            previewDescription: preview?.description,
            previewUrl: preview?.url,
            previewType: preview?.type,
            previewImage: preview?.image,
            previewCode: preview?.code,
            previewProviderName: preview?.providerName,
            previewWidth: preview?.width,
            previewHeight: preview?.height,
        });
    }

    if (!preview?.title && gif) {
        return client.post(`api/post/${postType}/${entityId}`, {
            post,
            gif_link: gif,
        });
    }

    if (!post && gif) {
        return client.post(`api/post/${postType}/${entityId}`, {
            gif_link: gif,
        });
    }

    return client.post(`api/post/${postType}/${entityId}`, { post });
};

export const editPost = ({ postType, entityId, post, preview, gif }) => {
    if (preview?.title && gif) {
        return client.patch(`api/post/${postType}/${entityId}`, {
            post,
            previewName: preview?.title,
            previewDescription: preview?.description,
            previewUrl: preview?.url,
            previewType: preview?.type,
            previewImage: preview?.image,
            previewCode: preview?.code,
            previewProviderName: preview?.providerName,
            previewWidth: preview?.width,
            previewHeight: preview?.height,
            gif_link: gif,
        });
    }
    if (preview?.title && !gif) {
        return client.patch(`api/post/${postType}/${entityId}`, {
            post,
            previewName: preview?.title,
            previewDescription: preview?.description,
            previewUrl: preview?.url,
            previewType: preview?.type,
            previewImage: preview?.image,
            previewCode: preview?.code,
            previewProviderName: preview?.providerName,
            previewWidth: preview?.width,
            previewHeight: preview?.height,
        });
    }

    if (!preview?.title && gif) {
        return client.patch(`api/post/${postType}/${entityId}`, {
            post,
            gif_link: gif,
        });
    }

    if (!post && gif) {
        return client.patch(`api/post/${postType}/${entityId}`, {
            gif_link: gif,
        });
    }

    return client.patch(`api/post/${postType}/${entityId}`, { post });
};

export const getUsersList = ({ term }) => client.get(`api/search/autocomplete?term=${term}`);
export const getPreviewOfLink = ({ url }) => client.post('/api/op', { url });
export const getPreviewOfLinkUpdate = ({ url }) => client.post('/api/op', { url });
export const getPreviewOfLinkComments = ({ url }) => client.post('/api/op', { url });
export const getGifs = ({ name }) => client.get(`/api/post/user/getgiphies?q=${name}`);
export const uploadPostPhoto = ({ file }) => client.post('/upload', file);
export const uploadPostPhotoUpdate = ({ file }) => client.post('/upload', file);
export const deletePostPhoto = ({ key }) => client.delete(`/api/files/key/${key}`);
export const getUserPost = ({ postId }) => client.get(`/api/post/view/${postId}`);
// comments
export const getComments = ({ postId, page, commentType }) =>
    client.get(`/api/comments/${commentType}/${postId}`, { params: { page, per_page: 6 } });
export const addNewComment = ({ postId, text, gif, preview, commentType }) =>
    !preview?.title
        ? client.post(`/api/comments/${commentType}/${postId}`, { msg: text, gif_link: gif })
        : client.post(`/api/comments/${commentType}/${postId}`, {
              msg: text,
              gif_link: gif,
              previewName: preview?.title,
              previewDescription: preview?.description,
              previewUrl: preview?.url,
              previewType: preview?.type,
              previewImage: preview?.image,
              previewCode: preview?.code,
              previewProviderName: preview?.providerName,
              previewWidth: preview?.width,
              previewHeight: preview?.height,
          });

// replyComments
export const getReplyComments = ({ postId, commentId, page, commentType }) =>
    client.get(`/api/comments/${commentType}/${postId}`, { params: { page, per_page: 3, parent: commentId } });
export const replyToComments = ({ commentId, text, gif, preview, commentType }) =>
    !preview?.title
        ? client.post(`/api/comments/${commentType}/reply/${commentId}`, { msg: text, gif_link: gif })
        : client.post(`/api/comments/${commentType}/reply/${commentId}`, {
              msg: text,
              gif_link: gif,
              previewName: preview?.title,
              previewDescription: preview?.description,
              previewUrl: preview?.url,
              previewType: preview?.type,
              previewImage: preview?.image,
              previewCode: preview?.code,
              previewProviderName: preview?.providerName,
              previewWidth: preview?.width,
              previewHeight: preview?.height,
          });

export const editComment = ({ postId, commentId, text, gif, preview, commentType }) =>
    !preview?.title
        ? client.patch(`/api/comments/${commentType}/${commentId}`, { msg: text, gif_link: gif, postId })
        : client.patch(`/api/comments/${commentType}/${commentId}`, {
              msg: text,
              gif_link: gif,
              postId,
              previewName: preview?.title,
              previewDescription: preview?.description,
              previewUrl: preview?.url,
              previewType: preview?.type,
              previewImage: preview?.image,
              previewCode: preview?.code,
              previewProviderName: preview?.providerName,
              previewWidth: preview?.width,
              previewHeight: preview?.height,
          });
export const deleteComment = ({ commentId, commentType }) => client.delete(`/api/comments/${commentType}/${commentId}`);

// Report post
export const reportReasonList = () => client.get('/api/report/dict');
export const reportPost = ({ postId, reason, details }) => client.post(`/api/report/${postId}`, { reason, details });

// suggestions
export const getGroupsYouMayKnow = ({ perPage }) => client.get(`/api/groups/suggestions?per_page=${perPage}`);
export const getPeopleYouMayKnow = ({ page, perPage }) =>
    client.get(`/api/friends/sugg?page=${page}&per_page=${perPage}`);
