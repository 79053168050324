import produce from 'immer';
import * as actions from './searchActions';
import * as followersAction from '../followers/followerActions';

const initialState = {
    searchPageNumber: 1,
    searchPostsPageList: [],
    searchUsersPageList: [],
    searchGroupsPageList: [],
    searchCompaniesPageList: [],
    searchPageLoading: false,
    searchPageError: false,
    searchPageHasMore: true,
    searchList: [],
    searchError: false,
    searchLoading: false,
    searchCount: 0,
};

const getFollower = (list, follower) => list.find(item => item.id === follower.id);

export const reducer = produce((state = initialState, { type, payload }) => {
    const data = payload?.data;

    switch (type) {
        case followersAction.UNFOLLOW_ACTION.SUCCEEDED: {
            const user = getFollower(state?.searchUsersPageList, { id: payload?.args?.userId });

            if (user) {
                user.isFollowing = false;
            }

            break;
        }
        case followersAction.DIRECT_FOLLOW.SUCCEEDED: {
            const user = getFollower(state?.searchUsersPageList, { id: payload?.args?.userId });

            if (user) {
                user.isFollowing = true;
            }

            break;
        }
        case followersAction.FOLLOW_REQUEST.SUCCEEDED: {
            if (payload?.args?.isOtherProfile) {
                const user = getFollower(state?.searchUsersPageList, { id: payload?.args?.userId });

                if (user) {
                    user.followStatus = 'sent';
                }
            }
            break;
        }
        case actions.GET_SEARCH.REQUESTED: {
            state.searchLoading = true;
            state.searchError = false;
            break;
        }
        case actions.GET_SEARCH.SUCCEEDED: {
            state.searchLoading = false;
            state.searchList = data?.list ?? [];
            if (state?.searchList?.length === 0) {
                state.searchError = true;
            }
            break;
        }
        case actions.GET_SEARCH_PAGE.REQUESTED: {
            state.searchPageNumber = payload.args.page;
            state.searchPageLoading = true;
            state.searchPageError = false;
            break;
        }
        case actions.GET_SEARCH_PAGE.SUCCEEDED: {
            if (payload.args.type === 'tags') {
                if (data?.list && data?.list?.length > 0) {
                    data.list[data?.list?.length - 1].position = data.list.length;
                    state.searchPostsPageList = [...state.searchPostsPageList, ...data?.list];
                } else {
                    state.searchPageHasMore = false;
                }
                if (state?.searchPostsPageList?.length === 0 && data?.list?.length === 0) {
                    state.searchPageError = true;
                }
                state.searchPageLoading = false;
                state.searchCount = data.count;
            } else if (payload.args.type === 'users') {
                if (data?.list && data?.list?.length > 0) {
                    data.list[data?.list?.length - 1].position = data.list.length;
                    state.searchUsersPageList = [...state.searchUsersPageList, ...data?.list];
                } else {
                    state.searchPageHasMore = false;
                }
                if (state?.searchUsersPageList?.length === 0 && data?.list?.length === 0) {
                    state.searchPageError = true;
                }
                state.searchPageLoading = false;
                state.searchCount = data.count;
            } else if (payload.args.type === 'groups') {
                if (data?.list && data?.list?.length > 0) {
                    data.list[data?.list?.length - 1].position = data.list.length;
                    state.searchGroupsPageList = [...state.searchGroupsPageList, ...data?.list];
                } else {
                    state.searchPageHasMore = false;
                }
                if (state?.searchGroupsPageList?.length === 0 && data?.list?.length === 0) {
                    state.searchPageError = true;
                }
                state.searchPageLoading = false;
                state.searchCount = data.count;
            } else if (payload.args.type === 'companies') {
                if (data?.list && data?.list?.length > 0) {
                    data.list[data?.list?.length - 1].position = data.list.length;
                    state.searchCompaniesPageList = [...state.searchCompaniesPageList, ...data?.list];
                } else {
                    state.searchPageHasMore = false;
                }
                if (state?.searchCompaniesPageList?.length === 0 && data?.list?.length === 0) {
                    state.searchPageError = true;
                }
                state.searchPageLoading = false;
                state.searchCount = data.count;
            }
            break;
        }
        case actions.CLEAN_SEARCH_PAGE: {
            state.searchPostsPageList = [];
            state.searchUsersPageList = [];
            state.searchGroupsPageList = [];
            state.searchCompaniesPageList = [];
            state.searchPageNumber = 1;
            state.searchPageHasMore = true;
            state.searchPageError = true;
            state.searchCount = 0;
            break;
        }
        default:
            return state;
    }

    return state;
});

export default reducer;
