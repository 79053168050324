import { MODULE_NAME } from './searchConstant';
import { requestActions } from '../actionsCreator';
import { requestApiCall } from '../connectivity/connectivityActions';
import api from '../../api';

export const GET_SEARCH = requestActions(MODULE_NAME, 'GET_SEARCH');
export const GET_SEARCH_PAGE = requestActions(MODULE_NAME, 'GET_SEARCH_PAGE');
export const CLEAN_SEARCH_PAGE = 'CLEAN_SEARCH_PAGE';

export const cleanSearchPage = () => ({ type: CLEAN_SEARCH_PAGE });
export const getSearch = query => requestApiCall(api.names.getSearch, { query }, GET_SEARCH);
export const getSearchPage = ({ query, type, page }) =>
    requestApiCall(api.names.getSearchPage, { query, type, page }, GET_SEARCH_PAGE);
