import client from '../client';

export const getUserGroups = ({ userId, page, searchTerm }) =>
    client.get(`/api/groups/user/${userId}?page=${page}&per_page=20&term=${searchTerm}`);

export const getUserGroupSuggestions = ({ page, searchTerm }) =>
    client.get(`/api/groups/suggestions?page=${page}&per_page=20&term=${searchTerm}`);

export const getUserGroupInvitation = ({ page, searchTerm, dir }) =>
    client.get(`/api/invites/group?page=${page}&per_page=20&dir=${dir}&term=${searchTerm}`);

export const createGroup = ({ name, privacy, category, subcategory, description, post_group }) =>
    client.post('/api/groups', { name, privacy, category, subcategory, description, post_group });

export const leaveGroup = ({ groupId }) => client.post(`/api/groups/${groupId}/leave`);

export const blockAndUnblockMember = ({ groupId, userId, blockType }) =>
    client.post(`/api/groups/${groupId}/members/${userId}/${blockType}`);

export const deleteMember = ({ groupId, userId }) => client.delete(`/api/groups/${groupId}/members/${userId}`);

export const changeUserRole = ({ groupId, userId, role }) =>
    client.patch(`/api/groups/${groupId}/members/${userId}/role`, { role });

export const editGroup = ({
    groupId,
    name,
    privacy,
    category,
    subcategory,
    description,
    permalink,
    location,
    links,
    pictures,
    contacts,
    wall,
    post_group,
}) =>
    client.patch(`/api/groups/${groupId}`, {
        name,
        privacy,
        category,
        subcategory,
        description,
        permalink,
        location,
        links,
        pictures,
        contacts,
        wall,
        post_group,
    });

export const joinGroup = ({ groupId }) => client.post(`/api/groups/${groupId}/join`);

export const deleteGroup = ({ groupId }) => client.delete(`/api/groups/${groupId}`);

export const getGroupMembers = ({ groupId, searchTerm = '', page = 1, filters = '' }) =>
    client.get(`/api/groups/${groupId}/members?page=${page}&per_page=20&search_text=${searchTerm}${filters}`);

export const getSpecificGroup = ({ permaLink }) => client.get(`/api/groups/${permaLink}`);

export const getGroupCategories = () => client.get('/api/dict');

export const getGroupsPost = ({ entityId, page }) => client.get(`/api/post/group/${entityId}?page=${page}&per_page=10`);

export const actionOnInvite = ({ groupId, action }) => client.patch(`/api/invites/group/${groupId}/${action}`);

export const lisUserToInvite = ({ groupId, page = 1, searchTerm = '' }) =>
    client.get(`/api/follower/except/group/${groupId}?per_page=20&page=${page}&term=${searchTerm}`);

export const sendGroupInvite = ({ groupId, userId }) => client.post(`/api/invites/group/${groupId}/create/${userId}`);
