/* eslint-disable no-restricted-syntax */
import produce from 'immer';
import * as actions from './userActions';
import * as profileActions from '../profile/profileActions';
import * as authActions from '../auth/authActions';
import { getErrorFrom } from '../utils';

const initialState = {
    loggedUser: undefined,
    edit: {
        isLoading: false,
        error: undefined,
    },
    changePassword: {
        isLoading: false,
        error: undefined,
    },
};

const payloadToUser = user => {
    if (user?.profile?.birthday) {
        try {
            user.profile.birthdayMonth = user.profile.birthday.substring(5, 7);
            user.profile.birthdayDay = user.profile.birthday.substring(8, 10);
            user.profile.birthdayYear = user.profile.birthday.substring(0, 4);
        } catch {
            user.profile.birthdayMonth = 1;
            user.profile.birthdayDay = 1;
            user.profile.birthdayYear = 2000;
        }
    }
    return user;
};

export const reducer = produce((state = initialState, { type, payload }) => {
    const data = payload?.data;
    switch (type) {
        case profileActions.DELETE_IMAGE.SUCCEEDED: {
            if (payload.args.type === 'profile') {
                state.loggedUser = { ...state.loggedUser, avatar: null };
            }
            if (payload.args.type === 'background') {
                state.loggedUser = { ...state.loggedUser, cover: null };
            }

            break;
        }
        case profileActions.UPLOAD_PROFILE_PHOTO.SUCCEEDED: {
            state.loggedUser = { ...state.loggedUser, avatar: { key: data?.fileId } };
            break;
        }
        case profileActions.UPLOAD_BACKGROUND_PHOTO.SUCCEEDED: {
            state.loggedUser = { ...state.loggedUser, cover: { key: data?.fileId } };
            break;
        }
        case actions.GET_CURRENT_USER.SUCCEEDED: {
            state.loggedUser = payloadToUser(data);
            break;
        }
        case actions.EDIT_USER_BC_SETTINGS.SUCCEEDED: {
            state.edit.isLoading = false;
            state.edit.error = undefined;
            state.loggedUser.profile = {
                ...state.loggedUser.profile,
                ...data,
            };
            break;
        }
        case actions.EDIT_USER.SUCCEEDED: {
            state.edit.isLoading = false;
            state.edit.error = undefined;
            state.loggedUser = payloadToUser(data);
            break;
        }
        case actions.EDIT_USER_BC_SETTINGS.REQUESTED:
        case actions.EDIT_USER.REQUESTED: {
            state.edit.isLoading = true;
            state.edit.error = undefined;
            break;
        }
        case actions.EDIT_USER_BC_SETTINGS.FAILED:
        case actions.EDIT_USER.FAILED: {
            state.edit.isLoading = false;
            state.edit.error = getErrorFrom(payload.error);
            break;
        }
        case actions.CHANGE_PASSWORD.REQUESTED: {
            state.changePassword.isLoading = true;
            state.changePassword.error = undefined;
            break;
        }
        case actions.CHANGE_PASSWORD.FAILED: {
            state.changePassword.isLoading = false;
            state.changePassword.error = getErrorFrom(payload);
            break;
        }
        case actions.CHANGE_PASSWORD.SUCCEEDED: {
            state.changePassword.isLoading = false;
            state.changePassword.error = undefined;
            break;
        }
        case authActions.LOGOUT: {
            for (const propertyName in state) {
                if (Object.prototype.hasOwnProperty.call(state, propertyName)) {
                    delete state[propertyName];
                }
            }
            for (const propertyName in initialState) {
                if (Object.prototype.hasOwnProperty.call(initialState, propertyName)) {
                    state[propertyName] = initialState[propertyName];
                }
            }
            break;
        }
        default:
            return state;
    }
    return state;
});

export default reducer;
