import produce from 'immer';
import { isNil } from 'lodash';
import { LOCAL_STORAGE_TOKEN } from './authConstants';
import * as actions from './authActions';
import { getErrorFrom } from '../utils';

const initialState = {
    token: localStorage.getItem(LOCAL_STORAGE_TOKEN) || undefined,
    hasLoginFailed: false,
    login: {
        error: undefined,
        isLoading: false,
        error2FA: undefined,
        smsSent: false,
        phoneId: undefined,
        appId: undefined,
        phone: undefined,
        app: undefined,
    },
    signUp: {
        email: undefined,
        isLoading: false,
    },
    requestResetPassword: {
        email: undefined,
        isLoading: false,
        error: undefined,
    },
    resetPassword: {
        isLoading: false,
        error: undefined,
    },
};

const flatten = twoFA => {
    const phoneIndex = twoFA[0].twoFactorAuthPhone ? 0 : 1;
    const appIndex = twoFA[0].twoFactorAuthApp ? 0 : 1;
    const phone = twoFA[phoneIndex]?.twoFactorAuthPhone;
    const phoneId = twoFA[phoneIndex]?.twoFactorAuthPhone?.id;
    const app = twoFA[appIndex]?.twoFactorAuthApp;
    const appId = twoFA[appIndex]?.twoFactorAuthApp?.id;
    return { phone, app, appId, phoneId };
};

export const reducer = produce((state = initialState, { type, payload }) => {
    const data = payload?.data;
    switch (type) {
        case actions.LOGIN.REQUESTED: {
            state.login.isLoading = true;
            state.login.error = undefined;
            break;
        }
        case actions.LOGIN.SUCCEEDED: {
            if (data.twoFactorAuth) {
                const { phone, app, appId, phoneId } = flatten(data.twoFactorAuth);
                state.login.is2FA = true;
                state.login.app = isNil(app) ? undefined : app.accountName;
                state.login.phone = isNil(phone) ? undefined : phone.countryCode + phone.phoneNumber;
                state.login.appId = appId;
                state.login.phoneId = phoneId;
            } else {
                state.token = data.token;
                localStorage.setItem(LOCAL_STORAGE_TOKEN, data.token);
            }
            state.login.isLoading = false;
            break;
        }
        case actions.LOGIN.FAILED: {
            state.login.isLoading = false;
            state.login.error = getErrorFrom(payload);
            break;
        }
        case actions.LOGOUT: {
            state.token = undefined;
            localStorage.setItem(LOCAL_STORAGE_TOKEN, '');
            break;
        }
        case actions.SIGN_UP.REQUESTED: {
            state.signUp.isLoading = true;
            state.signUp.error = undefined;
            break;
        }
        case actions.SIGN_UP.FAILED: {
            state.signUp.isLoading = false;
            state.signUp.error = getErrorFrom(payload);
            break;
        }
        case actions.SIGN_UP.SUCCEEDED: {
            state.signUp.isLoading = false;
            state.signUp.email = data?.email;
            break;
        }
        case actions.REQUEST_RESET_PASSWORD.SUCCEEDED: {
            state.requestResetPassword.isLoading = false;
            break;
        }
        case actions.REQUEST_RESET_PASSWORD.REQUESTED: {
            state.requestResetPassword.isLoading = true;
            state.requestResetPassword.error = undefined;
            break;
        }
        case actions.REQUEST_RESET_PASSWORD.FAILED: {
            state.requestResetPassword.isLoading = false;
            state.requestResetPassword.error = getErrorFrom(payload);
            break;
        }
        case actions.RESET_PASSWORD.SUCCEEDED: {
            state.resetPassword.isLoading = false;
            break;
        }
        case actions.RESET_PASSWORD.REQUESTED: {
            state.resetPassword.isLoading = true;
            break;
        }
        case actions.RESET_PASSWORD.FAILED: {
            state.resetPassword.isLoading = false;
            state.resetPassword.error = getErrorFrom(payload);
            break;
        }
        case actions.CLEAN_ERRORS: {
            state.login.error = undefined;
            state.signUp.error = undefined;
            state.requestResetPassword.error = undefined;
            break;
        }
        case actions.VERIFY_2FA.REQUESTED: {
            state.login.isLoading = true;
            state.login.error2FA = undefined;
            state.login.error = undefined;
            break;
        }
        case actions.VERIFY_2FA.FAILED: {
            state.login.isLoading = false;
            state.login.error2FA = getErrorFrom(payload);
            break;
        }
        case actions.VERIFY_2FA.SUCCEEDED: {
            localStorage.setItem(LOCAL_STORAGE_TOKEN, data.token);
            state.token = data.token;
            state.login = initialState.login;
            break;
        }
        default:
            return state;
    }
    return state;
});
