import { MODULE_NAME } from './userConstants';
import { requestActions } from '../actionsCreator';
import { requestApiCall } from '../connectivity/connectivityActions';
import api from '../../api';

/**
 * Actions constants
 */

export const GET_CURRENT_USER = requestActions(MODULE_NAME, 'GET_CURRENT_USER');
export const EDIT_USER = requestActions(MODULE_NAME, 'EDIT_USER');
export const EDIT_USER_BC_SETTINGS = requestActions(MODULE_NAME, 'EDIT_USER_BC_SETTINGS');
export const CHANGE_PASSWORD = requestActions(MODULE_NAME, 'CHANGE_PASSWORD');

/**
 * Action creators
 */

export const getCurrentUser = () => ({
    type: GET_CURRENT_USER,
});
export const edit = user => requestApiCall(api.names.editUser, user, EDIT_USER);
export const editBcSettings = settings => requestApiCall(api.names.editUserBcSettings, settings, EDIT_USER_BC_SETTINGS);
export const changePassword = ({ oldPassword, newPassword, newPasswordRepeat }) =>
    requestApiCall(api.names.changePassword, { oldPassword, newPassword, newPasswordRepeat }, CHANGE_PASSWORD);
