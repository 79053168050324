import client from '../client';

export const login = ({ email, password }) => client.post('/api/users/auth', { email, plainPassword: password });

export const verify2FA = ({ id, token, type }) => client.post(`/api/users/auth/${type}/validateToken/${id}`, { token });

export const resendToken = ({ id }) => client.post(`/api/2fa/phone/resendToken/${id}`, {});

export const signUp = ({ email, password, firstName, lastName }) =>
    client.post('/api/users/register', { email, plainPassword: password, firstName, lastName }).then(response => {
        response.data = { ...response?.data, email };
        return response;
    });

export const confirmEmail = ({ token }) => client.get(`/api/users/confirm?token=${token}`);

export const requestResetPassword = ({ email }) =>
    client.post('/api/users/reset', { email }).then(response => {
        response.data = { ...response?.data, email };
        return response;
    });

export const resetPassword = ({ password, token }) =>
    client.post('/api/users/emailReset', { newPassword: password, newPasswordRepeat: password, token });

export const invalidRequest = () => client.post('/api/users/emailReset', { invalidProp: 123 });
