/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
import produce from 'immer';
import * as actions from './shortVideoActions';
import { getErrorFrom } from '../utils';

const initialState = {
    userFeed: {
        isLoading: false,
        videos: [],
        error: undefined,
        page: 1,
        hasMore: true,
    },
    newShortVideo: {
        isSaving: false,
        error: undefined,
    }
};

export const reducer = produce((state = initialState, { type, payload }) => {
    const data = payload?.data;
    switch (type) {
        case actions.GET_SHORT_VIDEOS.REQUESTED: {
            if (payload?.args?.reload) {
                state.userFeed.videos = [];
                state.userFeed.hasMore = true;
            }
            state.userFeed.page = payload.args.page;
            state.userFeed.isLoading = true;
            state.userFeed.error = undefined;
            break;
        }
        case actions.GET_SHORT_VIDEOS.SUCCEEDED: {
            if (data.list && data.list.length > 0) {
                /**
                 * Position property is used to calculate if it's time to load more posts.
                 * For usage see FeedPage.jsx function handleShowLastPost.
                 */
                data.list[data.list.length - 1].position = state.userFeed.videos.length;
                state.userFeed.videos = [...state.userFeed.videos, ...data.list ];
            } else {
                state.userFeed.hasMore = false;
            }
            state.userFeed.isLoading = false;
            state.userFeed.error = undefined;
            break;
        }
        case actions.GET_SHORT_VIDEOS.FAILED: {
            state.userFeed.isLoading = false;
            state.userFeed.error = getErrorFrom(payload.error);
            break;
        }
        case actions.DELETE_SHORT_VIDEO.SUCCEEDED: {
            break;
        }
        case actions.CREATE_SHORT_VIDEO.REQUESTED: {
            state.newShortVideo.isSaving = true;
            state.newShortVideo.error = undefined;
            break;
        }
        case actions.CREATE_SHORT_VIDEO.SUCCEEDED: {
            state.newShortVideo.isSaving = false;
            break;
        }
        case actions.CREATE_SHORT_VIDEO.FAILED: {
            state.newShortVideo.isSaving = false;
            state.newShortVideo.error = getErrorFrom(payload.error);
            break;
        }
        default:
            return state;
    }
    return state;
});

export default reducer;
