import { MODULE_NAME } from './authConstants';
import { requestActions } from '../actionsCreator';
import { requestApiCall } from '../connectivity/connectivityActions';
import api from '../../api';

/**
 * Constants
 */

export const LOGIN = requestActions(MODULE_NAME, 'LOGIN');
export const VERIFY_2FA = requestActions(MODULE_NAME, 'VERIFY_2FA');
export const RESEND_TOKEN = requestActions(MODULE_NAME, 'RESEND_TOKEN');
export const SIGN_UP = requestActions(MODULE_NAME, 'SIGN_UP');
export const CONFIRM_EMAIL = requestActions(MODULE_NAME, 'CONFIRM_EMAIL');
export const REQUEST_RESET_PASSWORD = requestActions(MODULE_NAME, 'REQUEST_RESET_PASSWORD');
export const RESET_PASSWORD = requestActions(MODULE_NAME, 'RESET_PASSWORD');
export const INVALID_REQUEST = requestActions(MODULE_NAME, 'INVALID_REQUEST');
export const LOGOUT = 'LOGOUT';
export const CLEAN_ERRORS = 'CLEAN_ERRORS';

/**
 * Creators
 */

export const login = ({ email, password }) => requestApiCall(api.names.login, { email, password }, LOGIN);
export const verify2FA = ({ token, id, type }) => requestApiCall(api.names.verify2FA, { token, id, type }, VERIFY_2FA);
export const resendToken = ({ id }) => requestApiCall(api.names.resendToken, { id }, RESEND_TOKEN);
export const signUp = ({ email, password, firstName, lastName }) =>
    requestApiCall(api.names.signUp, { email, password, firstName, lastName }, SIGN_UP);
export const requestResetPassword = ({ email }) =>
    requestApiCall(api.names.requestResetPassword, { email }, REQUEST_RESET_PASSWORD);
export const resetPassword = ({ password, token }) =>
    requestApiCall(api.names.resetPassword, { password, token }, RESET_PASSWORD);
export const logout = () => ({ type: LOGOUT });
export const invalidRequest = () => requestApiCall(api.names.invalidRequest, {}, INVALID_REQUEST);
