import { MODULE_NAME } from './companyConstants';
import { requestActions } from '../actionsCreator';
import { requestApiCall } from '../connectivity/connectivityActions';
import api from '../../api';

/**
 * Actions constants
 */
export const GET_COMPANIES = requestActions(MODULE_NAME, 'GET_COMPANIES');
export const DELETE_COMPANY = requestActions(MODULE_NAME, 'DELETE_COMPANY');
export const CHANGE_USER_ROLE = requestActions(MODULE_NAME, 'CHANGE_USER_ROLE');
export const GET_SPECIFIC_COMPANY = requestActions(MODULE_NAME, 'GET_SPECIFIC_COMPANY');
export const GET_COMPANY_MEMBERS = requestActions(MODULE_NAME, 'GET_COMPANY_MEMBERS');
export const GET_MANAGED_COMPANIES = requestActions(MODULE_NAME, 'GET_MANAGED_COMPANIES');
export const GET_SUGGESTION_COMPANIES = requestActions(MODULE_NAME, 'GET_SUGGESTION_COMPANIES');
export const CREATE_COMPANY = requestActions(MODULE_NAME, 'CREATE_COMPANY');
export const EDIT_COMPANY = requestActions(MODULE_NAME, 'EDIT_COMPANY');
export const UPLOAD_PROFILE_PHOTO = requestActions(MODULE_NAME, 'UPLOAD_PROFILE_PHOTO');
export const UPLOAD_BACKGROUND_PHOTO = requestActions(MODULE_NAME, 'UPLOAD_BACKGROUND_PHOTO');
export const COMPANY_ACTION = requestActions(MODULE_NAME, 'COMPANY_ACTION');
export const DELETE_MEMBER = requestActions(MODULE_NAME, 'DELETE_MEMBER');
export const LIST_USER_TO_INVITE = requestActions(MODULE_NAME, 'LIST_USER_TO_INVITE');
export const ADD_MEMBER_TO_COMPANY = requestActions(MODULE_NAME, 'ADD_MEMBER_TO_COMPANY');
export const END_COMPAY_CREATION = 'END_COMPAY_CREATION';

/**
 * Actions creators
 */
export const getUserCompanies = ({ userId, page, searchTerm, reload = false }) =>
    requestApiCall(api.names.getUserCompanies, { userId, page, searchTerm, reload }, GET_COMPANIES);

export const companyAction = ({ companyId, action }) =>
    requestApiCall(api.names.companyAction, { companyId, action }, COMPANY_ACTION);

export const getUserSugestionCompanies = ({ userId, page, searchTerm, reload = false }) =>
    requestApiCall(api.names.getUserSugestionCompanies, { userId, page, searchTerm, reload }, GET_SUGGESTION_COMPANIES);

export const getUserManagedCompanies = ({ userId, page, searchTerm, reload = false }) =>
    requestApiCall(api.names.getUserManagedCompanies, { userId, page, searchTerm, reload }, GET_MANAGED_COMPANIES);

export const createCompany = ({ name, privacy, category, subcategory, description }) =>
    requestApiCall(api.names.createCompany, { name, privacy, category, subcategory, description }, CREATE_COMPANY);

export const editCompany = ({ permalink, companyId, name, privacy, category, subcategory, description }) =>
    requestApiCall(
        api.names.editCompany,
        { companyId, name, privacy, category, subcategory, description, permalink },
        EDIT_COMPANY
    );

export const deleteCompany = ({ companyId }) => requestApiCall(api.names.deleteCompany, { companyId }, DELETE_COMPANY);
export const changeUserRoleCompany = ({ companyId, userId, role }) =>
    requestApiCall(api.names.changeUserRoleCompany, { companyId, userId, role }, CHANGE_USER_ROLE);
export const deleteMemberCompany = ({ companyId, userId }) =>
    requestApiCall(api.names.deleteMemberCompany, { companyId, userId }, DELETE_MEMBER);

export const getSpecificCompany = ({ companyPermalink }) =>
    requestApiCall(api.names.getSpecificCompany, { companyPermalink }, GET_SPECIFIC_COMPANY);

export const getCompanyMembers = ({ companyId, reloadAllMembers = false, reload = false, searchTerm = '', page = 1 }) =>
    requestApiCall(
        api.names.getCompanyMembers,
        { companyId, reloadAllMembers, reload, searchTerm, page },
        GET_COMPANY_MEMBERS
    );

export const uploadCompanyPhoto = (file, type) =>
    requestApiCall(
        api.names.uploadProfilePhoto,
        { file },
        type === 'profile' ? UPLOAD_PROFILE_PHOTO : UPLOAD_BACKGROUND_PHOTO
    );

export const endCreateCompany = () => ({ type: END_COMPAY_CREATION });

export const lisUserToInvite = ({ companyId, page, searchTerm, reload = false }) =>
    requestApiCall(api.names.lisUserToInviteInCompany, { companyId, page, searchTerm, reload }, LIST_USER_TO_INVITE);

export const addCompanyToMember = ({ companyId, userId, position, user }) =>
    requestApiCall(api.names.addCompanyToMember, { companyId, userId, position, user }, ADD_MEMBER_TO_COMPANY);
