// Auth
export const login = 'login';
export const verify2FA = 'verify2FA';
export const resendToken = 'resendToken';
export const signUp = 'signUp';
export const confirmEmail = 'confirmEmail';
export const requestResetPassword = 'requestResetPassword';
export const resetPassword = 'resetPassword';
export const invalidRequest = 'invalidRequest';

// User
export const getCurrentUser = 'getCurrentUser';
export const editUser = 'editUser';
export const editUserBcSettings = 'editUserBcSettings';
export const changePassword = 'changePassword';

// Feed
export const getFeed = 'getFeed';
export const getComments = 'getComments';
export const addNewComment = 'addNewComment';
export const getReplyComments = 'getReplyComments';
export const replyToComments = 'replyToComments';
export const deleteComment = 'deleteComment';
export const upvote = 'upvote';
export const downvote = 'downvote';
export const createNewPost = 'createNewPost';
export const editPost = 'editPost';
export const deletePost = 'deletePost';
export const savePost = 'savePost';
export const getUsersList = 'getUsersList';
export const getPreviewOfLink = 'getPreviewOfLink';
export const getPreviewOfLinkUpdate = 'getPreviewOfLinkUpdate';
export const getGifs = 'getGifs';
export const deletePostPhoto = 'deletePostPhoto';
export const uploadPostPhotoUpdate = 'uploadPostPhotoUpdate';
export const editComment = 'editComment';
export const getUserPost = 'getUserPost';
export const getPreviewOfLinkComments = 'getPreviewOfLinkComments';
export const getSearch = 'getSearch';
export const getSearchPage = 'getSearchPage';

// 2FA
export const getQRCode = 'getQRCode';
export const registerPhone = 'registerPhone';
export const submitCode = 'submitCode';
export const resendCode = 'resendCode';
export const verifyAppAuth = 'verifyAppAuth';
export const registerQRCode = 'registerQRCode';
export const get2FA = 'get2FA';
export const deleteAppAuth = 'deleteAppAuth';
export const deletePhoneAuth = 'deletePhoneAuth';
export const verifyMobileAppCode = 'verifyMobileAppCode';
export const verifyAppAuthCodeToDelete = 'verifyAppAuthCodeToDelete';
export const uploadPostPhoto = 'uploadPostPhoto';

// Coins
export const getFavoriteCoinsByUser = 'getFavoriteCoinsByUser';

// Profile
export const getUserProfile = 'getUserProfile';
export const uploadProfilePhoto = 'uploadProfilePhoto';
export const deleteImage = 'deleteImage';
export const getUserPosts = 'getUserPosts';

// Report Post
export const reportReasonList = 'reportReasonList';
export const reportPost = 'reportPost';

// Followers and following
export const getFollowers = 'getFollowers';
export const getFollowing = 'getFollowing';
export const unFollowUser = 'unFollowUser';
export const sendFollowInvite = 'sendFollowInvite';
export const cancelFollowInvite = 'cancelFollowInvite';
export const removeFollower = 'removeFollower';
export const directFollow = 'directFollow';

// Companies
export const getUserCompanies = 'getUserCompanies';
export const createCompany = 'createCompany';
export const getUserManagedCompanies = 'getUserManagedCompanies';
export const getUserSugestionCompanies = 'getUserSugestionCompanies';
export const companyAction = 'companyAction';
export const getSpecificCompany = 'getSpecificCompany';
export const getCompanyMembers = 'getCompanyMembers';
export const getCompanyPost = 'getCompanyPost';
export const editCompany = 'editCompany';
export const deleteCompany = 'deleteCompany';
export const lisUserToInviteInCompany = 'lisUserToInviteInCompany';
export const addCompanyToMember = 'addCompanyToMember';
export const deleteMemberCompany = 'deleteMemberCompany';
export const changeUserRoleCompany = 'changeUserRoleCompany';

// Suggestions
export const getGroupsYouMayKnow = 'getGroupsYouMayKnow';
export const getPeopleYouMayKnow = 'getPeopleYouMayKnow';

// Groups
export const getUserGroups = 'getUserGroups';
export const getGroupCategories = 'getGroupCategories';
export const createGroup = 'createGroup';
export const leaveGroup = 'leaveGroup';
export const getUserGroupSuggestions = 'getUserGroupSuggestions';
export const getUserGroupInvitation = 'getUserGroupInvitation';
export const joinGroup = 'joinGroup';
export const getSpecificGroup = 'getSpecificGroup';
export const getGroupMembers = 'getGroupMembers';
export const getGroupsPost = 'getGroupsPost';
export const actionOnInvite = 'actionOnInvite';
export const editGroup = 'editGroup';
export const deleteGroup = 'deleteGroup';
export const blockAndUnblockMember = 'blockAndUnblockMember';
export const deleteMember = 'deleteMember';
export const changeUserRole = 'changeUserRole';
export const lisUserToInvite = 'lisUserToInvite';
export const sendGroupInvite = 'sendGroupInvite';

// Notifications
export const getNotifications = 'getNotifications';
export const patchMarkNotifications = 'patchMarkNotifications';
export const updateFollowRequest = 'updateFollowRequest';

// Photo list
export const listAlbums = 'listAlbums';
export const createAlbum = 'createAlbum';
export const editAlbum = 'editAlbum';
export const deleteAlbum = 'deleteAlbum';
export const listPhotos = 'listPhotos';
export const uploadPhoto = 'uploadPhoto';
export const deletePhoto = 'deletePhoto';

// Short video
export const getShortVideo = 'getShortVideo';
export const createShortVideo = 'createShortVideo';
export const deleteShortVideo = 'deleteShortVideo';

// Status
export const getStatus = 'getStatus';

// Communcations
export const updateOnlineStatus = 'updateOnlineStatus';
