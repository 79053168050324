import { MODULE_NAME } from './shortVideoConstants';
import { requestActions } from '../actionsCreator';
import { requestApiCall } from '../connectivity/connectivityActions';
import api from '../../api';

/**
 * Actions constants
 */
export const GET_SHORT_VIDEOS = requestActions(MODULE_NAME, 'GET_SHORT_VIDEOS');
export const CREATE_SHORT_VIDEO = requestActions(MODULE_NAME, 'CREATE_SHORT_VIDEO');
export const DELETE_SHORT_VIDEO = requestActions(MODULE_NAME, 'GET_GROUP_MEMBERS');

/**
 * Actions creators
 */
export const getShortVideos = ({ page, reload }) =>
    requestApiCall(api.names.getShortVideo, { page, reload }, GET_SHORT_VIDEOS);

export const createShortVideo = ({ file }) =>
    requestApiCall(api.names.createShortVideo, { file }, CREATE_SHORT_VIDEO);

export const deleteShortVideo = ({ shortVideoId }) =>
    requestApiCall(api.names.deleteShortVideo, { shortVideoId }, DELETE_SHORT_VIDEO);
