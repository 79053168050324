import { MODULE_NAME } from './feedConstants';
import { requestActions } from '../actionsCreator';
import { requestApiCall } from '../connectivity/connectivityActions';
import api from '../../api';

/**
 * Actions constants
 */

export const GET_FEED = requestActions(MODULE_NAME, 'GET_FEED');
export const GET_COMMENTS_FOR_IMAGE_MODAL = requestActions(MODULE_NAME, 'GET_COMMENTS_FOR_IMAGE_MODAL');
export const GET_COMMENTS = requestActions(MODULE_NAME, 'GET_COMMENTS');
export const ADD_NEW_COMMENTS = requestActions(MODULE_NAME, 'ADD_NEW_COMMENTS');
export const EDIT_COMMENTS = requestActions(MODULE_NAME, 'EDIT_COMMENTS');
export const EDIT_REPLY_COMMENTS = requestActions(MODULE_NAME, 'EDIT_REPLY_COMMENTS');
export const DELETE_COMMENTS = requestActions(MODULE_NAME, 'DELETE_COMMENTS');
export const HIDE_COMMENTS = 'HIDE_COMMENTS';
export const REPLY_TO_COMMENTS = requestActions(MODULE_NAME, 'REPLY_TO_COMMENTS');
export const GET_REPLY_COMMENTS = requestActions(MODULE_NAME, 'GET_REPLY_COMMENTS');
export const CREATE_POST = requestActions(MODULE_NAME, 'CREATE_POST');
export const EDIT_POST = requestActions(MODULE_NAME, 'EDIT_POST');
export const UPVOTE = requestActions(MODULE_NAME, 'UPVOTE ');
export const DOWNVOTE = requestActions(MODULE_NAME, 'DOWNVOTE');
export const UP_VOTE_FOR_IMAGE_PREVIEW = requestActions(MODULE_NAME, 'UP_VOTE_FOR_IMAGE_PREVIEW ');
export const DOWN_VOTE_FOR_IMAGE_PREVIEW = requestActions(MODULE_NAME, 'DOWN_VOTE_FOR_IMAGE_PREVIEW');
export const CLEAN_POSTS = 'CLEAN_POSTS';
export const CLEAN_POSTS_CREATION = 'CLEAN_POSTS_CREATION';
export const ON_IMAGE_PREVIEW_MODAL = 'ON_IMAGE_PREVIEW_MODAL';
export const CLEAN_POSTS_EDIT = 'CLEAN_POSTS_EDIT';
export const GET_USER_LIST = requestActions(MODULE_NAME, 'GET_USER_LIST');
export const GET_USER_POST = requestActions(MODULE_NAME, 'GET_USER_POST');
export const GET_PREVIEW_OF_LINK = requestActions(MODULE_NAME, 'GET_PREVIEW_OF_LINK');
export const GET_PREVIEW_OF_LINK_UPDATE = requestActions(MODULE_NAME, 'GET_PREVIEW_OF_LINK_UPDATE');
export const GET_PREVIEW_OF_LINK_COMMENT = requestActions(MODULE_NAME, 'GET_PREVIEW_OF_LINK_COMMENT');
export const GET_PREVIEW_OF_LINK_REPLY_COMMENT = requestActions(MODULE_NAME, 'GET_PREVIEW_OF_LINK_REPLY_COMMENT');
export const GET_GIFS = requestActions(MODULE_NAME, 'GET_GIFS');
export const UPLOAD_POST_PHOTO = requestActions(MODULE_NAME, 'UPLOAD_POST_PHOTO');
export const UPLOAD_POST_PHOTO_UPDATE = requestActions(MODULE_NAME, 'UPLOAD_POST_PHOTO_UPDATE');
export const UPLOAD_COMMENT_PHOTO = requestActions(MODULE_NAME, 'UPLOAD_COMMENT_PHOTO');
export const UPLOAD_REPLY_COMMENT_PHOTO = requestActions(MODULE_NAME, 'UPLOAD_REPLY_COMMENT_PHOTO');
export const UPLOAD_COMMENT_PHOTO_UPDATE = requestActions(MODULE_NAME, 'UPLOAD_COMMENT_PHOTO_UPDATE');
export const UPLOAD_REPLY_COMMENT_PHOTO_UPDATE = requestActions(MODULE_NAME, 'UPLOAD_REPLY_COMMENT_PHOTO_UPDATE');
export const DELETE_COMMENT_PHOTO = requestActions(MODULE_NAME, 'DELETE_COMMENT_PHOTO');
export const DELETE_REPLY_COMMENT_PHOTO = requestActions(MODULE_NAME, 'DELETE_REPLY_COMMENT_PHOTO');
export const REMOVE_PREVIEW = 'REMOVE_PREVIEW';
export const REMOVE_PREVIEW_UPDATE = 'REMOVE_PREVIEW_UPDATE';
export const REMOVE_PREVIEW_COMMNETS = 'REMOVE_PREVIEW_COMMNETS';
export const SAVE_POST = requestActions(MODULE_NAME, 'SAVE_POST');
export const COPY_LINK = 'COPY_LINK';
export const CLEAN_IMAGE_PREVIEW = 'CLEAN_IMAGE_PREVIEW';
export const DELETE_POST_PHOTO = requestActions(MODULE_NAME, 'DELETE_POST_PHOTO');
export const POST_DELETE_ACTION = requestActions(MODULE_NAME, 'POST_DELETE_ACTION');
export const GET_SEARCH = requestActions(MODULE_NAME, 'GET_SEARCH');
export const GET_SEARCH_PAGE = requestActions(MODULE_NAME, 'GET_SEARCH_PAGE');
export const REPORT_REASON_LIST = requestActions(MODULE_NAME, 'REPORT_REASON_LIST');
export const REPORT_POST = requestActions(MODULE_NAME, 'REPORT_POST');
export const PLAY_VIDEO = 'PLAY_VIDEO';
export const RESET_PAGE = 'RESET_PAGE';
export const START_LOADING = 'START_LOADING';
export const PLAY_COMMENT_VIDEO = 'PLAY_COMMENT_VIDEO';
export const GET_GROUP_YOU_MAY_KNOW = requestActions(MODULE_NAME, 'GET_GROUP_YOU_MAY_KNOW');
export const GET_PEOPLE_YOU_MAY_KNOW = requestActions(MODULE_NAME, 'GET_PEOPLE_YOU_MAY_KNOW');
export const CLEAN_COMMENT_CREATION = 'CLEAN_COMMENT_CREATION';
export const CLEAN_REPLY_COMMENT_CREATION = 'CLEAN_REPLY_COMMENT_CREATION';
export const DELETE_REPLY_COMMENTS = requestActions(MODULE_NAME, 'DELETE_REPLY_COMMENTS');

/**
 * Action creators
 */
export const resetPage = () => ({ type: RESET_PAGE });
export const getFeed = ({ page, reload = false }) => requestApiCall(api.names.getFeed, { page, reload }, GET_FEED);
export const getComments = ({
    commentType = 'user',
    postId,
    page,
    isProfile,
    isImagePreview = false,
    reload = false,
}) =>
    requestApiCall(
        api.names.getComments,
        { commentType, postId, page, isProfile, isImagePreview, reload },
        GET_COMMENTS
    );
export const editComment = ({
    commentType = 'user',
    postId,
    commentId,
    text,
    gif,
    isProfile,
    preview,
    isImagePreview = false,
}) =>
    requestApiCall(
        api.names.editComment,
        { postId, commentId, text, gif, preview, isProfile, isImagePreview, commentType },
        EDIT_COMMENTS
    );
export const editReplyComment = ({
    postId,
    commentId,
    text,
    gif,
    level,
    parentCommentId,
    level1CommentId,
    level2CommentId,
    level3CommentId,

    isProfile,
    preview,
    isImagePreview = false,
    commentType = 'user',
}) =>
    requestApiCall(
        api.names.editComment,
        {
            postId,
            commentId,
            text,
            gif,
            preview,
            isProfile,
            isImagePreview,
            level,
            parentCommentId,
            level1CommentId,
            level2CommentId,
            level3CommentId,
            commentType,
        },
        EDIT_REPLY_COMMENTS
    );
export const addNewComment = ({
    commentType = 'user',
    postId,
    text,
    gif,
    isProfile,
    preview,
    isImagePreview = false,
}) =>
    requestApiCall(
        api.names.addNewComment,
        { postId, text, gif, preview, isProfile, isImagePreview, commentType },
        ADD_NEW_COMMENTS
    );
export const deleteComment = ({ commentType = 'user', commentId, postId, isProfile, isImagePreview = false }) =>
    requestApiCall(
        api.names.deleteComment,
        { commentId, postId, isProfile, isImagePreview, commentType },
        DELETE_COMMENTS
    );
export const hideComments = ({ postId, isProfile, isImagePreview = false }) => ({
    type: HIDE_COMMENTS,
    payload: { postId, isProfile, isImagePreview },
});
export const cleanAfterSuccessfullCommentCreation = () => ({ type: CLEAN_COMMENT_CREATION });

export const getReplyComments = ({
    postId,
    commentId,
    parentCommentId,
    level1CommentId,
    level2CommentId,
    level3CommentId,
    level,
    page,
    isProfile,
    isImagePreview = false,
    commentType = 'user',
    reload = false,
}) =>
    requestApiCall(
        api.names.getReplyComments,
        {
            postId,
            commentId,
            parentCommentId,
            level1CommentId,
            level2CommentId,
            level3CommentId,
            level,
            page,
            isProfile,
            isImagePreview,
            commentType,
            reload,
        },
        GET_REPLY_COMMENTS
    );
export const replyToComments = ({
    commentId,
    postId,
    parentCommentId,
    level1CommentId,
    level2CommentId,
    level3CommentId,
    text,
    level,
    gif,
    isProfile,
    preview,
    isImagePreview = false,
    isImageUpload = false,
    commentType = 'user',
}) =>
    requestApiCall(
        api.names.replyToComments,
        {
            commentId,
            postId,
            parentCommentId,
            level1CommentId,
            level2CommentId,
            level3CommentId,
            level,
            text,
            gif,
            preview,
            isProfile,
            isImagePreview,
            isImageUpload,
            commentType,
        },
        REPLY_TO_COMMENTS
    );
export const cleanAfterSuccessfullReplyCommentCreation = () => ({
    type: CLEAN_REPLY_COMMENT_CREATION,
});

export const uploadReplyCommentPhoto = ({
    file,
    commentId,
    parentCommentId,
    level1CommentId,
    level2CommentId,
    level3CommentId,
    postId,
    level,
    isProfile,
    commentType = 'user',
}) =>
    requestApiCall(
        api.names.uploadPostPhoto,
        {
            file,
            commentId,
            postId,
            parentCommentId,
            level1CommentId,
            level2CommentId,
            level3CommentId,
            level,
            isProfile,
            commentType,
        },
        UPLOAD_REPLY_COMMENT_PHOTO
    );

export const upvote = ({ postId, isProfile = false }) =>
    requestApiCall(api.names.upvote, { postId, isProfile }, UPVOTE);
export const downvote = ({ postId, isProfile = false }) =>
    requestApiCall(api.names.downvote, { postId, isProfile }, DOWNVOTE);
export const upvoteForImagePreview = ({ postId }) =>
    requestApiCall(api.names.upvote, { postId }, UP_VOTE_FOR_IMAGE_PREVIEW);
export const downvoteForImagePreview = ({ postId }) =>
    requestApiCall(api.names.downvote, { postId }, DOWN_VOTE_FOR_IMAGE_PREVIEW);
export const createNewPost = ({ postType = 'user', entityId, post, preview, gif }) =>
    requestApiCall(api.names.createNewPost, { postType, entityId, post, preview, gif }, CREATE_POST);
export const editPost = ({ postType = 'user', entityId, post, preview, gif, isProfile = false }) =>
    requestApiCall(api.names.editPost, { postType, entityId, post, preview, gif, isProfile }, EDIT_POST);
export const cleanAfterSuccessfullPostCreation = () => ({ type: CLEAN_POSTS_CREATION });
export const cleanAfterSuccessfullPostEdit = () => ({ type: CLEAN_POSTS_EDIT });
export const cleanPosts = (isProfile = false) => ({ type: CLEAN_POSTS, payload: { isProfile } });
export const onImagePreviewModal = isModal => ({
    type: ON_IMAGE_PREVIEW_MODAL,
    payload: isModal,
});
export const playVideo = ({ postId, isProfile }) => ({ type: PLAY_VIDEO, payload: { postId, isProfile } });
export const playCommentVideo = ({ postId, commentId, isProfile }) => ({
    type: PLAY_COMMENT_VIDEO,
    payload: { postId, commentId, isProfile },
});

export const getUsersList = term => requestApiCall(api.names.getUsersList, { term }, GET_USER_LIST);
export const getUserPost = postId => requestApiCall(api.names.getUserPost, { postId }, GET_USER_POST);
export const getPreviewOfLink = url => requestApiCall(api.names.getPreviewOfLink, { url }, GET_PREVIEW_OF_LINK);
export const getPreviewOfLinkUpdate = url =>
    requestApiCall(api.names.getPreviewOfLinkUpdate, { url }, GET_PREVIEW_OF_LINK_UPDATE);
export const getPreviewOfLinkComments = url =>
    requestApiCall(api.names.getPreviewOfLinkComments, { url }, GET_PREVIEW_OF_LINK_COMMENT);
export const removePreview = () => ({ type: REMOVE_PREVIEW });
export const removePreviewUpdate = () => ({ type: REMOVE_PREVIEW_UPDATE });
export const removePreviewComments = () => ({ type: REMOVE_PREVIEW_COMMNETS });
export const getPreviewOfLinkReplyComments = ({ url, level }) =>
    requestApiCall(api.names.getPreviewOfLinkComments, { url, level }, GET_PREVIEW_OF_LINK_REPLY_COMMENT);
export const savePost = ({ postType, postId, postAction, isProfile = false }) =>
    requestApiCall(api.names.savePost, { postType, postId, postAction, isProfile }, SAVE_POST);
export const copyLink = ({ postId }) => ({
    type: COPY_LINK,
    payload: { postId, args: { notification: { text: 'PostOptions.copyMessage' } } },
});
export const deletePost = ({ postType, postId, isProfile = false }) =>
    requestApiCall(
        api.names.deletePost,
        { postType, postId, notification: { text: 'PostDeleteModal.deleteSuccess' }, isProfile },
        POST_DELETE_ACTION
    );
export const getGifs = name => requestApiCall(api.names.getGifs, { name }, GET_GIFS);
export const uploadPostPhoto = file => requestApiCall(api.names.uploadPostPhoto, { file }, UPLOAD_POST_PHOTO);
export const uploadPostPhotoUpdate = file =>
    requestApiCall(api.names.uploadPostPhotoUpdate, { file }, UPLOAD_POST_PHOTO_UPDATE);
export const deletePostPhoto = key => requestApiCall(api.names.deletePostPhoto, { key }, DELETE_POST_PHOTO);
export const uploadCommentPhoto = file => requestApiCall(api.names.uploadPostPhoto, { file }, UPLOAD_COMMENT_PHOTO);
export const uploadCommentPhotoUpdate = file =>
    requestApiCall(
        api.names.uploadPostPhoto,
        {
            file,
        },
        UPLOAD_COMMENT_PHOTO_UPDATE
    );
export const uploadReplyCommentPhotoUpdate = ({
    file,
    commentId,
    postId,
    parentCommentId,
    level1CommentId,
    level2CommentId,
    level3CommentId,
    level,
    isProfile,
    isImagePreview = false,
    commentType = 'user',
}) =>
    requestApiCall(
        api.names.uploadPostPhoto,
        {
            file,
            commentId,
            postId,
            parentCommentId,
            level1CommentId,
            level2CommentId,
            level3CommentId,
            level,
            isProfile,
            isImagePreview,
            commentType,
        },
        UPLOAD_REPLY_COMMENT_PHOTO_UPDATE
    );
export const deleteCommentPhoto = key => requestApiCall(api.names.deletePostPhoto, { key }, DELETE_COMMENT_PHOTO);
export const deleteReplyCommentPhoto = ({
    level,
    parentCommentId,
    level1CommentId,
    level2CommentId,
    level3CommentId,
    postId,
    key,
    commentId,
    isProfile,
    isImagePreview = false,
    commentType = 'user',
}) =>
    requestApiCall(
        api.names.deletePostPhoto,
        {
            key,
            isProfile,
            isImagePreview,
            commentId,
            postId,
            level,
            parentCommentId,
            level1CommentId,
            level2CommentId,
            level3CommentId,
            commentType,
        },
        DELETE_REPLY_COMMENT_PHOTO
    );
export const reportReasonList = () => requestApiCall(api.names.reportReasonList, {}, REPORT_REASON_LIST);
export const reportPost = ({ postId, reason, details }) =>
    requestApiCall(
        api.names.reportPost,
        { postId, reason, details, notification: { text: 'PostReportModal.reportSuccess' } },
        REPORT_POST
    );
export const cleanImagePreview = () => ({
    type: CLEAN_IMAGE_PREVIEW,
});

export const getGroupYouMayKnow = ({ perPage }) =>
    requestApiCall(api.names.getGroupsYouMayKnow, { perPage }, GET_GROUP_YOU_MAY_KNOW);
export const getPeopleYouMayKnow = ({ page, perPage }) =>
    requestApiCall(api.names.getPeopleYouMayKnow, { page, perPage }, GET_PEOPLE_YOU_MAY_KNOW);

export const getGroupsPost = ({ entityId, reload = false, page }) =>
    requestApiCall(api.names.getGroupsPost, { entityId, reload, page }, GET_FEED);

export const getCompanyPost = ({ entityId, reload = false, page }) =>
    requestApiCall(api.names.getCompanyPost, { entityId, reload, page }, GET_FEED);

export const startLoad = () => ({ type: START_LOADING });
export const deleteReplyComment = ({
    commentType = 'user',
    commentId,
    postId,
    isProfile,
    isImagePreview = false,
    parentCommentId,
    level1CommentId,
    level2CommentId,
    level3CommentId,
    level,
}) =>
    requestApiCall(
        api.names.deleteComment,
        {
            commentId,
            postId,
            isProfile,
            isImagePreview,
            commentType,
            parentCommentId,
            level1CommentId,
            level2CommentId,
            level3CommentId,
            level,
        },
        DELETE_REPLY_COMMENTS
    );
