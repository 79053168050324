import PropTypes from 'prop-types';
import React from 'react';
import styles from './Box.module.scss';

export const Box = props => (
    <div className={`${styles.box} ${props.className}`}>
        <div className={styles.innerBox}>{props.children}</div>
    </div>
);

Box.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
};
