import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './Footer.module.scss';
import { Text } from '../controls';
import { LEGAL_PRIVACY, LEGAL_TERMS, LEGAL_COOKIES, LEGAL_REFUNDS, LEGAL_DISCLOSURES } from '../../routesMap';

export const loadTermlyScript = () => {
    const id = '8e7cb9e9-e363-4ce2-a8f2-14fcf0712772';
    if (document.getElementById(id)) return;

    const element = document.createElement('script');
    element.type = 'text/javascript';
    element.async = true;
    element.src = 'https://app.termly.io/embed.min.js';
    element.id = id;
    const js = document.getElementsByTagName('script')[0];
    js.parentNode.insertBefore(element, js);
};

export const showPreferenceModal = () => {
    window.displayPreferenceModal();
};

export const Footer = props => {
    const { openLinksInNewTab = false } = props;
    const navLinkTarget = openLinksInNewTab ? '_blank' : '_self';
    useEffect(() => {
        loadTermlyScript();
    }, []);
    const year = new Date().getFullYear();
    return (
        <div className={styles.footer}>
            <div className={`${styles.links}`}>
                <div className={`${styles.innerBox}`}>
                    <NavLink target={navLinkTarget} className={`${styles.link} ${styles.first}`} to={LEGAL_PRIVACY}>
                        <Text>Footer.privacy</Text>
                    </NavLink>
                    <NavLink target={navLinkTarget} className={styles.link} to={LEGAL_TERMS}>
                        <Text>Footer.terms</Text>
                    </NavLink>
                    <NavLink target={navLinkTarget} className={styles.link} to={LEGAL_COOKIES}>
                        <Text>Footer.cookies</Text>
                    </NavLink>
                    <NavLink target={navLinkTarget} className={styles.link} to={LEGAL_REFUNDS}>
                        <Text>Footer.refoundPolicy</Text>
                    </NavLink>
                    <NavLink target={navLinkTarget} className={styles.link} to={LEGAL_DISCLOSURES}>
                        <Text>Footer.legalDisclosures</Text>
                    </NavLink>
                    <NavLink className={`${styles.link} ${styles.doNotSell}`} onClick={showPreferenceModal} to={'#'}>
                        <Text>Footer.doNotSell</Text>
                    </NavLink>
                    <span className={`${styles.link} ${styles.last}`}>
                        <Text year={year}>Footer.someeGlobal</Text>
                    </span>
                </div>
            </div>
        </div>
    );
};

Footer.propTypes = {
    openLinksInNewTab: PropTypes.bool,
};
