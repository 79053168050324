import client from '../client';

export const listAlbums = ({ userId, page, searchText = '' }) =>
    client.get(`/api/gallery/albums/${userId}?page=${page}&per_page=6&term=${searchText}`);

export const createAlbum = ({ title, privacy }) =>
    client.post(`/api/gallery/albums`, {
        name: title,
        privacy,
    });

export const editAlbum = ({ albumId, title, privacy }) =>
    client.post(`/api/gallery/albums/${albumId}`, {
        name: title,
        privacy,
    });
export const deleteAlbum = ({ albumId }) => client.delete(`/api/gallery/albums/${albumId}`);
export const listPhotos = ({ userId, albumId, page }) =>
    client.get(`/api/gallery/photos?user_id=${userId}&album_id=${albumId}&page=${page}&per_page=5`);
export const uploadPhoto = ({ file }) => client.post('/upload', file);
export const deletePhoto = ({ photoKey }) => client.delete(`/api/files/key/${photoKey}`);
