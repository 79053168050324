import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Text, Span } from '..';
import styles from './PasswordChecker.module.scss';
import defaultSvg from '../../../assets/blackCircle.svg';
import validSvg from '../../../assets/correct.svg';
import invalidSvg from '../../../assets/incorrect.svg';

const PasswordError = ({ testid, error }) => (
    <div data-testid={testid} className={cx(styles.invalid, styles.default)}>
        <img className={styles.img} src={invalidSvg} alt={invalidSvg} />
        <Text>{error}</Text>
    </div>
);

const PasswordDefault = ({ testid, value }) => (
    <div data-testid={testid} className={styles.default}>
        <img className={styles.img} src={defaultSvg} alt={defaultSvg} />
        <Text>{value}</Text>
    </div>
);

const PasswordValid = ({ testid, valid }) => (
    <div data-testid={testid} className={cx(styles.valid, styles.default)}>
        <img className={styles.img} src={validSvg} alt={validSvg} />
        <Text>{valid}</Text>
    </div>
);

const PasswordChecker = props => {
    const { fieldPassword, fieldConfPassword, onPasswordChange } = props;
    let min8Valid;
    let upperCaseValid;
    let lowerCaseValid;
    let numberValid;
    let specialCharValid;
    let passwordMatchValid;

    const min8 = value => {
        if (value === '') {
            min8Valid = false;
            return <PasswordDefault testid={'lenghtDefault'} value={'ChangePassword.characters'} />;
        }
        if (value.length >= 8) {
            min8Valid = true;
            return <PasswordValid valid={'ChangePassword.characters'} />;
        }
        min8Valid = false;
        return <PasswordError error={'ChangePassword.characters'} />;
    };

    const upperCase = value => {
        const regex = new RegExp('[A-Z]');
        if (value === '') {
            upperCaseValid = false;
            return <PasswordDefault testid={'upperCaseDefault'} value={'ChangePassword.capitalLetter'} />;
        }
        if (regex.test(value)) {
            upperCaseValid = true;
            return <PasswordValid valid={'ChangePassword.capitalLetter'} />;
        }
        upperCaseValid = false;
        return <PasswordError error={'ChangePassword.capitalLetter'} />;
    };

    const lowerCase = value => {
        const regex = new RegExp('[a-z]');
        if (value === '') {
            lowerCaseValid = false;
            return <PasswordDefault testid={'lowerCaseDefault'} value={'ChangePassword.lowerCaseLetter'} />;
        }
        if (regex.test(value)) {
            lowerCaseValid = true;
            return <PasswordValid valid={'ChangePassword.lowerCaseLetter'} />;
        }
        lowerCaseValid = false;
        return <PasswordError error={'ChangePassword.lowerCaseLetter'} />;
    };

    const number = value => {
        const regex = new RegExp('[0-9]');
        if (value === '') {
            numberValid = false;
            return <PasswordDefault testid={'numberDefault'} value={'ChangePassword.number'} />;
        }
        if (regex.test(value)) {
            numberValid = true;
            return <PasswordValid valid={'ChangePassword.number'} />;
        }
        numberValid = false;
        return <PasswordError error={'ChangePassword.number'} />;
    };

    const specialChar = value => {
        const regex = new RegExp('[!@#$%^&*(),_.?":{}|<>+-]');
        if (value === '') {
            specialCharValid = false;
            return <PasswordDefault testid={'specialCharDefault'} value={'ChangePassword.specialCharacter'} />;
        }
        if (regex.test(value)) {
            specialCharValid = true;
            return <PasswordValid valid={'ChangePassword.specialCharacter'} />;
        }
        specialCharValid = false;
        return <PasswordError error={'ChangePassword.specialCharacter'} />;
    };
    const passMatch = value => {
        if (value === '') {
            passwordMatchValid = false;
            return <PasswordDefault testid={'PasswordMatchDefault'} value={'ChangePassword.passwordMatch'} />;
        }
        if (value === fieldConfPassword) {
            passwordMatchValid = true;
            return <PasswordValid valid={'ChangePassword.passwordMatch'} />;
        }
        passwordMatchValid = false;
        return <PasswordError error={'ChangePassword.passwordMatch'} />;
    };
    const valid = () => {
        if (min8Valid && upperCaseValid && lowerCaseValid && numberValid && specialCharValid && passwordMatchValid) {
            return onPasswordChange(false);
        }
        return onPasswordChange(true);
    };
    const validations = [min8, upperCase, lowerCase, number, specialChar, passMatch, valid];
    return (
        <div className={styles.container}>
            <Span>ChangePassword.passwordAtLeast</Span>
            <div className={styles.patterns}>{validations.map(validation => validation(fieldPassword))}</div>
        </div>
    );
};

export default PasswordChecker;

PasswordChecker.defaultProps = {
    fieldPassword: '',
};

PasswordChecker.propTypes = {
    fieldPassword: PropTypes.string,
    fieldConfPassword: PropTypes.string,
    onPasswordChange: PropTypes.func,
};

PasswordDefault.propTypes = {
    testid: PropTypes.string,
    value: PropTypes.any,
};
PasswordError.propTypes = {
    error: PropTypes.string,
    testid: PropTypes.string,
};
PasswordValid.propTypes = {
    testid: PropTypes.string,
    valid: PropTypes.any,
};
