import { MODULE_NAME } from './communicationConstants';
import { requestActions } from '../actionsCreator';
import { requestApiCall } from '../connectivity/connectivityActions';
import api from '../../api';

/**
 * Actions constants
 */

export const UPDATE_ONLINE_STATUS = requestActions(MODULE_NAME, 'UPDATE_ONLINE_STATUS');

export const FETCH_OTHER_USERS_ONLINE_STATUSES = requestActions(MODULE_NAME, 'FETCH_OTHER_USERS_ONLINE_STATUSES');

export const UPDATE_OTHER_USER_ONLINE_STATUS = requestActions(MODULE_NAME, 'UPDATE_OTHER_USER_ONLINE_STATUS');

export const DO_ROLLCALL_PING = requestActions(MODULE_NAME, 'DO_ROLLCALL_PING');

/**
 * Action creators
 */

export const updateOnlineStatus = newStatus => requestApiCall(api.names.updateOnlineStatus, newStatus, UPDATE_ONLINE_STATUS);
