import produce from 'immer';
import * as actions from './companyActions';
import * as groupActions from '../groups/groupActions';
import { getErrorFrom } from '../utils';

const initialState = {
    isLoading: true,
    error: undefined,
    success: false,
    companies: [],
    hasMore: true,
    page: 1,
    count: 0,
    managed: {
        isLoading: true,
        error: undefined,
        success: false,
        companies: [],
        hasMore: true,
        page: 1,
        count: 0,
    },
    suggestions: {
        isLoading: true,
        error: undefined,
        success: false,
        companies: [],
        hasMore: true,
        page: 1,
        count: 0,
    },
    createCompany: {
        isLoading: false,
        data: {},
        success: true,
    },
    coverImage: {
        success: false,
        data: {},
        isLoading: false,
    },
    logoImage: {
        success: false,
        data: {},
        isLoading: false,
    },
    categories: [],
    subCategories: [],
    specificCompany: {
        isLoading: true,
        error: undefined,
        success: false,
        company: {},
    },
    members: {
        isLoading: true,
        error: undefined,
        success: false,
        members: [],
        admins: [],
        allMembers: [],
        allAdmins: [],
        hasMore: true,
        page: 1,
        count: 0,
        allCount: 0,
    },
    membersToInvite: {
        isLoading: true,
        error: undefined,
        success: false,
        members: [],
        hasMore: true,
        page: 1,
    },
    editCompany: {
        isLoading: false,
    },
};

export const reducer = produce((state = initialState, { type, payload }) => {
    const data = payload?.data;
    switch (type) {
        case actions.ADD_MEMBER_TO_COMPANY.SUCCEEDED: {
            state.membersToInvite.members = state.membersToInvite.members?.filter(
                item => item?.id !== payload?.args?.userId
            );

            state.members.allCount += 1;
            state.members.members = [
                ...state.members.members,
                { user: payload?.args?.user, type: 'member', position: payload?.args?.position },
            ];
            break;
        }
        case actions.LIST_USER_TO_INVITE.REQUESTED: {
            if (payload?.args?.reload) {
                state.membersToInvite.members = [];
            }
            state.membersToInvite.page = payload.args.page;

            state.membersToInvite.isLoading = true;
            state.membersToInvite.error = undefined;
            state.membersToInvite.success = false;
            state.membersToInvite.hasMore = true;

            break;
        }
        case actions.LIST_USER_TO_INVITE.SUCCEEDED: {
            state.membersToInvite.isLoading = false;
            state.membersToInvite.error = undefined;
            state.membersToInvite.success = true;

            if (data?.list && data?.list?.length > 0) {
                data.list[data?.list?.length - 1].position = data.list.length;
                state.membersToInvite.members = [...state.membersToInvite.members, ...data?.list];
            } else {
                state.membersToInvite.hasMore = false;
            }
            break;
        }
        case actions.DELETE_MEMBER.SUCCEEDED: {
            const removedUserId = payload?.args?.userId;

            state.members.members = state.members.members.filter(item => item.user?.id !== removedUserId);
            state.members.allMembers = state.members.allMembers.filter(item => item.user?.id !== removedUserId);

            state.members.count -= 1;
            state.members.allCount -= 1;
            break;
        }
        case actions.CHANGE_USER_ROLE.SUCCEEDED: {
            const role = payload.args?.role;

            const userIndex = state.members.members?.findIndex(item => item?.user?.id === payload?.args?.userId);
            const user = state.members.members?.find(item => item?.user?.id === payload?.args?.userId);

            if (userIndex !== -1) {
                state.members.members[userIndex].type = role;

                if (role === 'moderator') {
                    state.members.admins.push(user);
                    state.members.allAdmins.push(user);
                } else {
                    state.members.admins = state.members.admins.filter(item => item.user?.id !== payload?.args?.userId);
                    state.members.allAdmins = state.members.allAdmins.filter(
                        item => item.user?.id !== payload?.args?.userId
                    );
                }
            }
            break;
        }
        case actions.GET_COMPANY_MEMBERS.REQUESTED: {
            if (payload?.args?.reload) {
                state.members.members = [];
                state.members.count = 0;
            }

            if (payload?.args?.reloadAllMembers) {
                state.members.allMembers = [];
                state.members.allAdmins = [];
                state.members.allCount = 0;
            }

            state.members.isLoading = true;
            state.members.success = false;
            state.members.page = payload.args.page;
            state.members.hasMore = true;
            break;
        }
        case actions.GET_COMPANY_MEMBERS.SUCCEEDED: {
            if (payload?.args?.reload) {
                state.members.members = [];
                state.members.admins = [];
            }

            if (payload?.args?.reloadAllMembers) {
                state.members.allMembers = [];
                state.members.allAdmins = [];
            }

            const admins = [];
            [...state.members.allAdmins, ...data?.list]?.forEach(item => {
                if (item.type === 'owner' || item.type === 'moderator') {
                    admins.push(item);
                }
            });

            if (payload?.args?.searchTerm === '') {
                state.members.allAdmins = admins;
            }

            state.members.count = data?.count;

            state.members.admins = admins;

            state.members.isLoading = false;
            state.members.error = undefined;
            state.members.success = true;

            if (data?.list && data?.list?.length > 0) {
                data.list[data?.list?.length - 1].user.position = data.list.length;
                state.members.members = [...state.members.members, ...data?.list];
                if (payload?.args?.reloadAllMembers) {
                    state.members.allMembers = [...state.members.allMembers, ...data?.list];
                    state.members.allCount = data?.count;
                }
            } else {
                state.members.hasMore = false;
            }
            break;
        }
        case actions.GET_SPECIFIC_COMPANY.REQUESTED: {
            state.specificCompany.isLoading = true;
            state.specificCompany.error = undefined;
            state.specificCompany.success = false;
            state.specificCompany.company = {};
            state.members.allMembers = [];
            state.members.allAdmins = [];
            state.members.admins = [];
            state.members.count = 0;
            state.members.allCount = 0;
            state.members.members = [];
            state.members.page = 1;
            state.members.hasMore = true;
            break;
        }
        case actions.GET_SPECIFIC_COMPANY.SUCCEEDED: {
            state.specificCompany.error = undefined;
            state.specificCompany.success = true;
            if (data) {
                state.specificCompany.company = data;
                state.specificCompany.isLoading = false;
            }
            break;
        }
        case actions.COMPANY_ACTION.SUCCEEDED: {
            const action = payload?.args?.action;
            const companyIndex = state.companies?.findIndex(item => item?.id === payload?.args?.companyId);

            if (action === 'unfollow') {
                state.companies = state.companies.filter(item => item?.id !== payload?.args?.companyId);
                state.count -= 1;
            }
            if (action === 'follow' && companyIndex !== -1) {
                state.companies[companyIndex].isFollow = true;
            }

            break;
        }
        case actions.END_COMPAY_CREATION: {
            state.createCompany.isLoading = false;
            state.createCompany.data = {};
            state.createCompany.success = false;
            state.logoImage.isLoading = false;
            state.logoImage.success = false;
            state.coverImage.isLoading = false;
            state.coverImage.success = false;
            break;
        }
        case actions.UPLOAD_PROFILE_PHOTO.REQUESTED: {
            state.logoImage.isLoading = true;
            state.logoImage.success = false;
            break;
        }
        case actions.UPLOAD_PROFILE_PHOTO.SUCCEEDED: {
            state.logoImage.isLoading = false;
            state.logoImage.success = true;

            if (state.specificCompany.company?.id) {
                state.specificCompany.company = { ...state.specificCompany.company, avatar: { key: data?.fileId } };
            }
            break;
        }
        case actions.UPLOAD_BACKGROUND_PHOTO.REQUESTED: {
            state.coverImage.isLoading = true;
            state.coverImage.success = false;
            break;
        }
        case actions.UPLOAD_BACKGROUND_PHOTO.SUCCEEDED: {
            state.coverImage.isLoading = false;
            state.coverImage.success = true;

            if (state.specificCompany.company?.id) {
                state.specificCompany.company = { ...state.specificCompany.company, cover: { key: data?.fileId } };
            }
            break;
        }
        case actions.CREATE_COMPANY.REQUESTED: {
            state.createCompany.isLoading = true;
            state.createCompany.data = {};
            state.createCompany.success = false;
            break;
        }
        case actions.CREATE_COMPANY.SUCCEEDED: {
            state.createCompany.data = data;
            state.createCompany.success = true;

            break;
        }
        case actions.EDIT_COMPANY.REQUESTED: {
            state.editCompany.isLoading = true;
            break;
        }
        case actions.EDIT_COMPANY.SUCCEEDED: {
            state.editCompany.isLoading = false;
            break;
        }
        case actions.EDIT_COMPANY.FAILED: {
            state.editCompany.isLoading = false;
            break;
        }
        case groupActions.GET_GROUPS_CATEGORIES.SUCCEEDED: {
            const groupsList = data?.lists?.companyCategories;
            const categories = [];
            const subCategories = [];

            groupsList?.map(item => {
                categories.push({ value: item?.category, label: item?.category });
                subCategories.push(item);
            });

            state.categories = categories;
            state.subCategories = subCategories;

            break;
        }
        case actions.GET_COMPANIES.REQUESTED:
            if (payload?.args?.reload) {
                state.companies = [];
            }
            state.isLoading = true;
            state.error = undefined;
            state.success = false;
            state.page = payload.args.page;
            state.hasMore = true;
            break;
        case actions.GET_COMPANIES.SUCCEEDED: {
            if (payload?.args?.reload) {
                state.companies = [];
            }

            state.isLoading = false;
            state.error = undefined;
            state.success = true;

            state.count = data?.count;

            if (data?.list && data?.list?.length > 0) {
                data.list[data?.list?.length - 1].position = data.list.length;
                state.companies = [...state.companies, ...data?.list];
                state.hasMore = true;
            } else {
                state.hasMore = false;
            }
            break;
        }
        case actions.GET_MANAGED_COMPANIES.REQUESTED: {
            if (payload?.args?.reload) {
                state.managed.companies = [];
            }
            state.managed.isLoading = true;
            state.managed.error = undefined;
            state.managed.success = false;
            state.managed.page = payload.args.page;
            state.managed.hasMore = true;
            break;
        }
        case actions.GET_MANAGED_COMPANIES.SUCCEEDED: {
            if (payload?.args?.reload) {
                state.managed.companies = [];
            }

            state.managed.isLoading = false;
            state.managed.error = undefined;
            state.managed.success = true;
            state.managed.count = data?.count;

            if (data?.list && data?.list?.length > 0) {
                data.list[data?.list?.length - 1].position = data.list.length;
                state.managed.companies = [...state.managed.companies, ...data?.list];
                state.managed.hasMore = true;
            } else {
                state.managed.hasMore = false;
            }
            break;
        }
        case actions.GET_SUGGESTION_COMPANIES.REQUESTED: {
            if (payload?.args?.reload) {
                state.suggestions.companies = [];
            }
            state.suggestions.isLoading = true;
            state.suggestions.error = undefined;
            state.suggestions.success = false;
            state.suggestions.page = payload.args.page;
            state.suggestions.hasMore = true;
            break;
        }
        case actions.GET_SUGGESTION_COMPANIES.SUCCEEDED: {
            if (payload?.args?.reload) {
                state.suggestions.companies = [];
            }

            state.suggestions.isLoading = false;
            state.suggestions.error = undefined;
            state.suggestions.success = true;
            state.suggestions.count = data?.count;

            if (data?.list && data?.list?.length > 0) {
                data.list[data?.list?.length - 1].position = data.list.length;
                state.suggestions.companies = [...state.suggestions.companies, ...data?.list];
                state.suggestions.hasMore = true;
            } else {
                state.suggestions.hasMore = false;
            }
            break;
        }
        default:
            return state;
    }
    return state;
});

export default reducer;
