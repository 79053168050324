import client from '../client';

export const getFollowers = ({ userId, page, searchTerm, filters = '' }) =>
    client.get(`/api/follower/followers/${userId}?page=${page}&per_page=20&term=${searchTerm}${filters}`);

export const getFollowing = ({ userId, page, searchTerm, filters = '' }) =>
    client.get(`/api/follower/following/${userId}?page=${page}&per_page=20&term=${searchTerm}${filters}`);

export const unFollowUser = ({ userId }) => client.delete(`/api/follower/${userId}/unfollow`);

export const removeFollower = ({ userId }) => client.post(`/api/follower/remove/${userId}`);

export const sendFollowInvite = ({ userId }) => client.post(`/api/follower/invite/${userId}`);

export const directFollow = ({ userId }) => client.post(`/api/follower/${userId}/follow`);

export const cancelFollowInvite = ({ userId }) => client.delete(`/api/follower/invite/${userId}`);
