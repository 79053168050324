import client from '../client';

// https://dev1.somee.social/api/dict
// https://dev1.somee.social/api/status
// https://dev1.somee.social/api/exchange/coins/latesttoken/ong-social
// https://dev1.somee.social/api/user/user/new
// https://dev1.somee.social/api/users/auth

export const getCurrentUser = () => client.get('/api/user/user/new');

export const editUser = user => client.patch('/api/user/edit', user);

export const editUserBcSettings = settings => client.post('/api/profile/editBlockchainSetting', settings);

export const changePassword = ({ oldPassword, newPassword, newPasswordRepeat }) =>
    client.post('/api/profile/change-password', {
        oldPassword,
        newPassword,
        newPasswordRepeat,
    });
