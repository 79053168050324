import produce from 'immer';
import * as actions from './twoFactorActions';
import { getErrorFrom } from '../utils';

const initialState = {
    image: {
        isLoading: false,
        error: undefined,
        data: {},
    },
    main: {
        isLoading: false,
        data: [],
        error: '',
    },
    appAuthData: {},
    appAuthDataSuccess: false,
    phoneSucceed: false,
    phoneData: {},
    submitCode: {},
    submitCodeError: '',
    phoneError: '',
    ResendCode: '',
    verifyAppAuth: false,
    verifyAppAuthData: {},
    verifyAppAuthError: '',
    deleteTwoFaError: '',
    deleteTwoFaSucceed: false,
    verifyPhoneAppError: '',
    verifyPhoneAppSucceed: false,
    verifyAuthAppDeleteSucceed: false,
    verifyAuthAppDeleteError: '',
};

export const reducer = produce((state = initialState, { type, payload }) => {
    const data = payload?.data;
    switch (type) {
        case actions.CLEAN_2FA: {
            state.image = {
                isLoading: false,
                error: undefined,
                data: {},
            };
            state.main = {
                isLoading: false,
                data: [],
                error: '',
            };
            state.appAuthData = {};
            state.appAuthDataSuccess = false;
            state.phoneSucceed = false;
            state.phoneData = {};
            state.submitCode = {};
            state.submitCodeError = '';
            state.phoneError = '';
            state.ResendCode = '';
            state.verifyAppAuth = false;
            state.verifyAppAuthData = {};
            state.verifyAppAuthError = '';
            state.deleteTwoFaError = '';
            state.deleteTwoFaSucceed = false;
            state.verifyPhoneAppError = '';
            state.verifyPhoneAppSucceed = false;
            state.verifyAuthAppDeleteSucceed = false;
            state.verifyAuthAppDeleteError = '';
            break;
        }
        case actions.VERIFY_AUTH_APP_CODE.REQUESTED: {
            state.image.isLoading = true;
            state.verifyAuthAppDeleteError = '';
            state.verifyAuthAppDeleteSucceed = false;
            break;
        }
        case actions.VERIFY_AUTH_APP_CODE.SUCCEEDED: {
            state.image.isLoading = false;
            state.verifyAuthAppDeleteError = '';
            state.verifyAuthAppDeleteSucceed = true;
            break;
        }
        case actions.VERIFY_AUTH_APP_CODE.FAILED: {
            state.image.isLoading = false;
            state.verifyAuthAppDeleteError = getErrorFrom(payload);
            state.verifyAuthAppDeleteSucceed = false;
            break;
        }
        case actions.VERIFY_PHONE_APP_CODE.REQUESTED: {
            state.image.isLoading = true;
            state.verifyPhoneAppError = '';
            state.verifyPhoneAppSucceed = false;
            break;
        }
        case actions.VERIFY_PHONE_APP_CODE.SUCCEEDED: {
            state.image.isLoading = false;
            state.verifyPhoneAppError = '';
            state.verifyPhoneAppSucceed = true;
            break;
        }
        case actions.VERIFY_PHONE_APP_CODE.FAILED: {
            state.image.isLoading = false;
            state.verifyPhoneAppError = getErrorFrom(payload);
            state.verifyPhoneAppSucceed = false;
            break;
        }
        case actions.DELETE_PHONE_AUTH.REQUESTED: {
            state.image.isLoading = true;
            state.deleteTwoFaError = '';
            state.deleteTwoFaSucceed = false;
            break;
        }
        case actions.DELETE_PHONE_AUTH.SUCCEEDED: {
            state.image.isLoading = false;
            state.deleteTwoFaError = '';
            state.deleteTwoFaSucceed = true;
            break;
        }
        case actions.DELETE_PHONE_AUTH.FAILED: {
            state.image.isLoading = false;
            state.deleteTwoFaError = getErrorFrom(payload);
            state.deleteTwoFaSucceed = false;
            break;
        }
        case actions.DELETE_APP_AUTH.REQUESTED: {
            state.image.isLoading = true;
            state.deleteTwoFaError = '';
            state.deleteTwoFaSucceed = false;
            break;
        }
        case actions.DELETE_APP_AUTH.SUCCEEDED: {
            state.image.isLoading = false;
            state.deleteTwoFaError = '';
            state.deleteTwoFaSucceed = true;
            break;
        }
        case actions.DELETE_APP_AUTH.FAILED: {
            state.image.isLoading = false;
            state.deleteTwoFaError = getErrorFrom(payload);
            state.deleteTwoFaSucceed = false;
            break;
        }
        case actions.GET_2FA.REQUESTED: {
            state.main.isLoading = true;
            state.main.error = '';
            state.main.data = {};
            state.deleteTwoFaError = '';
            state.deleteTwoFaSucceed = false;
            state.verifyAppAuthError = '';
            state.verifyAppAuthData = {};
            state.verifyAppAuth = false;
            state.ResendCode = '';
            state.phoneError = '';
            state.submitCodeError = '';
            state.submitCode = {};
            state.phoneData = {};
            state.phoneSucceed = false;
            state.appAuthDataSuccess = false;
            state.appAuthData = {};
            state.verifyPhoneAppSucceed = false;
            state.verifyPhoneAppError = '';
            state.verifyAuthAppDeleteError = '';
            state.verifyAuthAppDeleteSucceed = false;
            break;
        }
        case actions.GET_2FA.SUCCEEDED: {
            state.main.isLoading = false;
            state.main.data = data;
            state.main.error = '';
            state.deleteTwoFaError = '';
            state.deleteTwoFaSucceed = false;
            break;
        }
        case actions.GET_2FA.FAILED: {
            state.main.isLoading = false;
            state.main.data = {};
            state.main.error = getErrorFrom(payload);
            state.deleteTwoFaError = '';
            state.deleteTwoFaSucceed = false;
            break;
        }
        case actions.REGISTER_QRCODE.REQUESTED: {
            state.image.isLoading = true;
            state.appAuthData = {};
            state.appAuthDataSuccess = false;
            break;
        }
        case actions.REGISTER_QRCODE.SUCCEEDED: {
            state.image.isLoading = false;
            state.appAuthData = data;
            state.appAuthDataSuccess = true;
            break;
        }
        case actions.VERIFY_APP_AUTH.REQUESTED: {
            state.image.isLoading = true;
            state.verifyAppAuth = false;
            state.verifyAppAuthData = {};
            state.verifyAppAuthError = '';
            break;
        }
        case actions.VERIFY_APP_AUTH.SUCCEEDED: {
            state.image.isLoading = false;
            state.verifyAppAuth = true;
            state.verifyAppAuthData = data;
            state.verifyAppAuthError = '';
            break;
        }
        case actions.VERIFY_APP_AUTH.FAILED: {
            state.image.isLoading = false;
            state.verifyAppAuth = false;
            state.verifyAppAuthData = {};
            state.verifyAppAuthError = getErrorFrom(payload);
            break;
        }
        case actions.RESEND_CODE.SUCCEEDED: {
            state.ResendCode = true;
            break;
        }
        case actions.RESEND_CODE.FAILED: {
            state.ResendCode = false;
            break;
        }
        case actions.SUBMIT_CODE.REQUESTED: {
            state.image.isLoading = true;
            state.submitCode = {};
            state.submitCodeError = '';
            break;
        }
        case actions.SUBMIT_CODE.SUCCEEDED: {
            state.image.isLoading = false;
            state.submitCode = data;
            state.submitCodeError = '';
            break;
        }
        case actions.SUBMIT_CODE.FAILED: {
            state.image.isLoading = false;
            state.submitCode = {};
            state.submitCodeError = getErrorFrom(payload);
            break;
        }
        case actions.REGISTER_PHONE.REQUESTED: {
            state.phoneError = '';
            state.phoneSucceed = false;
            state.phoneData = {};
            state.image.isLoading = true;
            break;
        }
        case actions.REGISTER_PHONE.SUCCEEDED: {
            state.phoneError = '';
            state.phoneSucceed = true;
            state.phoneData = data;
            state.image.isLoading = false;
            break;
        }
        case actions.REGISTER_PHONE.FAILED: {
            state.phoneError = getErrorFrom(payload);
            state.phoneSucceed = false;
            state.phoneData = {};
            state.image.isLoading = false;
            state.image.error = true;
            break;
        }
        case actions.GENERATE_QRCODE.REQUESTED: {
            state.image.isLoading = true;
            state.image.error = undefined;
            break;
        }
        case actions.GENERATE_QRCODE.SUCCEEDED: {
            state.image.data = data;
            state.image.isLoading = false;
            state.image.error = undefined;
            break;
        }
        default:
            return state;
    }
    return state;
});

export default reducer;
