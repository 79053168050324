/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
import produce from 'immer';
import { merge } from 'lodash';
import * as actions from './feedActions';
import * as authActions from '../auth/authActions';
import * as profileActions from '../profile/profileActions';
import { getErrorFrom } from '../utils';

const initialState = {
    userFeed: {
        isLoading: false,
        posts: [],
        error: undefined,
        page: 1,
        hasMore: true,
    },
    selectedImagePreviewPost: {},
    onImagePreviewModal: false,
    imagePreviewError: false,
    selectedImagePreviewPostLoading: true,
    userList: [],
    postCreatedSuccess: false,
    postImageUploadSuccess: false,
    postCreatedError: '',
    postCreationLoading: false,
    postCreatedSuccessData: {},
    postCreatedSuccessUpdate: false,
    postImageUploadSuccessUpdate: false,
    postCreatedErrorUpdate: '',
    postCreationLoadingUpdate: false,
    postCreatedSuccessDataUpdate: {},
    previewLink: {},
    previewLoading: false,
    previewError: false,
    previewLinkUpdate: {},
    previewLoadingUpdate: false,
    previewErrorUpdate: false,
    previewLinkComment: {},
    previewLoadingComment: false,
    previewErrorComment: false,
    gifs: [],
    gifsLoading: false,
    postComments: [], // per post { isLoading, comments, page }
    deletePhotoSuccess: false,
    deletePhotoLoading: false,
    // add New Comment
    newCommentLoading: false,
    newCommentSuccess: false,
    newCommentError: false,
    newCommentData: {},
    newCommentDataToUploadImage: {},
    deleteCommentLoading: false,
    deleteCommentSuccess: false,
    deleteCommentError: false,
    editCommentLoading: false,
    editCommentSuccess: false,
    editCommentError: false,
    commentImageUploadSuccess: false,
    commentImageUploadSuccessUpdate: false,
    deleteCommentPhotoSuccess: false,
    deleteCommentPhotoLoading: false,
    // repost post
    reportReasonLoading: false,
    reportReasonList: [],
    suggestions: {
        isLoading: false,
        hasLoadedGroups: false,
        hasLoadedPeople: false,
        groups: [],
        people: [],
    },
    // reply
    replyLevel1: {
        newReplyCommentLoading: false,
        newReplyCommentSuccess: false,
        newReplyCommentError: false,
        newReplyPostData: {},
        previewLinkReplyComment: {},
        previewLoadingReplyComment: false,
        previewErrorReplyComment: false,
        newReplyCommentDataToUploadImage: {},
        replyCommentImageUploadSuccess: false,
        deleteReplyCommentPhotoSuccess: false,
        editReplyCommentLoading: true,
        editReplyCommentError: false,
        editReplyCommentSuccess: false,
        replyCommentImageUploadSuccessUpdate: false,
        editReplyCommentImageFile: '',
        deleteReplyCommentLoading: false,
    },
    replyLevel2: {
        newReplyCommentLoading: false,
        newReplyCommentSuccess: false,
        newReplyCommentError: false,
        newReplyPostData: {},
        previewLinkReplyComment: {},
        previewLoadingReplyComment: false,
        previewErrorReplyComment: false,
        newReplyCommentDataToUploadImage: {},
        replyCommentImageUploadSuccess: false,
        deleteReplyCommentPhotoSuccess: false,
        editReplyCommentLoading: true,
        editReplyCommentError: false,
        editReplyCommentSuccess: false,
        replyCommentImageUploadSuccessUpdate: false,
        editReplyCommentImageFile: '',
        deleteReplyCommentLoading: false,
    },
    replyLevel3: {
        newReplyCommentLoading: false,
        newReplyCommentSuccess: false,
        newReplyCommentError: false,
        newReplyPostData: {},
        previewLinkReplyComment: {},
        previewLoadingReplyComment: false,
        previewErrorReplyComment: false,
        newReplyCommentDataToUploadImage: {},
        replyCommentImageUploadSuccess: false,
        deleteReplyCommentPhotoSuccess: false,
        editReplyCommentLoading: true,
        editReplyCommentError: false,
        editReplyCommentSuccess: false,
        replyCommentImageUploadSuccessUpdate: false,
        editReplyCommentImageFile: '',
        deleteReplyCommentLoading: false,
    },
};

const getPostIndex = (list, post) => list.findIndex(item => item.id === post.id);
const getCommentIndex = (list, comment) => list.findIndex(item => item.id === comment.id);
const getPost = (list, post) => list.find(item => item.id === post.id);
const updatePost = (list, postChanges, clearComments) => {
    const index = getPostIndex(list, postChanges);
    const post = list[index];

    // merge new comments with old ones
    if (postChanges.commentsList) {
        const allNewPosts = post.commentsList
            ? [...post.commentsList, ...postChanges.commentsList]
            : postChanges.commentsList;

        let myArrSerialized = allNewPosts.map(e => JSON.stringify(e));
        const mySetSerialized = new Set(myArrSerialized);

        const myUniqueArrSerialized = [...mySetSerialized];

        postChanges.commentsList = myUniqueArrSerialized.map(e => JSON.parse(e));
    }

    if (clearComments) post.commentsList = [];

    list[index] = merge({}, post, postChanges);
    return list;
};

const updateComment = (list, commentChanges) => {
    const index = getCommentIndex(list, commentChanges);
    const comment = list[index];
    if (commentChanges.reload && comment.commentsReplyList) comment.commentsReplyList = [];
    if (commentChanges.commentsReplyList) {
        const allNewComment = comment.commentsReplyList
            ? [...commentChanges.commentsReplyList, ...comment.commentsReplyList]
            : commentChanges.commentsReplyList;

        let myArrSerialized = allNewComment.map(e => JSON.stringify(e));
        const mySetSerialized = new Set(myArrSerialized);

        const myUniqueArrSerialized = [...mySetSerialized];

        commentChanges.commentsReplyList = myUniqueArrSerialized.map(e => JSON.parse(e));
    }
    list[index] = merge({}, comment, commentChanges);
    return list;
};

export const reducer = produce((state = initialState, { type, payload }) => {
    const data = payload?.data;
    switch (type) {
        case actions.EDIT_COMMENTS.REQUESTED: {
            state.editCommentLoading = true;
            state.editCommentError = false;
            state.editCommentSuccess = false;
            break;
        }
        case actions.EDIT_COMMENTS.SUCCEEDED: {
            state.editCommentLoading = false;
            state.editCommentError = false;
            state.editCommentSuccess = true;

            if (payload?.args?.isProfile) {
                break;
            }

            if (payload?.args.isImagePreview) {
                state.selectedImagePreviewPost?.commentsList.push(data);
                state.selectedImagePreviewPost.comments += 1;
                break;
            }

            const postIndex = state.userFeed.posts?.findIndex(item => item?.id === payload?.args?.postId);
            const commentIndex = state.userFeed.posts[postIndex]?.commentsList?.findIndex(
                item => item?.id === payload.args?.commentId
            );
            state.userFeed.posts[postIndex]?.commentsList.splice(commentIndex, 1, data);
            break;
        }
        case actions.EDIT_COMMENTS.FAILED: {
            state.deleteCommentLoading = false;
            state.deleteCommentError = true;
            state.deleteCommentSuccess = false;
            break;
        }
        case actions.EDIT_REPLY_COMMENTS.REQUESTED: {
            if (payload.args.level === 1) {
                state.replyLevel1.editReplyCommentLoading = true;
                state.replyLevel1.editReplyCommentError = false;
                state.replyLevel1.editReplyCommentSuccess = false;
            }
            if (payload.args.level === 2) {
                state.replyLevel2.editReplyCommentLoading = true;
                state.replyLevel2.editReplyCommentError = false;
                state.replyLevel2.editReplyCommentSuccess = false;
            }
            if (payload.args.level === 3) {
                state.replyLevel3.editReplyCommentLoading = true;
                state.replyLevel3.editReplyCommentError = false;
                state.replyLevel3.editReplyCommentSuccess = false;
            }
            break;
        }
        case actions.EDIT_REPLY_COMMENTS.SUCCEEDED: {
            if (payload.args.level === 1) {
                state.replyLevel1.editReplyCommentLoading = false;
                state.replyLevel1.editReplyCommentError = false;
                state.replyLevel1.editReplyCommentSuccess = true;

                if (payload.args.isProfile) break;

                const postIndex = state.userFeed.posts?.findIndex(item => item?.id === payload?.args?.postId);
                const parentCommentIndex = state.userFeed.posts[postIndex]?.commentsList?.findIndex(
                    item => item?.id === payload.args?.parentCommentId
                );
                const commentIndex = state.userFeed.posts[postIndex]?.commentsList[
                    parentCommentIndex
                ]?.commentsReplyList.findIndex(item => item?.id === payload.args?.commentId);
                if (payload?.args?.isImageUpload) {
                    if (state.replyLevel1.editReplyCommentImageFile) {
                        state.userFeed.posts[postIndex].commentsList[parentCommentIndex].commentsReplyList[
                            commentIndex
                        ].photo.push({ key: state.replyLevel1.editReplyCommentImageFile });
                    }
                }
                state.userFeed.posts[postIndex]?.commentsList[parentCommentIndex].commentsReplyList.splice(
                    commentIndex,
                    1,
                    data
                );
                break;
            }
            if (payload.args.level === 2) {
                state.replyLevel2.editReplyCommentLoading = false;
                state.replyLevel2.editReplyCommentError = false;
                state.replyLevel2.editReplyCommentSuccess = true;
                if (payload.args.isProfile) break;

                const postIndex = state.userFeed.posts?.findIndex(item => item?.id === payload?.args?.postId);
                const parentCommentIndex = state.userFeed.posts[postIndex]?.commentsList?.findIndex(
                    item => item?.id === payload.args?.parentCommentId
                );
                const level1CommentIndex = state.userFeed.posts[postIndex]?.commentsList[
                    parentCommentIndex
                ]?.commentsReplyList.findIndex(item => item?.id === payload.args?.level1CommentId);
                const commentIndex = state.userFeed.posts[postIndex]?.commentsList[
                    parentCommentIndex
                ]?.commentsReplyList[level1CommentIndex].commentsReplyList.findIndex(
                    item => item?.id === payload.args?.commentId
                );
                if (payload?.args?.isImageUpload) {
                    if (state.replyLevel2.editReplyCommentImageFile) {
                        state.userFeed.posts[postIndex]?.commentsList[parentCommentIndex].commentsReplyList[
                            level1CommentIndex
                        ].commentsReplyList[commentIndex].photo.push({
                            key: state.replyLevel2.editReplyCommentImageFile,
                        });
                    }
                }
                state.userFeed.posts[postIndex]?.commentsList[parentCommentIndex].commentsReplyList[
                    level1CommentIndex
                ].commentsReplyList.splice(commentIndex, 1, data);
                break;
            }
            if (payload.args.level === 3) {
                state.replyLevel3.editReplyCommentLoading = false;
                state.replyLevel3.editReplyCommentError = false;
                state.replyLevel3.editReplyCommentSuccess = true;
                if (payload.args.isProfile) break;

                const postIndex = state.userFeed.posts?.findIndex(item => item?.id === payload?.args?.postId);
                const parentCommentIndex = state.userFeed.posts[postIndex]?.commentsList?.findIndex(
                    item => item?.id === payload.args?.parentCommentId
                );
                const level1CommentIndex = state.userFeed.posts[postIndex]?.commentsList[
                    parentCommentIndex
                ]?.commentsReplyList.findIndex(item => item?.id === payload.args?.level1CommentId);
                const level2CommentIndex = state.userFeed.posts[postIndex]?.commentsList[
                    parentCommentIndex
                ]?.commentsReplyList[level1CommentIndex].commentsReplyList.findIndex(
                    item => item?.id === payload.args?.level2CommentId
                );
                const commentIndex = state.userFeed.posts[postIndex]?.commentsList[
                    parentCommentIndex
                ]?.commentsReplyList[level1CommentIndex].commentsReplyList[
                    level2CommentIndex
                ].commentsReplyList.findIndex(item => item?.id === payload.args?.commentId);
                if (payload?.args?.isImageUpload) {
                    if (state.replyLevel3.editReplyCommentImageFile) {
                        state.userFeed.posts[postIndex]?.commentsList[parentCommentIndex].commentsReplyList[
                            level1CommentIndex
                        ].commentsReplyList[level2CommentIndex].commentsReplyList[commentIndex].photo.push({
                            key: state.replyLevel3.editReplyCommentImageFile,
                        });
                    }
                }
                state.userFeed.posts[postIndex]?.commentsList[parentCommentIndex].commentsReplyList[
                    level1CommentIndex
                ].commentsReplyList[level2CommentIndex].commentsReplyList.splice(commentIndex, 1, data);
                break;
            }

            break;
        }
        case actions.EDIT_REPLY_COMMENTS.FAILED: {
            if (payload.args.level === 1) {
                state.replyLevel1.editReplyCommentLoading = false;
                state.replyLevel1.editReplyCommentError = true;
                state.replyLevel1.editReplyCommentSuccess = false;
            }
            if (payload.args.level === 2) {
                state.replyLevel2.editReplyCommentLoading = false;
                state.replyLevel2.editReplyCommentError = true;
                state.replyLevel2.editReplyCommentSuccess = false;
            }
            if (payload.args.level === 3) {
                state.replyLevel3.editReplyCommentLoading = false;
                state.replyLevel3.editReplyCommentError = true;
                state.replyLevel3.editReplyCommentSuccess = false;
            }
            break;
        }
        case actions.DELETE_COMMENTS.REQUESTED: {
            state.deleteCommentLoading = true;
            state.deleteCommentError = false;
            state.deleteCommentSuccess = false;
            break;
        }
        case actions.DELETE_COMMENTS.SUCCEEDED: {
            state.deleteCommentLoading = false;
            state.deleteCommentError = false;
            state.deleteCommentSuccess = true;

            if (payload.args?.isProfile) {
                break;
            }

            if (payload.args?.isImagePreview) {
                const commentIndex = state.selectedImagePreviewPost?.commentsList?.findIndex(
                    item => item?.id === payload.args?.commentId
                );
                state.selectedImagePreviewPost?.commentsList.splice(commentIndex, 1);
                state.selectedImagePreviewPost.comments -= 1;
                break;
            }

            const postIndex = state.userFeed.posts?.findIndex(item => item?.id === payload?.args?.postId);
            const commentIndex = state.userFeed.posts[postIndex]?.commentsList?.findIndex(
                item => item?.id === payload.args?.commentId
            );
            state.userFeed.posts[postIndex]?.commentsList.splice(commentIndex, 1);
            state.userFeed.posts[postIndex].comments -= 1;
            break;
        }
        case actions.DELETE_COMMENTS.FAILED: {
            state.deleteCommentLoading = false;
            state.deleteCommentError = true;
            state.deleteCommentSuccess = false;
            break;
        }
        case actions.DELETE_REPLY_COMMENTS.REQUESTED: {
            if (payload.args.level === 1) {
                state.replyLevel1.deleteReplyCommentLoading = false;
            }
            if (payload.args.level === 2) {
                state.replyLevel2.deleteReplyCommentLoading = false;
            }
            if (payload.args.level === 3) {
                state.replyLevel3.deleteReplyCommentLoading = false;
            }
            break;
        }
        case actions.DELETE_REPLY_COMMENTS.SUCCEEDED: {
            if (payload.args.level === 1) {
                state.replyLevel1.deleteReplyCommentLoading = true;
                if (payload.args?.isProfile) break;
                const postIndex = state.userFeed.posts?.findIndex(item => item?.id === payload?.args?.postId);
                const parentCommentIndex = state.userFeed.posts[postIndex]?.commentsList?.findIndex(
                    item => item?.id === payload.args?.parentCommentId
                );
                const commentIndex = state.userFeed.posts[postIndex]?.commentsList[
                    parentCommentIndex
                ]?.commentsReplyList.findIndex(item => item?.id === payload.args?.commentId);
                state.userFeed.posts[postIndex]?.commentsList[parentCommentIndex].commentsReplyList.splice(
                    commentIndex,
                    1
                );
                break;
            }
            if (payload.args.level === 2) {
                state.replyLevel2.deleteReplyCommentLoading = true;
                if (payload.args?.isProfile) break;
                const postIndex = state.userFeed.posts?.findIndex(item => item?.id === payload?.args?.postId);
                const parentCommentIndex = state.userFeed.posts[postIndex]?.commentsList?.findIndex(
                    item => item?.id === payload.args?.parentCommentId
                );
                const level1CommentIndex = state.userFeed.posts[postIndex]?.commentsList[
                    parentCommentIndex
                ]?.commentsReplyList.findIndex(item => item?.id === payload.args?.level1CommentId);
                const commentIndex = state.userFeed.posts[postIndex]?.commentsList[
                    parentCommentIndex
                ]?.commentsReplyList[level1CommentIndex].commentsReplyList.findIndex(
                    item => item?.id === payload.args?.commentId
                );
                state.userFeed.posts[postIndex]?.commentsList[parentCommentIndex].commentsReplyList[
                    level1CommentIndex
                ].commentsReplyList.splice(commentIndex, 1);
                break;
            }
            if (payload.args.level === 3) {
                state.replyLevel3.deleteReplyCommentLoading = true;
                if (payload.args?.isProfile) break;
                const postIndex = state.userFeed.posts?.findIndex(item => item?.id === payload?.args?.postId);
                const parentCommentIndex = state.userFeed.posts[postIndex]?.commentsList?.findIndex(
                    item => item?.id === payload.args?.parentCommentId
                );
                const level1CommentIndex = state.userFeed.posts[postIndex]?.commentsList[
                    parentCommentIndex
                ]?.commentsReplyList.findIndex(item => item?.id === payload.args?.level1CommentId);
                const level2CommentIndex = state.userFeed.posts[postIndex]?.commentsList[
                    parentCommentIndex
                ]?.commentsReplyList[level1CommentIndex].commentsReplyList.findIndex(
                    item => item?.id === payload.args?.level2CommentId
                );
                const commentIndex = state.userFeed.posts[postIndex]?.commentsList[
                    parentCommentIndex
                ]?.commentsReplyList[level1CommentIndex].commentsReplyList[
                    level2CommentIndex
                ].commentsReplyList.findIndex(item => item?.id === payload.args?.commentId);
                state.userFeed.posts[postIndex]?.commentsList[parentCommentIndex].commentsReplyList[
                    level1CommentIndex
                ].commentsReplyList[level2CommentIndex].commentsReplyList.splice(commentIndex, 1);
                break;
            }
            break;
        }
        case actions.DELETE_REPLY_COMMENTS.FAILED: {
            if (payload.args.level === 1) {
                state.replyLevel1.deleteReplyCommentLoading = false;
            }
            if (payload.args.level === 2) {
                state.replyLevel2.deleteReplyCommentLoading = false;
            }
            if (payload.args.level === 3) {
                state.replyLevel3.deleteReplyCommentLoading = false;
            }
            break;
        }
        case actions.UPLOAD_COMMENT_PHOTO_UPDATE.REQUESTED: {
            state.editCommentLoading = true;
            state.commentImageUploadSuccessUpdate = false;
            break;
        }
        case actions.UPLOAD_COMMENT_PHOTO_UPDATE.SUCCEEDED: {
            state.commentImageUploadSuccessUpdate = true;
            break;
        }
        case actions.UPLOAD_REPLY_COMMENT_PHOTO_UPDATE.REQUESTED: {
            if (payload.args.level === 1) {
                state.replyLevel1.editReplyCommentLoading = true;
                state.replyLevel1.replyCommentImageUploadSuccessUpdate = false;
            }
            if (payload.args.level === 2) {
                state.replyLevel2.editReplyCommentLoading = true;
                state.replyLevel2.replyCommentImageUploadSuccessUpdate = false;
            }
            if (payload.args.level === 3) {
                state.replyLevel3.editReplyCommentLoading = true;
                state.replyLevel3.replyCommentImageUploadSuccessUpdate = false;
            }
            break;
        }
        case actions.UPLOAD_REPLY_COMMENT_PHOTO_UPDATE.SUCCEEDED: {
            if (payload.args.level === 1) {
                state.replyLevel1.replyCommentImageUploadSuccessUpdate = true;
                if (payload.args.isProfile) break;
                state.replyLevel1.editReplyCommentImageFile = data.fileId;
            }
            if (payload.args.level === 2) {
                state.replyLevel2.replyCommentImageUploadSuccessUpdate = true;
                if (payload.args.isProfile) break;
                state.replyLevel2.editReplyCommentImageFile = data.fileId;
            }
            if (payload.args.level === 3) {
                state.replyLevel3.replyCommentImageUploadSuccessUpdate = true;
                if (payload.args.isProfile) break;
                state.replyLevel3.editReplyCommentImageFile = data.fileId;
            }
            break;
        }
        case actions.DELETE_COMMENT_PHOTO.REQUESTED: {
            state.deleteCommentPhotoSuccess = false;
            state.deleteCommentPhotoLoading = true;
            state.postCreationLoadingUpdate = true;
            break;
        }
        case actions.DELETE_COMMENT_PHOTO.SUCCEEDED: {
            state.deleteCommentPhotoSuccess = true;
            state.deleteCommentPhotoLoading = false;
            break;
        }
        case actions.DELETE_REPLY_COMMENT_PHOTO.REQUESTED: {
            if (payload.args.level === 1) {
                state.replyLevel1.deleteReplyCommentPhotoSuccess = false;
            }
            if (payload.args.level === 2) {
                state.replyLevel2.deleteReplyCommentPhotoSuccess = false;
            }
            if (payload.args.level === 3) {
                state.replyLevel3.deleteReplyCommentPhotoSuccess = false;
            }
            break;
        }
        case actions.DELETE_REPLY_COMMENT_PHOTO.SUCCEEDED: {
            if (payload.args.level === 1) {
                state.replyLevel1.deleteReplyCommentPhotoSuccess = true;
                if (payload.args.isProfile) break;
                const postIndex = state.userFeed.posts?.findIndex(item => item?.id === payload?.args?.postId);
                const parentCommentIndex = state.userFeed.posts[postIndex]?.commentsList?.findIndex(
                    item => item?.id === payload.args?.parentCommentId
                );
                const commentIndex = state.userFeed.posts[postIndex]?.commentsList[
                    parentCommentIndex
                ]?.commentsReplyList.findIndex(item => item?.id === payload.args?.commentId);
                state.userFeed.posts[postIndex].commentsList[parentCommentIndex].commentsReplyList[
                    commentIndex
                ].photo = [];
            }
            if (payload.args.level === 2) {
                state.replyLevel2.deleteReplyCommentPhotoSuccess = true;
                if (payload.args.isProfile) break;

                const postIndex = state.userFeed.posts?.findIndex(item => item?.id === payload?.args?.postId);
                const parentCommentIndex = state.userFeed.posts[postIndex]?.commentsList?.findIndex(
                    item => item?.id === payload.args?.parentCommentId
                );
                const level1CommentIndex = state.userFeed.posts[postIndex]?.commentsList[
                    parentCommentIndex
                ]?.commentsReplyList.findIndex(item => item?.id === payload.args?.level1CommentId);
                const commentIndex = state.userFeed.posts[postIndex]?.commentsList[
                    parentCommentIndex
                ]?.commentsReplyList[level1CommentIndex].commentsReplyList.findIndex(
                    item => item?.id === payload.args?.commentId
                );
                state.userFeed.posts[postIndex].commentsList[parentCommentIndex].commentsReplyList[
                    level1CommentIndex
                ].commentsReplyList[commentIndex].photo = [];
                break;
            }
            if (payload.args.level === 3) {
                state.replyLevel3.deleteReplyCommentPhotoSuccess = true;
                if (payload.args.isProfile) break;

                const postIndex = state.userFeed.posts?.findIndex(item => item?.id === payload?.args?.postId);
                const parentCommentIndex = state.userFeed.posts[postIndex]?.commentsList?.findIndex(
                    item => item?.id === payload.args?.parentCommentId
                );
                const level1CommentIndex = state.userFeed.posts[postIndex]?.commentsList[
                    parentCommentIndex
                ]?.commentsReplyList.findIndex(item => item?.id === payload.args?.level1CommentId);
                const level2CommentIndex = state.userFeed.posts[postIndex]?.commentsList[
                    parentCommentIndex
                ]?.commentsReplyList[level1CommentIndex].commentsReplyList.findIndex(
                    item => item?.id === payload.args?.level2CommentId
                );
                const commentIndex = state.userFeed.posts[postIndex]?.commentsList[
                    parentCommentIndex
                ]?.commentsReplyList[level1CommentIndex].commentsReplyList[
                    level2CommentIndex
                ].commentsReplyList.findIndex(item => item?.id === payload.args?.commentId);
                state.userFeed.posts[postIndex].commentsList[parentCommentIndex].commentsReplyList[
                    level1CommentIndex
                ].commentsReplyList[level2CommentIndex].commentsReplyList[commentIndex].photo = [];
                break;
            }
            break;
        }

        case actions.ADD_NEW_COMMENTS.REQUESTED: {
            state.newCommentLoading = true;
            state.newCommentError = false;
            state.newCommentSuccess = false;
            state.newCommentData = {};
            break;
        }
        case actions.ADD_NEW_COMMENTS.SUCCEEDED: {
            state.newCommentLoading = false;
            state.newCommentError = false;
            state.newCommentSuccess = true;

            const postIndex = state.userFeed.posts?.findIndex(item => item?.id === payload?.args.postId);
            state.newCommentData = state.userFeed.posts[postIndex];
            state.newCommentDataToUploadImage = payload?.data;

            if (payload?.args?.isProfile) {
                if (postIndex >= 0) {
                    state.userFeed.posts[postIndex].commentsList = [];
                    state.userFeed.posts[postIndex].comments += 1;
                }
                break;
            }

            if (payload?.args.isImagePreview) {
                state.selectedImagePreviewPost.commentsList = [];
            }

            state.userFeed.posts[postIndex].commentsList = [];
            state.userFeed.posts[postIndex].comments += 1;
            break;
        }
        case actions.ADD_NEW_COMMENTS.FAILED: {
            state.newCommentLoading = false;
            state.newCommentError = true;
            state.newCommentSuccess = false;
            state.newReplyPostData = {};
            break;
        }
        case actions.REPLY_TO_COMMENTS.REQUESTED: {
            if (payload.args.level === 1) {
                state.replyLevel1.newReplyCommentLoading = true;
                state.replyLevel1.newReplyCommentError = false;
                state.replyLevel1.newReplyCommentSuccess = false;
                state.replyLevel1.newReplyPostData = {};
                break;
            }
            if (payload.args.level === 2) {
                state.replyLevel2.newReplyCommentLoading = true;
                state.replyLevel2.newReplyCommentError = false;
                state.replyLevel2.newReplyCommentSuccess = false;
                state.replyLevel2.newReplyPostData = {};
                break;
            }
            if (payload.args.level === 3) {
                state.replyLevel3.newReplyCommentLoading = true;
                state.replyLevel3.newReplyCommentError = false;
                state.replyLevel3.newReplyCommentSuccess = false;
                state.replyLevel3.newReplyPostData = {};
                break;
            }
            break;
        }
        case actions.REPLY_TO_COMMENTS.SUCCEEDED: {
            if (payload.args.level === 1) {
                state.replyLevel1.newReplyCommentLoading = false;
                state.replyLevel1.newReplyCommentError = false;
                state.replyLevel1.newReplyCommentSuccess = true;

                if (payload.args.isProfile) break;

                state.replyLevel1.newReplyCommentDataToUploadImage = payload?.data;
                const postIndex = state.userFeed.posts?.findIndex(item => item?.id === payload?.args.postId);
                const commentIndex = state.userFeed.posts[postIndex]?.commentsList?.findIndex(
                    item => item?.id === payload.args?.commentId
                );
                if (payload?.args?.isImageUpload) return;
                state.userFeed.posts[postIndex].commentsList[commentIndex].commentsReplyList.push(payload?.data);
                break;
            }
            if (payload.args.level === 2) {
                state.replyLevel2.newReplyCommentLoading = false;
                state.replyLevel2.newReplyCommentError = false;
                state.replyLevel2.newReplyCommentSuccess = true;

                if (payload.args.isProfile) break;

                state.replyLevel2.newReplyCommentDataToUploadImage = payload?.data;

                const postIndex = state.userFeed.posts?.findIndex(item => item?.id === payload?.args?.postId);
                const parentCommentIndex = state.userFeed.posts[postIndex]?.commentsList?.findIndex(
                    item => item?.id === payload.args.parentCommentId
                );
                const commentIndex = state.userFeed.posts[postIndex]?.commentsList[
                    parentCommentIndex
                ]?.commentsReplyList?.findIndex(item => item?.id === payload.args?.commentId);

                if (payload?.args?.isImageUpload) return;
                state.userFeed.posts[postIndex].commentsList[parentCommentIndex].commentsReplyList[
                    commentIndex
                ].commentsReplyList.push(payload?.data);
                break;
            }
            if (payload.args.level === 3 || payload.args.level === 4) {
                state.replyLevel3.newReplyCommentLoading = false;
                state.replyLevel3.newReplyCommentError = false;
                state.replyLevel3.newReplyCommentSuccess = true;

                if (payload.args.isProfile) break;

                state.replyLevel3.newReplyCommentDataToUploadImage = payload?.data;
                const postIndex = state.userFeed.posts?.findIndex(item => item?.id === payload?.args?.postId);
                const parentCommentIndex = state.userFeed.posts[postIndex]?.commentsList?.findIndex(
                    item => item?.id === payload.args.parentCommentId
                );
                const level1CommentIndex = state.userFeed.posts[postIndex]?.commentsList[
                    parentCommentIndex
                ]?.commentsReplyList?.findIndex(item => item?.id === payload.args?.level1CommentId);
                const commentIndex = state.userFeed.posts[postIndex]?.commentsList[
                    parentCommentIndex
                ]?.commentsReplyList[level1CommentIndex]?.commentsReplyList.findIndex(
                    item => item?.id === payload.args?.commentId
                );
                if (payload?.args?.isImageUpload) break;
                state.userFeed.posts[postIndex].commentsList[parentCommentIndex].commentsReplyList[
                    level1CommentIndex
                ].commentsReplyList[commentIndex].commentsReplyList.push(payload?.data);
                break;
            }
            break;
        }
        case actions.REPLY_TO_COMMENTS.FAILED: {
            state.replyLevel1.newReplyCommentLoading = false;
            state.replyLevel1.newReplyCommentError = true;
            state.replyLevel1.newReplyCommentSuccess = false;
            state.replyLevel1.newReplyCommentData = {};
            break;
        }
        case actions.DELETE_POST_PHOTO.REQUESTED: {
            state.deletePhotoSuccess = false;
            state.deletePhotoLoading = true;
            state.postCreationLoadingUpdate = true;
            break;
        }
        case actions.DELETE_POST_PHOTO.SUCCEEDED: {
            state.deletePhotoSuccess = true;
            state.deletePhotoLoading = false;
            break;
        }
        case actions.PLAY_VIDEO: {
            state.userFeed.posts = updatePost(state.userFeed.posts, {
                id: payload.postId,
                playVideo: true,
            });
            break;
        }
        case actions.UPLOAD_COMMENT_PHOTO.REQUESTED: {
            state.newCommentLoading = true;
            state.commentImageUploadSuccess = false;
            break;
        }
        case actions.UPLOAD_COMMENT_PHOTO.SUCCEEDED: {
            state.commentImageUploadSuccess = true;
            break;
        }
        case actions.UPLOAD_REPLY_COMMENT_PHOTO.REQUESTED: {
            if (payload.args.level === 1) {
                state.replyLevel1.newReplyCommentLoading = true;
                state.replyLevel1.replyCommentImageUploadSuccess = false;
            }
            if (payload.args.level === 2) {
                state.replyLevel1.newReplyCommentLoading = true;
                state.replyLevel1.replyCommentImageUploadSuccess = false;
            }
            if (payload.args.level === 3) {
                state.replyLevel1.newReplyCommentLoading = true;
                state.replyLevel1.replyCommentImageUploadSuccess = false;
            }

            break;
        }
        case actions.UPLOAD_REPLY_COMMENT_PHOTO.SUCCEEDED: {
            if (payload.args.level === 1) {
                state.replyLevel1.replyCommentImageUploadSuccess = true;
                if (payload.args.isProfile) break;
                const postIndex = state.userFeed.posts?.findIndex(item => item?.id === payload?.args.postId);
                const commentIndex = state.userFeed.posts[postIndex]?.commentsList?.findIndex(
                    item => item?.id === payload.args?.commentId
                );
                if (state.replyLevel1.newReplyCommentDataToUploadImage) {
                    state.replyLevel1.newReplyCommentDataToUploadImage.photo = [{ key: payload?.data?.fileId }];
                    state.userFeed.posts[postIndex].commentsList[commentIndex].commentsReplyList.push(
                        state.replyLevel1.newReplyCommentDataToUploadImage
                    );
                }
                break;
            }
            if (payload.args.level === 2) {
                state.replyLevel2.replyCommentImageUploadSuccess = true;
                if (payload.args.isProfile) break;
                const postIndex = state.userFeed.posts?.findIndex(item => item?.id === payload?.args?.postId);
                const parentCommentIndex = state.userFeed.posts[postIndex]?.commentsList?.findIndex(
                    item => item?.id === payload.args.parentCommentId
                );
                const commentIndex = state.userFeed.posts[postIndex]?.commentsList[
                    parentCommentIndex
                ]?.commentsReplyList?.findIndex(item => item?.id === payload.args?.commentId);
                if (state.replyLevel2.newReplyCommentDataToUploadImage) {
                    state.replyLevel2.newReplyCommentDataToUploadImage.photo = [{ key: payload?.data?.fileId }];
                    state.userFeed.posts[postIndex].commentsList[parentCommentIndex].commentsReplyList[
                        commentIndex
                    ].commentsReplyList.push(state.replyLevel2.newReplyCommentDataToUploadImage);
                }
                break;
            }
            if (payload.args.level === 3) {
                state.replyLevel3.replyCommentImageUploadSuccess = true;
                if (payload.args.isProfile) break;
                const postIndex = state.userFeed.posts?.findIndex(item => item?.id === payload?.args?.postId);
                const parentCommentIndex = state.userFeed.posts[postIndex]?.commentsList?.findIndex(
                    item => item?.id === payload.args.parentCommentId
                );
                const level1CommentIndex = state.userFeed.posts[postIndex]?.commentsList[
                    parentCommentIndex
                ]?.commentsReplyList?.findIndex(item => item?.id === payload.args?.level1CommentId);
                const commentIndex = state.userFeed.posts[postIndex]?.commentsList[
                    parentCommentIndex
                ]?.commentsReplyList[level1CommentIndex]?.commentsReplyList.findIndex(
                    item => item?.id === payload.args?.commentId
                );
                if (state.replyLevel3.newReplyCommentDataToUploadImage) {
                    state.replyLevel3.newReplyCommentDataToUploadImage.photo = [{ key: payload?.data?.fileId }];
                    state.userFeed.posts[postIndex].commentsList[parentCommentIndex].commentsReplyList[
                        level1CommentIndex
                    ].commentsReplyList[commentIndex].commentsReplyList.push(
                        state.replyLevel3.newReplyCommentDataToUploadImage
                    );
                }
                break;
            }
            break;
        }
        case actions.GET_COMMENTS.REQUESTED: {
            if (payload?.args?.isProfile) {
                break;
            }
            state.userFeed.posts = updatePost(state.userFeed.posts, {
                id: payload.args.postId,
                showComments: true,
                isLoadingComments: true,
                commentsPage: payload.args.page,
            });
            break;
        }
        case actions.GET_COMMENTS.SUCCEEDED: {
            state.newCommentSuccess = false;
            if (payload?.args?.isProfile) {
                break;
            }

            if (payload?.args?.isImagePreview) {
                [state.selectedImagePreviewPost] = updatePost([state.selectedImagePreviewPost], {
                    id: payload.args.postId,
                    commentsList: data.list,
                    isLoadingComments: false,
                });
                break;
            }

            state.userFeed.posts = updatePost(state.userFeed.posts, {
                id: payload.args.postId,
                commentsList: data.list,
                isLoadingComments: false,
            });
            break;
        }
        case actions.GET_COMMENTS.FAILED: {
            if (payload?.args?.isProfile) {
                break;
            }
            state.userFeed.posts = updatePost(state.userFeed.posts, {
                id: payload.args.postId,
                isLoadingComments: false,
            });
            break;
        }
        case actions.GET_REPLY_COMMENTS.REQUESTED: {
            if (payload?.args?.isProfile) {
                break;
            }
            if (payload.args.level === 1) {
                const postIndex = state.userFeed.posts?.findIndex(item => item?.id === payload?.args?.postId);
                const commentList = state.userFeed.posts[postIndex].commentsList;
                state.userFeed.posts[postIndex].commentsList = updateComment(commentList, {
                    id: payload.args.commentId,
                    isLoadingReplyComments: true,
                    commentsReplyPage: payload.args.page,
                    reload: payload.args.reload,
                });
                break;
            }
            if (payload.args.level === 2) {
                const postIndex = state.userFeed.posts?.findIndex(item => item?.id === payload?.args?.postId);
                const parentCommentIndex = state.userFeed.posts[postIndex]?.commentsList?.findIndex(
                    item => item?.id === payload?.args?.parentCommentId
                );
                const commentList = state.userFeed.posts[postIndex].commentsList[parentCommentIndex].commentsReplyList;
                state.userFeed.posts[postIndex].commentsList[parentCommentIndex].commentsReplyList = updateComment(
                    commentList,
                    {
                        id: payload.args.commentId,
                        isLoadingReplyComments: true,
                        commentsReplyPage: payload.args.page,
                        reload: payload.args.reload,
                    }
                );
                break;
            }
            if (payload.args.level === 3) {
                const postIndex = state.userFeed.posts?.findIndex(item => item?.id === payload?.args?.postId);
                const parentCommentIndex = state.userFeed.posts[postIndex]?.commentsList?.findIndex(
                    item => item?.id === payload?.args?.parentCommentId
                );
                const level1CommentIndex = state.userFeed.posts[postIndex]?.commentsList[
                    parentCommentIndex
                ].commentsReplyList?.findIndex(item => item?.id === payload?.args?.level1CommentId);
                const commentList =
                    state.userFeed.posts[postIndex].commentsList[parentCommentIndex].commentsReplyList[
                        level1CommentIndex
                    ].commentsReplyList;
                state.userFeed.posts[postIndex].commentsList[parentCommentIndex].commentsReplyList[
                    level1CommentIndex
                ].commentsReplyList = updateComment(commentList, {
                    id: payload.args.commentId,
                    isLoadingReplyComments: true,
                    commentsReplyPage: payload.args.page,
                    reload: payload.args.reload,
                });
                break;
            }
            break;
        }
        case actions.GET_REPLY_COMMENTS.SUCCEEDED: {
            if (payload?.args?.isProfile) {
                break;
            }

            if (payload.args.level === 1) {
                state.replyLevel1.newReplyCommentSuccess = false;
                if (payload?.args?.isProfile) {
                    break;
                }
                const postIndex = state.userFeed.posts?.findIndex(item => item?.id === payload?.args?.postId);
                const commentList = state.userFeed.posts[postIndex].commentsList;
                const replyCommentList = data.list.reverse();
                state.userFeed.posts[postIndex].commentsList = updateComment(commentList, {
                    id: payload.args.commentId,
                    isLoadingReplyComments: false,
                    commentsReplyList: replyCommentList,
                });
                break;
            }
            if (payload.args.level === 2) {
                state.replyLevel2.newReplyCommentSuccess = false;
                if (payload?.args?.isProfile) {
                    break;
                }
                const postIndex = state.userFeed.posts?.findIndex(item => item?.id === payload?.args?.postId);
                const parentCommentIndex = state.userFeed.posts[postIndex]?.commentsList?.findIndex(
                    item => item?.id === payload?.args?.parentCommentId
                );
                const commentList = state.userFeed.posts[postIndex].commentsList[parentCommentIndex].commentsReplyList;
                const replyCommentList = data.list.reverse();
                state.userFeed.posts[postIndex].commentsList[parentCommentIndex].commentsReplyList = updateComment(
                    commentList,
                    {
                        id: payload.args.commentId,
                        isLoadingReplyComments: false,
                        commentsReplyList: replyCommentList,
                    }
                );
                break;
            }
            if (payload.args.level === 3) {
                state.replyLevel3.newReplyCommentSuccess = false;
                if (payload?.args?.isProfile) {
                    break;
                }
                const postIndex = state.userFeed.posts?.findIndex(item => item?.id === payload?.args?.postId);
                const parentCommentIndex = state.userFeed.posts[postIndex]?.commentsList?.findIndex(
                    item => item?.id === payload?.args?.parentCommentId
                );
                const level1CommentIndex = state.userFeed.posts[postIndex]?.commentsList[
                    parentCommentIndex
                ].commentsReplyList?.findIndex(item => item?.id === payload?.args?.level1CommentId);
                const commentList =
                    state.userFeed.posts[postIndex].commentsList[parentCommentIndex].commentsReplyList[
                        level1CommentIndex
                    ].commentsReplyList;
                const replyCommentList = data?.list.reverse();
                state.userFeed.posts[postIndex].commentsList[parentCommentIndex].commentsReplyList[
                    level1CommentIndex
                ].commentsReplyList = updateComment(commentList, {
                    id: payload.args.commentId,
                    isLoadingReplyComments: false,
                    commentsReplyList: replyCommentList,
                });
                break;
            }
            break;
        }
        case actions.GET_REPLY_COMMENTS.FAILED: {
            if (payload?.args?.isProfile) {
                break;
            }
            const postIndex = state.userFeed.posts?.findIndex(item => item?.id === payload?.args?.postId);
            const commentList = state.userFeed.posts[postIndex].commentsList;
            state.userFeed.posts[postIndex].commentsList = updateComment(commentList, {
                id: payload.args.commentId,
                isLoadingReplyComments: false,
            });
            break;
        }
        case actions.HIDE_COMMENTS: {
            if (payload?.isProfile) {
                break;
            }

            if (payload?.isImagePreview) {
                state.selectedImagePreviewPost.commentsList = [];
                state.selectedImagePreviewPost.showComments = false;
                break;
            }

            const clearComments = true;
            state.userFeed.posts = updatePost(
                state.userFeed.posts,
                {
                    id: payload.postId,
                    showComments: false,
                },
                clearComments
            );
            break;
        }
        case actions.UPLOAD_POST_PHOTO.REQUESTED: {
            state.postCreationLoading = true;
            state.postImageUploadSuccess = false;
            break;
        }
        case actions.UPLOAD_POST_PHOTO.SUCCEEDED: {
            state.postImageUploadSuccess = true;
            break;
        }
        case actions.UPLOAD_POST_PHOTO_UPDATE.REQUESTED: {
            state.postCreationLoadingUpdate = true;
            state.postImageUploadSuccessUpdate = false;
            break;
        }
        case actions.UPLOAD_POST_PHOTO_UPDATE.SUCCEEDED: {
            state.postImageUploadSuccessUpdate = true;
            break;
        }
        case actions.GET_GIFS.REQUESTED: {
            state.gifs = [];
            state.gifsLoading = true;
            break;
        }
        case actions.GET_GIFS.SUCCEEDED: {
            state.gifs = data?.list;
            state.gifsLoading = false;
            break;
        }
        case actions.REMOVE_PREVIEW: {
            state.previewLink = {};
            state.previewLoading = false;
            state.previewError = false;
            break;
        }
        case actions.REMOVE_PREVIEW_UPDATE: {
            state.previewLinkUpdate = {};
            state.previewLoadingUpdate = false;
            state.previewErrorUpdate = false;
            break;
        }
        case actions.GET_PREVIEW_OF_LINK.REQUESTED: {
            state.previewLink = {};
            state.previewLoading = true;
            state.previewError = false;
            break;
        }
        case actions.GET_PREVIEW_OF_LINK.SUCCEEDED: {
            state.previewLink = data;
            state.previewLoading = false;
            state.previewError = false;
            break;
        }
        case actions.GET_PREVIEW_OF_LINK.FAILED: {
            state.previewLink = {};
            state.previewLoading = false;
            state.previewError = true;
            break;
        }
        case actions.GET_PREVIEW_OF_LINK_COMMENT.REQUESTED: {
            state.previewLoadingComment = true;
            state.previewErrorComment = false;
            break;
        }
        case actions.GET_PREVIEW_OF_LINK_COMMENT.SUCCEEDED: {
            state.previewLinkComment = data;
            state.previewLoadingComment = false;
            state.previewErrorComment = false;
            break;
        }
        case actions.GET_PREVIEW_OF_LINK_COMMENT.FAILED: {
            state.previewLoadingComment = false;
            state.previewErrorComment = true;
            break;
        }
        case actions.GET_PREVIEW_OF_LINK_REPLY_COMMENT.REQUESTED: {
            if (payload.args.level === 1) {
                state.replyLevel1.previewLoadingReplyComment = true;
                state.replyLevel1.previewErrorReplyComment = false;
                break;
            }
            if (payload.args.level === 2) {
                state.replyLevel2.previewLoadingReplyComment = true;
                state.replyLevel2.previewErrorReplyComment = false;
                break;
            }
            if (payload.args.level === 3) {
                state.replyLevel3.previewLoadingReplyComment = true;
                state.replyLevel3.previewErrorReplyComment = false;
                break;
            }

            break;
        }
        case actions.GET_PREVIEW_OF_LINK_REPLY_COMMENT.SUCCEEDED: {
            if (payload.args.level === 1) {
                state.replyLevel1.previewLinkReplyComment = data;
                state.replyLevel1.previewLoadingReplyComment = false;
                state.replyLevel1.previewErrorReplyComment = false;
                break;
            }
            if (payload.args.level === 2) {
                state.replyLevel2.previewLinkReplyComment = data;
                state.replyLevel2.previewLoadingReplyComment = false;
                state.replyLevel2.previewErrorReplyComment = false;
                break;
            }
            if (payload.args.level === 3) {
                state.replyLevel3.previewLinkReplyComment = data;
                state.replyLevel3.previewLoadingReplyComment = false;
                state.replyLevel3.previewErrorReplyComment = false;
                break;
            }
            break;
        }
        case actions.GET_PREVIEW_OF_LINK_REPLY_COMMENT.FAILED: {
            if (payload.args.level === 1) {
                state.replyLevel1.previewLoadingReplyComment = false;
                state.replyLevel1.previewErrorReplyComment = true;
                break;
            }
            if (payload.args.level === 2) {
                state.replyLevel2.previewLoadingReplyComment = false;
                state.replyLevel2.previewErrorReplyComment = true;
                break;
            }
            if (payload.args.level === 3) {
                state.replyLevel3.previewLoadingReplyComment = false;
                state.replyLevel3.previewErrorReplyComment = true;
                break;
            }
            break;
        }
        case actions.REMOVE_PREVIEW_COMMNETS: {
            state.previewLinkComment = {};
            state.previewLoadingComment = false;
            state.previewErrorComment = false;
            state.replyLevel1.previewLoadingReplyComment = false;
            state.replyLevel1.previewErrorReplyComment = false;
            state.replyLevel1.previewLinkReplyComment = {};
            state.replyLevel2.previewLoadingReplyComment = false;
            state.replyLevel2.previewErrorReplyComment = false;
            state.replyLevel2.previewLinkReplyComment = {};
            state.replyLevel3.previewLoadingReplyComment = false;
            state.replyLevel3.previewErrorReplyComment = false;
            state.replyLevel3.previewLinkReplyComment = {};
            break;
        }
        case actions.GET_PREVIEW_OF_LINK_UPDATE.REQUESTED: {
            state.previewLinkUpdate = {};
            state.previewLoadingUpdate = true;
            state.previewErrorUpdate = false;
            break;
        }
        case actions.GET_PREVIEW_OF_LINK_UPDATE.SUCCEEDED: {
            state.previewLinkUpdate = data;
            state.previewLoadingUpdate = false;
            state.previewErrorUpdate = false;
            break;
        }
        case actions.GET_PREVIEW_OF_LINK_UPDATE.FAILED: {
            state.previewLinkUpdate = {};
            state.previewLoadingUpdate = false;
            state.previewErrorUpdate = true;
            break;
        }
        case actions.GET_USER_LIST.SUCCEEDED: {
            state.userList = data?.list;
            break;
        }
        case actions.GET_USER_POST.REQUESTED: {
            state.selectedImagePreviewPostLoading = true;
            state.imagePreviewError = false;
            state.userFeed.posts = [];
            state.userFeed.page = 1;
            break;
        }
        case actions.GET_USER_POST.SUCCEEDED: {
            state.userFeed.posts = [];
            if (data) {
                state.userFeed.posts = [data];

                state.selectedImagePreviewPostLoading = false;
                state.imagePreviewError = false;
                state.userFeed.hasMore = false;
                state.userFeed.error = undefined;

                if (data?.photo[0]) {
                    state.onImagePreviewModal = true;
                }
            } else {
                state.selectedImagePreviewPost = {};
                state.onImagePreviewModal = false;
                state.selectedImagePreviewPostLoading = false;
                state.imagePreviewError = true;
            }

            break;
        }
        case actions.GET_USER_POST.FAILED: {
            state.selectedImagePreviewPost = {};
            state.onImagePreviewModal = false;
            state.selectedImagePreviewPostLoading = false;
            state.imagePreviewError = true;
            break;
        }
        case actions.CLEAN_POSTS_CREATION: {
            state.postCreationLoading = false;
            state.postCreatedError = '';
            state.postCreatedSuccess = false;
            state.previewLink = {};
            state.postCreatedSuccessData = {};
            state.previewLoading = false;
            state.previewError = false;
            state.postImageUploadSuccess = false;
            state.postCreationLoadingUpdate = false;
            state.postCreatedErrorUpdate = '';
            state.postCreatedSuccessUpdate = false;
            state.previewLinkUpdate = {};
            state.postCreatedSuccessDataUpdate = {};
            state.previewLoadingUpdate = false;
            state.previewErrorUpdate = false;
            state.postImageUploadSuccessUpdate = false;
            state.deletePhotoSuccess = false;
            state.deletePhotoLoading = false;
            state.newCommentLoading = false;
            state.newCommentSuccess = false;
            state.newCommentError = false;
            break;
        }
        case actions.CLEAN_COMMENT_CREATION: {
            state.newCommentLoading = false;
            state.newCommentSuccess = false;
            state.newCommentError = false;
            state.newCommentData = {};
            state.newCommentDataToUploadImage = {};
            state.deleteCommentLoading = false;
            state.deleteCommentSuccess = false;
            state.deleteCommentError = false;
            state.editCommentLoading = false;
            state.editCommentSuccess = false;
            state.editCommentError = false;
            state.commentImageUploadSuccess = false;
            state.commentImageUploadSuccessUpdate = false;
            state.deleteCommentPhotoSuccess = false;
            state.deleteCommentPhotoLoading = false;
            break;
        }
        case actions.CLEAN_REPLY_COMMENT_CREATION: {
            // level1
            state.replyLevel1.newReplyCommentLoading = false;
            state.replyLevel1.newReplyCommentSuccess = false;
            state.replyLevel1.newCommentError = false;
            state.replyLevel1.newReplyPostData = {};
            state.replyLevel1.newReplyCommentDataToUploadImage = {};
            state.replyLevel1.replyCommentImageUploadSuccess = false;
            state.replyLevel1.commentImageUploadSuccessUpdate = false;
            state.replyLevel1.deleteReplyCommentPhotoSuccess = false;
            state.replyLevel1.editReplyCommentImageFile = '';

            // level2
            state.replyLevel2.newReplyCommentLoading = false;
            state.replyLevel2.newReplyCommentSuccess = false;
            state.replyLevel2.newCommentError = false;
            state.replyLevel2.newReplyPostData = {};
            state.replyLevel2.newReplyCommentDataToUploadImage = {};
            state.replyLevel2.replyCommentImageUploadSuccess = false;
            state.replyLevel2.commentImageUploadSuccessUpdate = false;
            state.replyLevel2.deleteReplyCommentPhotoSuccess = false;
            state.replyLevel3.editReplyCommentImageFile = '';
            // level3
            state.replyLevel3.newReplyCommentLoading = false;
            state.replyLevel3.newReplyCommentSuccess = false;
            state.replyLevel3.newCommentError = false;
            state.replyLevel3.newReplyPostData = {};
            state.replyLevel3.newReplyCommentDataToUploadImage = {};
            state.replyLevel3.replyCommentImageUploadSuccess = false;
            state.replyLevel3.commentImageUploadSuccessUpdate = false;
            state.replyLevel3.deleteReplyCommentPhotoSuccess = false;
            state.replyLevel3.editReplyCommentImageFile = '';

            break;
        }
        case actions.CLEAN_POSTS_EDIT: {
            state.postCreationLoadingUpdate = false;
            state.postCreatedErrorUpdate = '';
            state.postCreatedSuccessUpdate = false;
            state.previewLinkUpdate = {};
            state.postCreatedSuccessDataUpdate = {};
            state.previewLoadingUpdate = false;
            state.previewErrorUpdate = false;
            state.postImageUploadSuccessUpdate = false;
            state.deletePhotoSuccess = false;
            state.deletePhotoLoading = false;
            break;
        }
        case actions.CREATE_POST.REQUESTED: {
            state.postCreationLoading = true;
            state.postCreatedError = '';
            state.postCreatedSuccess = false;
            break;
        }
        case actions.CREATE_POST.SUCCEEDED: {
            state.postCreationLoading = false;
            state.postCreatedError = '';
            state.postCreatedSuccess = true;
            state.postCreatedSuccessData = data;
            break;
        }
        case actions.CREATE_POST.FAILED: {
            state.postCreationLoading = false;
            state.postCreatedError = getErrorFrom(payload);
            state.postCreatedSuccess = false;
            break;
        }
        case actions.EDIT_POST.REQUESTED: {
            state.postCreationLoadingUpdate = true;
            state.postCreatedErrorUpdate = '';
            state.postCreatedSuccessUpdate = false;
            state.deletePhotoSuccess = false;
            state.deletePhotoLoading = false;
            break;
        }
        case actions.EDIT_POST.SUCCEEDED: {
            const postIndex = state.userFeed.posts.findIndex(post => post.id === payload.args.entityId);

            if (payload.args?.isProfile) {
                state.postCreationLoadingUpdate = false;
                state.postCreatedErrorUpdate = '';
                state.postCreatedSuccessUpdate = true;
                state.postCreatedSuccessDataUpdate = data;

                if (postIndex >= 0) {
                    state.userFeed.posts.splice(postIndex, 1, data);
                }

                break;
            }

            state.userFeed.posts.splice(postIndex, 1, data);
            state.postCreationLoadingUpdate = false;
            state.postCreatedErrorUpdate = '';
            state.postCreatedSuccessUpdate = true;
            state.postCreatedSuccessDataUpdate = data;
            break;
        }
        case actions.EDIT_POST.FAILED: {
            state.postCreationLoadingUpdate = false;
            state.postCreatedErrorUpdate = getErrorFrom(payload);
            state.postCreatedSuccessUpdate = false;
            break;
        }
        case actions.GET_FEED.SUCCEEDED: {
            if (data.list && data.list.length > 0) {
                /**
                 * Position property is used to calculate if it's time to load more posts.
                 * For usage see FeedPage.jsx function handleShowLastPost.
                 */
                data.list[data.list.length - 1].position = state.userFeed.posts.length;
                state.userFeed.posts = [...state.userFeed.posts, ...data.list];
            } else {
                state.userFeed.hasMore = false;
            }
            state.userFeed.isLoading = false;
            state.userFeed.error = undefined;
            break;
        }
        case actions.START_LOADING: {
            state.userFeed.hasMore = true;
            state.userFeed.isLoading = true;
            state.userFeed.error = undefined;
            state.userFeed.posts = [];
            break;
        }
        case actions.GET_FEED.REQUESTED: {
            if (payload?.args?.reload) {
                state.userFeed.posts = [];
                state.userFeed.hasMore = true;
            }
            state.userFeed.page = payload.args.page;
            state.userFeed.isLoading = true;
            state.userFeed.error = undefined;
            break;
        }
        case actions.GET_FEED.FAILED: {
            state.userFeed.isLoading = false;
            state.userFeed.error = getErrorFrom(payload.error);
            break;
        }
        case actions.CLEAN_POSTS: {
            state.userFeed = initialState.userFeed;
            break;
        }
        case actions.RESET_PAGE: {
            state.userFeed.page = 1;
            break;
        }
        case actions.UPVOTE.SUCCEEDED: {
            if (payload?.args?.isProfile) {
                return;
            }
            const post = getPost(state.userFeed.posts, { id: payload.args.postId });
            if (post.voted === 'dislike') {
                post.votesDown -= 1;
                post.votesUp += 1;
                post.voted = 'like';
            } else if (post.voted === 'like') {
                post.votesUp -= 1;
                post.voted = null;
            } else {
                post.votesUp += 1;
                post.voted = 'like';
            }
            break;
        }
        case actions.UP_VOTE_FOR_IMAGE_PREVIEW.SUCCEEDED: {
            const post = state.selectedImagePreviewPost;
            if (post.voted === 'dislike') {
                post.votesDown -= 1;
                post.votesUp += 1;
                post.voted = 'like';
            } else if (post.voted === 'like') {
                post.votesUp -= 1;
                post.voted = null;
            } else {
                post.votesUp += 1;
                post.voted = 'like';
            }
            break;
        }

        case actions.DOWN_VOTE_FOR_IMAGE_PREVIEW.SUCCEEDED: {
            const post = state.selectedImagePreviewPost;
            if (post.voted === 'dislike') {
                post.votesDown -= 1;
                post.votesUp += 1;
                post.voted = 'like';
            } else if (post.voted === 'like') {
                post.votesUp -= 1;
                post.voted = null;
            } else {
                post.votesUp += 1;
                post.voted = 'like';
            }
            break;
        }
        case actions.DOWNVOTE.SUCCEEDED: {
            if (payload?.args?.isProfile) {
                return;
            }
            const post = getPost(state.userFeed.posts, { id: payload.args.postId });
            if (post.voted === 'like') {
                post.votesUp -= 1;
                post.votesDown += 1;
                post.voted = 'dislike';
            } else if (post.voted === 'dislike') {
                post.votesDown -= 1;
                post.voted = null;
            } else {
                post.votesDown += 1;
                post.voted = 'dislike';
            }
            break;
        }
        case actions.POST_DELETE_ACTION.SUCCEEDED: {
            const postIndex = getPostIndex(state.userFeed.posts, { id: payload.args.postId });

            if (payload.args?.isProfile) {
                if (postIndex >= 0) {
                    const post = getPost(state.userFeed.posts, { id: payload.args.postId });
                    post.deleted = true;
                }
                break;
            }
            const post = getPost(state.userFeed.posts, { id: payload.args.postId });
            post.deleted = true;
            break;
        }
        case actions.SAVE_POST.SUCCEEDED: {
            if (payload.args?.isProfile) {
                break;
            }
            const post = getPost(state.userFeed.posts, { id: payload.args.postId });
            post.bookmarked = !post.bookmarked;
            break;
        }

        case actions.ON_IMAGE_PREVIEW_MODAL: {
            state.onImagePreviewModal = payload;
            break;
        }
        case actions.GET_COMMENTS_FOR_IMAGE_MODAL.REQUESTED: {
            state.selectedImagePreviewPost = {
                ...state.selectedImagePreviewPost,
                showComments: true,
                isLoadingComments: true,
                commentsPage: payload.args.page,
            };
            break;
        }
        case actions.GET_COMMENTS_FOR_IMAGE_MODAL.SUCCEEDED: {
            state.selectedImagePreviewPost = {
                ...state.selectedImagePreviewPost,
                commentsList: data.list,
                isLoadingComments: false,
            };
            break;
        }
        case actions.GET_COMMENTS_FOR_IMAGE_MODAL.FAILED: {
            state.selectedImagePreviewPost = {
                ...state.selectedImagePreviewPost,
                isLoadingComments: false,
            };
            break;
        }
        case actions.REPORT_REASON_LIST.REQUESTED: {
            state.reportReasonList = [];
            state.reportReasonLoading = true;
            break;
        }
        case actions.REPORT_REASON_LIST.SUCCEEDED: {
            state.reportReasonList = data.list;
            state.reportReasonLoading = false;
            break;
        }
        case actions.REPORT_POST.SUCCEEDED: {
            const postIndex = getPostIndex(state.userFeed.posts, { id: payload.args.postId });
            if (postIndex >= 0) {
                const post = getPost(state.userFeed.posts, { id: payload.args.postId });
                post.deleted = true;
            }
            break;
        }
        case actions.CLEAN_IMAGE_PREVIEW: {
            state.selectedImagePreviewPost = {};
            state.onImagePreviewModal = false;
            state.imagePreviewError = false;
            state.selectedImagePreviewPostLoading = true;
            break;
        }
        case actions.PLAY_COMMENT_VIDEO: {
            if (payload?.isProfile) {
                break;
            }
            const postIndex = state.userFeed.posts?.findIndex(item => item?.id === payload?.postId);
            const commentIndex = state.userFeed.posts[postIndex]?.commentsList?.findIndex(
                item => item?.id === payload?.commentId
            );
            const currentComment = state.userFeed.posts[postIndex]?.commentsList[commentIndex];
            currentComment.playVideo = true;
            state.userFeed.posts[postIndex]?.commentsList.splice(commentIndex, currentComment);
            break;
        }

        case authActions.LOGOUT: {
            for (const propertyName in state) {
                if (Object.prototype.hasOwnProperty.call(state, propertyName)) {
                    delete state[propertyName];
                }
            }
            for (const propertyName in initialState) {
                if (Object.prototype.hasOwnProperty.call(initialState, propertyName)) {
                    state[propertyName] = initialState[propertyName];
                }
            }
            break;
        }

        // suggestions
        case actions.GET_GROUP_YOU_MAY_KNOW.REQUESTED: {
            state.suggestions.isLoading = true;
            state.suggestions.hasLoadedGroups = false;
            break;
        }
        case actions.GET_GROUP_YOU_MAY_KNOW.SUCCEEDED: {
            state.suggestions.groups = data.list;
            state.suggestions.isLoading = false;
            state.suggestions.hasLoadedGroups = true;
            break;
        }
        case actions.GET_GROUP_YOU_MAY_KNOW.FAILED: {
            state.suggestions.groups = [];
            state.suggestions.isLoading = false;
            state.suggestions.hasLoadedGroups = true;
            break;
        }

        case actions.GET_PEOPLE_YOU_MAY_KNOW.REQUESTED: {
            state.suggestions.isLoading = true;
            state.suggestions.hasLoadedPeople = true;
            break;
        }
        case actions.GET_PEOPLE_YOU_MAY_KNOW.SUCCEEDED: {
            state.suggestions.people = data.list;
            state.suggestions.isLoading = false;
            state.suggestions.hasLoadedPeople = true;
            break;
        }
        case actions.GET_PEOPLE_YOU_MAY_KNOW.FAILED: {
            state.suggestions.people = [];
            state.suggestions.isLoading = false;
            state.suggestions.hasLoadedPeople = true;
            break;
        }
        case profileActions.CLEAN_PROFILE: {
            state.onImagePreviewModal = false;
            return;
        }
        default:
            return state;
    }
    return state;
});

export default reducer;
