const defaultMessages = require('./lang/en.json');

export const getIntl = () => {
    const defaultLocale = 'en';
    let locale = navigator?.language?.toLowerCase() ?? defaultLocale;
    locale = locale === 'en-us' ? 'en' : locale;
    let messages = defaultMessages;

    try {
        if (locale !== defaultLocale) {
            // eslint-disable-next-line global-require, import/no-dynamic-require
            const messagesForLocale = require(`./lang/${locale}.json`);
            messages = { ...messages, ...messagesForLocale };
        }
    } catch {
        locale = defaultLocale;
    }
    return { messages, locale };
};
