import produce from 'immer';
import * as actions from './statusActions';

const initialState = {
    status: {
        hasLoaded: false,
        isLoading: false
    }
};

export const reducer = produce((state = initialState, { type, payload }) => {
    switch (type) {
        case actions.GET_STATUS.REQUESTED: {
            state.status = { hasLoaded: false, isLoading: true };
            break;
        }
        case actions.GET_STATUS.SUCCEEDED: {
            state.status = { isLoading: false, hasLoaded: true, ...payload.data };

            /**
             * Work around to skip the maintenance: 
             * localStorage.setItem('isMaintanceMode', 'true');
            */
            const storageIsMaintenanceMode = localStorage.getItem('isMaintanceMode');
            if (storageIsMaintenanceMode !== undefined && storageIsMaintenanceMode !== '') {
                state.status.isMaintanceMode = storageIsMaintenanceMode === 'true';
            }
            break;
        }
        default:
            return state;
    }
    return state;
});

export default reducer;
