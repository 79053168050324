import produce from 'immer';
import * as actions from './albumActions';

const initialState = {
    albums: {
        isLoading: true,
        albumList: [],
        count: 0,
        albumSelected: false,
        albumCreateSuccess: false,
        albumId: null,
        albumName: '',
        hasMore: true,
        page: 1,
    },
    photos: {
        isLoading: true,
        photoList: [],
        count: 0,
        photoUploading: false,
        photoUploadSuccess: false,
        hasMore: true,
        page: 1,
    },
};
export const reducer = produce((state = initialState, { type, payload }) => {
    const data = payload?.data;
    switch (type) {
        case actions.CREATE_ALBUM.REQUESTED: {
            state.albums.albumCreateSuccess = false;
            break;
        }
        case actions.CREATE_ALBUM.SUCCEEDED: {
            state.albums.albumCreateSuccess = true;
            break;
        }
        case actions.LIST_ALBUMS.REQUESTED: {
            if (payload?.args?.reload) {
                state.albums.albumList = [];
                state.albums.count = 0;
                state.albums.page = 1;
                state.albums.isLoading = true;
                state.albums.isLoading = true;
                return;
            }

            state.albums.page = payload.args.page;
            state.albums.isLoading = true;
            state.albums.albumSelected = false;
            state.albums.albumId = null;
            state.albums.isLoading = true;
            break;
        }
        case actions.LIST_ALBUMS.SUCCEEDED: {
            if (payload?.args?.reload) {
                state.albums.albumList = [];
                state.albums.count = 0;
            }

            state.albums.count = data.count;

            if (data?.list && data?.list?.length > 0) {
                data.list[data?.list?.length - 1].position = data.list.length;
                let allNewPhotos = [...state.albums.albumList, ...data?.list];
                state.albums.hasMore = true;

                let myArrSerialized = allNewPhotos.map(e => JSON.stringify(e));
                const mySetSerialized = new Set(myArrSerialized);

                const myUniqueArrSerialized = [...mySetSerialized];

                state.albums.albumList = myUniqueArrSerialized.map(e => JSON.parse(e));
            } else {
                state.albums.hasMore = false;
                state.albums.isLoading = false;
            }

            break;
        }
        case actions.DELETE_ALBUM.SUCCEEDED: {
            const albumIndex = state?.albums.albumList?.findIndex(item => item.id === payload.args.albumId);
            state.albums.albumList?.splice(albumIndex, 1);
            state.albums.count -= 1;
            break;
        }
        case actions.EDIT_ALBUM.SUCCEEDED: {
            const albumIndex = state?.albums.albumList?.findIndex(item => item.id === payload.args.albumId);
            state.albums.albumList.splice(albumIndex, 1, data);
            break;
        }
        case actions.SELECT_ALBUM: {
            state.albums.albumSelected = true;
            state.albums.albumId = payload.albumId;
            state.albums.albumName = payload.albumName;
            break;
        }
        case actions.DESELECT_ALBUM: {
            state.albums.albumSelected = false;
            state.albums.albumId = null;
            state.albums.albumName = null;
            state.albums.photoCount = 0;
            state.albums.photoList = [];
            break;
        }
        case actions.LIST_PHOTOS.REQUESTED: {
            if (payload?.args?.reload) {
                state.photos.photoCount = 0;
                state.photos.photoList = [];
                state.photos.page = 1;
                state.photos.isLoading = true;
                state.photos.hasMore = true;
                return;
            }

            state.photos.hasMore = true;
            state.photos.page = payload.args.page;
            state.photos.isLoading = true;
            break;
        }
        case actions.LIST_PHOTOS.SUCCEEDED: {
            if (payload?.args?.reload) {
                state.photos.photoList = [];
            }

            state.photos.photoCount = data.count;

            if (data?.list && data?.list?.length > 0) {
                data.list[data?.list?.length - 1].position = data.list.length;
                let allNewPhotos = [...state.photos.photoList, ...data?.list];
                state.photos.hasMore = true;

                let myArrSerialized = allNewPhotos.map(e => JSON.stringify(e));
                const mySetSerialized = new Set(myArrSerialized);

                const myUniqueArrSerialized = [...mySetSerialized];

                state.photos.photoList = myUniqueArrSerialized.map(e => JSON.parse(e));
            } else {
                state.photos.hasMore = false;
                state.photos.isLoading = false;
            }

            break;
        }
        case actions.UPLOAD_PHOTO.REQUESTED: {
            state.photos.photoUploading = true;
            state.photos.photoUploadSuccess = false;
            break;
        }
        case actions.UPLOAD_PHOTO.FAILED: {
            state.photos.photoUploading = false;
            state.photos.photoUploadSuccess = false;
            break;
        }
        case actions.UPLOAD_PHOTO.SUCCEEDED: {
            state.photos.photoUploading = false;
            state.photos.photoCount += 1;
            state.photos.photoUploadSuccess = true;
            state.photos.photoList = [{ key: data.fileId, createdAt: new Date() }, ...state.photos.photoList];
            break;
        }
        case actions.DELETE_PHOTO.SUCCEEDED: {
            const photoIndex = state?.photos.photoList?.findIndex(item => item.key === payload.args.photoKey);
            state.photos.photoList.splice(photoIndex, 1);
            state.photos.photoCount -= 1;
            break;
        }
        default: {
            return state;
        }
    }
    return state;
});

export default reducer;
