import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty, isNil, isNull } from 'lodash';
import styles from './Input.module.scss';
import ErrorMessage from '../ErrorMessage';
import { Label } from '../index';
import search from '../../../assets/search.svg';
import eye from '../../../assets/eye.svg';
import eyeClosed from '../../../assets/eyeClosed.svg';
import redEye from '../../../assets/redEye.svg';
import redEyeClosed from '../../../assets/redEyeClosed.svg';

const getId = props => {
    if (props.id) return props.id;
    if (props.label) return props.label.replace(/\s/g, '_');
    return `input_${Math.random()}`;
};

const Input = props => {
    const {
        errorMessage,
        showErrors,
        className,
        type,
        onChange,
        value,
        readOnly,
        multiline,
        dailCodeValue,
        updateDailCode,
        forSearch,
        onFocusedSearch,
        autoFocus,
        forMobileView,
        inputClassName,
        labelClassname,
        inputRef = null,
        error,
        maxLength,
    } = props;
    // debugger;
    const id = getId(props);
    const [showPassword, setShowPassword] = useState(false);
    const isValueEmpty = value === undefined || value === '';
    const currentValue = isValueEmpty ? '' : value;

    const Eye = () => {
        if (showPassword) {
            if ((showErrors && errorMessage) || error) {
                return <img src={redEye} alt="Hide Password" />;
            }
            return <img src={eye} alt="Hide Password" />;
        }
        if ((showErrors && errorMessage) || error) {
            return <img src={redEyeClosed} alt="Hide Password" />;
        }
        return <img src={eyeClosed} alt="Show Password" />;
    };

    const dailCode = () => {
        updateDailCode(dailCodeValue);
        const code = `${dailCodeValue} ${currentValue}`;

        if (currentValue?.includes(dailCodeValue)) {
            if (code.split(dailCodeValue)[1].trim()) return `${dailCodeValue}`;
            return currentValue.trim();
        }

        if (!currentValue?.includes(dailCodeValue)) {
            return `${dailCodeValue}`;
        }
        return code;
    };

    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        if (dailCodeValue) {
            setIsFocused(true);
        }
    }, [dailCodeValue]);

    const isFocusedOrNotEmpty = isFocused || !isValueEmpty;

    const labelClasses = classNames(`${styles.label} ${labelClassname}`, {
        [styles.labelFocused]: isFocusedOrNotEmpty,
        [styles.labelFocusedForSearch]: isFocusedOrNotEmpty && forSearch,
        [styles.labelForSearch]: forSearch,
        [styles.errorLabel]: (showErrors && errorMessage) || error,
    });

    const inputClasses = classNames(`${styles.input} ${inputClassName}`, {
        [styles.searchInput]: forSearch,
        [styles.inputFocused]: isFocusedOrNotEmpty,
        [styles.forMobileInput]: forMobileView,
        [styles.inputFocusedForSearch]: isFocusedOrNotEmpty && forSearch,
        [styles.inputPasswordFocused]: type === 'password' && isFocusedOrNotEmpty,
        [styles.inputMultiline]: multiline && isFocusedOrNotEmpty,
        [styles.error]: (showErrors && errorMessage) || error,
    });

    const searchClasses = classNames(styles.searchImage, {
        [styles.searchImageFosued]: isFocusedOrNotEmpty,
    });

    const onFocus = () => {
        setIsFocused(true);
        if (forSearch) onFocusedSearch();
    };

    const onBlur = () => {
        setIsFocused(false);
    };

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className={className}>
            <div className={`${styles.inputWrapper}`}>
                {forSearch && (
                    <img
                        data-testid="search.img"
                        style={{ pointerEvents: 'none' }}
                        src={search}
                        className={searchClasses}
                        alt="Search"
                    />
                )}
                <div data-testid="search.label">
                    <Label className={labelClasses}>{props.label}</Label>
                </div>
                {multiline ? (
                    <textarea
                        data-testid={id}
                        autoComplete="new-password"
                        onFocus={onFocus}
                        onBlur={onBlur}
                        value={currentValue}
                        readOnly={readOnly}
                        className={inputClasses}
                        type={type}
                        onChange={onChange}
                    />
                ) : (
                    <input
                        ref={inputRef}
                        data-testid={id}
                        autoComplete="new-password"
                        onFocus={onFocus}
                        onBlur={onBlur}
                        value={dailCodeValue ? dailCode() : currentValue}
                        readOnly={readOnly}
                        className={inputClasses}
                        type={type === 'password' && showPassword ? 'text' : type}
                        onChange={onChange}
                        autoFocus={autoFocus}
                        maxLength={maxLength}
                    />
                )}
                {type === 'password' && (
                    <span className={styles.showPassword} onClick={handleShowPassword}>
                        {Eye()}
                    </span>
                )}
            </div>
            {showErrors && errorMessage && <ErrorMessage data-testid={'errorMessage.fields'} message={errorMessage} />}
        </div>
    );
};

export default Input;

Input.propTypes = {
    className: PropTypes.string,
    errorMessage: PropTypes.any,
    showErrors: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.string,
    readOnly: PropTypes.any,
    forSearch: PropTypes.bool,
    onFocusedSearch: PropTypes.func,
    autoFocus: PropTypes.func,
    forMobileView: PropTypes.bool,
    multiline: PropTypes.any,
    dailCodeValue: PropTypes.any,
    updateDailCode: PropTypes.any,
    label: PropTypes.string,
    type: PropTypes.any,
    inputClassName: PropTypes.string,
    labelClassname: PropTypes.string,
};
