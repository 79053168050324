const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

const isIos = () => userAgent.match(/iPhone|iPad|iPod/i);

const isSafari = () => {
    const isMobileSafari = !!(navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/));
    const isWebSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
    return isMobileSafari || isWebSafari;
}; 

const isMobile = () => {
    const isAndroid = () => userAgent.match(/Android/i);
    const isOpera = () => userAgent.match(/Opera Mini/i);
    const isWindows = () => userAgent.match(/IEMobile/i);
    return isAndroid() || isIos() || isOpera() || isWindows();
};

const copyToClipboard = textToCopy => {
    const el = document.createElement('textarea');
    el.value = textToCopy;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
};

export { isMobile, copyToClipboard, isIos, isSafari };
