import produce from 'immer';
import * as actions from './coinsActions';
import { getErrorFrom } from '../utils';

const initialState = {
    favoriteCoins: {
        isLoading: false,
        coins: [],
        error: undefined,
    },
};

export const reducer = produce((state = initialState, { type, payload }) => {
    const data = payload?.data;
    
    switch (type) {
        case actions.GET_USER_FAVORITE_COINS.SUCCEEDED: {
            state.favoriteCoins.coins = data;
            state.favoriteCoins.isLoading = false;
            state.favoriteCoins.error = undefined;
            break;
        }
        case actions.GET_USER_FAVORITE_COINS.REQUESTED: {
            state.favoriteCoins.page = payload.args.page;
            state.favoriteCoins.isLoading = true;
            state.favoriteCoins.error = undefined;
            break;
        }
        case actions.GET_USER_FAVORITE_COINS.FAILED: {
            state.favoriteCoins.isLoading = false;
            state.favoriteCoins.error = getErrorFrom(payload.error);
            break;
        }
        default:
            return state;
    }
    return state;
});

export default reducer;
