import axios from 'axios';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

const LOCAL_STORAGE_TOKEN = 'rnd_fa';
const LOCAL_STORAGE_UUID = 'e_iv';

axios.interceptors.request.use((config) => {
    const token = localStorage.getItem(LOCAL_STORAGE_TOKEN) || undefined;
    if (!_.isNil(token)) config.headers['X-Token'] = `Bearer ${token}`;
    if (!localStorage.getItem(LOCAL_STORAGE_UUID)) localStorage.setItem(LOCAL_STORAGE_UUID, uuidv4());

    axios.defaults.baseURL = window.location.origin;
    config.headers['X-App'] = 1;
    return config;
});

axios.interceptors.response.use((response) => {
    const camelizeKeys = obj => {
        if (Array.isArray(obj)) {
            return obj.map(v => camelizeKeys(v));
        } if (obj !== null && obj.constructor === Object) {
            return Object.keys(obj).reduce(
                (result, key) => ({
                    ...result,
                    [_.camelCase(key)]: camelizeKeys(obj[key]),
                }),
                {}
            );
        }
        return obj;
    };

    if (response.data) response.data = camelizeKeys(response.data);
    return response;
});

export default axios;
