import { MODULE_NAME } from './coinsConstants';
import { requestActions } from '../actionsCreator';
import { requestApiCall } from '../connectivity/connectivityActions';
import api from '../../api';

/**
 * Actions constants
 */

export const GET_USER_FAVORITE_COINS = requestActions(MODULE_NAME, 'GET_USER_FAVORITE_COINS');

/**
 * Action creators
 */

export const getFavoriteCoinsByUser = ({ userId }) =>
    requestApiCall(api.names.getFavoriteCoinsByUser, { userId }, GET_USER_FAVORITE_COINS);
