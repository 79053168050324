import { connectivitySaga } from './connectivitySaga';
import { startSaga } from './startSaga';
import { loginSaga } from './auth/loginSaga';
import { authSaga } from './auth/authSaga';
import { socketSaga } from './socket/socketSaga';
import { pendingConfirmationSaga } from './auth/pendingConfirmationSaga';
import { emailConfirmedSaga } from './auth/emailConfirmedSaga';
import { updatePasswordSaga } from './auth/updatePasswordSaga';
import { loadFeedSaga } from './feed/feedSaga';
import { userNotificationSaga, newNotificationSaga } from './notification/notificationSaga';
import { profileSaga } from './profile/profileSaga';
import { followersSaga } from './followers/FollowersSaga';
import { communicationSaga } from './communication/communicationSaga';
import { groupSaga } from './group/GroupSaga';
import { companySaga } from './company/CompanySaga';
import { statusSaga } from './status/statusSaga';

export default {
    connectivitySaga,
    // startSaga,
    // authSaga,
    // loginSaga,
    // pendingConfirmationSaga,
    // emailConfirmedSaga,
    // updatePasswordSaga,
    // loadFeedSaga,
    // userNotificationSaga,
    // newNotificationSaga,
    // profileSaga,
    // followersSaga,
    // communicationSaga,
    // groupSaga,
    // companySaga,
    // statusSaga
};
