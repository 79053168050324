import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ConvertPage } from './containers/Pages/ConvertPage';

import {
    ROOT,
} from './routesMap';

const Routes = () => {
    const RedirectToLogin = () => <Redirect to={ROOT} />;
    return (
        <Switch>
            <Route exact path={ROOT} component={ConvertPage} />
            <Route component={RedirectToLogin} />
        </Switch>
    );
};

export default Routes;
