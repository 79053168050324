/* eslint-disable no-restricted-syntax */
import produce from 'immer';
import { forEach } from 'lodash';
import * as actions from './communicationActions';
import * as userActions from '../user/userActions';
import { getErrorFrom } from '../utils';
import { USER_ONLINE_STATUS } from '../../utils/constants';


const initialState = {
    id: -1,
    onlineStatus: USER_ONLINE_STATUS.Online,
    updateOnlineStatus: {
        isLoading: false,
        error: undefined,
    },
    otherOnlineStatus: {}
};

export const reducer = produce((state = initialState, { type, payload }) => {
    const data = payload?.data;
    switch (type) {
        case userActions.GET_CURRENT_USER.SUCCEEDED: {
            state.id = data.id;
            state.onlineStatus = data.onlineStatus;
            break;
        }
        case actions.UPDATE_ONLINE_STATUS.FAILED: {
            state.updateOnlineStatus.isLoading = false;
            state.updateOnlineStatus.error = getErrorFrom(payload.error);
            break;
        }
        case actions.UPDATE_ONLINE_STATUS.REQUESTED: {
            state.updateOnlineStatus.isLoading = true;
            state.updateOnlineStatus.error = undefined;
            break;
        }
        case actions.UPDATE_ONLINE_STATUS.SUCCEEDED: {
            state.updateOnlineStatus.isLoading = false;
            state.updateOnlineStatus.error = undefined;
            break;
        }
        case actions.FETCH_OTHER_USERS_ONLINE_STATUSES.SUCCEEDED: {
            forEach(data, (object => {
                const user = JSON.parse(object);
                state.otherOnlineStatus[user.id] = user;
            }));
            break;
        }
        case actions.UPDATE_OTHER_USER_ONLINE_STATUS.SUCCEEDED: {
            state.otherOnlineStatus[data.id] = data;
            break;
        }
        default:
            return state;
    }
    return state;
});

export default reducer;
