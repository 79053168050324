/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React from 'react';
import Lottie from 'react-lottie';
import styles from './Button.module.scss';
import loadingIconWhite from '../../../assets/loading_white.json';
import loadingIcon from '../../../assets/loading.json';
import wallet from '../../../assets/wallet.svg';
import settings from '../../../assets/blueSettingsIcon.svg';
import leaveBlue from '../../../assets/leaveBlue.svg';

const icons = { wallet, settings, leaveBlue };

const Button = ({
    icon,
    isLoading,
    className,
    secondary,
    disabled,
    smallLottie,
    redColor,
    onlyIconClass,
    IconClassName,
    ...props
}) => {
    const secondaryClassName = secondary ? styles.secondary : '';
    const lottieOptions = {
        loop: true,
        autoplay: true,
        animationData: secondary ? loadingIconWhite : loadingIcon,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    const content = (
        <>
            {icon && <img className={`${onlyIconClass ? IconClassName : styles.icon}`} src={icons[icon]} alt={icon} />}
            {props.children}
        </>
    );

    return (
        <button
            className={`${props.outlined ? styles.buttonOutline : styles.button} ${
                isLoading && styles.loadingButton
            } ${className} ${secondaryClassName} ${
                props.outlined ? styles.blueTextColor : redColor ? styles.red : styles.blue
            }`}
            {...props}
            disabled={disabled ? true : props.outlined ? false : isLoading}
        >
            {props.outlined ? (
                content
            ) : isLoading ? (
                <Lottie
                    options={lottieOptions}
                    height={smallLottie ? 30 : 50}
                    width={smallLottie ? 30 : 50}
                    style={{ margin: '0' }}
                />
            ) : (
                content
            )}
        </button>
    );
};

Button.propTypes = {
    'data-testid': PropTypes.string,
    icon: PropTypes.string,
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    secondary: PropTypes.bool,
    outlined: PropTypes.bool,
};

export default Button;

Button.propTypes = {
    children: PropTypes.element.isRequired,
    disabled: PropTypes.bool,
    smallLottie: PropTypes.any,
    redColor: PropTypes.string,
};
