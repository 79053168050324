import { MODULE_NAME } from './profileConstants';
import { requestActions } from '../actionsCreator';
import { requestApiCall } from '../connectivity/connectivityActions';
import api from '../../api';

/**
 * Actions constants
 */

export const GET_USER_PROFILE = requestActions(MODULE_NAME, 'GET_USER_PROFILE');
export const UPLOAD_PROFILE_PHOTO = requestActions(MODULE_NAME, 'UPLOAD_PROFILE_PHOTO');
export const UPLOAD_BACKGROUND_PHOTO = requestActions(MODULE_NAME, 'UPLOAD_BACKGROUND_PHOTO');
export const CLEAN_PROFILE = 'CLEAN_PROFILE';
export const CLEAN_EVERYTHING = 'CLEAN_EVERYTHING';
export const CLEAN_POSTS = 'CLEAN_POSTS';
export const DELETE_IMAGE = requestActions(MODULE_NAME, 'DELETE_IMAGE');
export const GET_USER_POSTS = requestActions(MODULE_NAME, 'GET_USER_POSTS');

/**
 * Actions creators
 */

export const getUserProfile = (permaLink, noLoading = false) =>
    requestApiCall(api.names.getUserProfile, { permaLink, noLoading }, GET_USER_PROFILE);
export const getUserPosts = (userId, page, reload = false) =>
    requestApiCall(api.names.getUserPosts, { userId, page, reload }, GET_USER_POSTS);

export const uploadProfilePhoto = (file, type) =>
    requestApiCall(
        api.names.uploadProfilePhoto,
        { file },
        type === 'profile' ? UPLOAD_PROFILE_PHOTO : UPLOAD_BACKGROUND_PHOTO
    );
export const deleteImage = (key, type) => requestApiCall(api.names.deleteImage, { key, type }, DELETE_IMAGE);
export const cleanProfile = () => ({ type: CLEAN_PROFILE });
export const cleanEverything = () => ({ type: CLEAN_EVERYTHING });
export const cleanPosts = () => ({ type: CLEAN_POSTS });
