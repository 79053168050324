import client from '../client';

export const getQRCode = () => client.get('/api/2fa/app');

export const get2FA = () => client.get('/api/2fa');

export const registerQRCode = ({ secret }) => client.post('/api/2fa/app', { secret, default: true });

export const registerPhone = ({ country, phone }) =>
    client.post('/api/2fa/phone', { countryCode: country, phoneNumber: phone });

export const submitCode = ({ code, authPhone }) => client.post(`/api/2fa/phone/verify/${authPhone}`, { token: code });

export const resendCode = ({ code }) => client.post(`/api/2fa/phone/resendToken/${code}`);

/*
2FA delete routes
*/

export const verifyMobileAppCode = ({ code, authPhone }) =>
    client.post(`/api/2fa/phone/verify/${authPhone}`, { token: code });

export const verifyAppAuthCodeToDelete = ({ code, token }) =>
    client.post(`/api/2fa/app/verify/${code}`, { token });

export const verifyAppAuth = ({ code, token }) => client.post(`/api/2fa/app/verify/${code}`, { token });

export const deleteAppAuth = ({ code }) => client.delete(`/api/2fa/app/${code}`);

export const deletePhoneAuth = ({ code }) => client.delete(`/api/2fa/phone/${code}`);
