import client from '../client';

export const getUserCompanies = ({ userId, page, searchTerm }) =>
    client.get(`/api/companies/${userId}/all?page=${page}&per_page=20&term=${searchTerm}`);

export const getUserManagedCompanies = ({ userId, page, searchTerm }) =>
    client.get(`/api/companies/${userId}/my?page=${page}&per_page=20&term=${searchTerm}`);

export const getUserSugestionCompanies = ({ page, searchTerm }) =>
    client.get(`/api/companies/suggestions?page=${page}&per_page=20`);

export const createCompany = ({ name, privacy, category, subcategory, description }) =>
    client.post('/api/companies', {
        name,
        privacy,
        category,
        subcategory,
        description,
    });

export const editCompany = ({ companyId, name, privacy, category, subcategory, description, permalink }) =>
    client.patch(`/api/companies/${companyId}`, {
        name,
        privacy,
        category,
        subcategory,
        description,
        options: {
            wall: 0,
            pictures: 0,
            contacts: 0,
        },
        location: null,
        links: [],
        permalink,
    });

export const deleteCompany = ({ companyId }) => client.delete(`/api/companies/${companyId}`);

export const companyAction = ({ companyId, action }) => client.post(`/api/companies/${companyId}/${action}`);

export const getSpecificCompany = ({ companyPermalink }) => client.get(`/api/companies/${companyPermalink}`);

export const getCompanyMembers = ({ companyId, searchTerm, page }) =>
    client.get(`/api/companies/${companyId}/members?per_page=20&page=${page}&search_text=${searchTerm}`);

export const getCompanyPost = ({ entityId, page }) =>
    client.get(`/api/post/company/${entityId}?page=${page}&per_page=10`);

export const changeUserRoleCompany = ({ companyId, userId, role }) =>
    client.patch(`/api/companies/${companyId}/members/${userId}/role`, { role });

export const deleteMemberCompany = ({ companyId, userId }) =>
    client.delete(`/api/companies/${companyId}/members/${userId}`);

export const lisUserToInviteInCompany = ({ companyId, page = 1, searchTerm = '' }) =>
    client.get(`/api/follower/except/company/${companyId}?per_page=20&page=${page}&term=${searchTerm}`);

export const addCompanyToMember = ({ companyId, userId, position }) =>
    client.post(`/api/companies/${companyId}/members/${userId}`, { position });
