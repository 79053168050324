import PropTypes from 'prop-types';
import React from 'react';
import styles from './ErrorMessage.module.scss';
import { Text } from '../index';

const ErrorMessage = ({ message, ...props }) => {
    if (!message) return null;

    return (
        <div {...props} role="alert" className={styles.error}>
            {message?.id ? (
                <Text {...message}>{message?.id ?? message}</Text>
            ) : (
                <Text {...message}>{message?.id ?? message}</Text>
            )}
        </div>
    );
};

ErrorMessage.propTypes = {
    'data-testid': PropTypes.string,
    message: PropTypes.oneOfType([
        PropTypes.shape({
            id: PropTypes.any,
        }),
        PropTypes.string,
    ]),
};

export default ErrorMessage;
