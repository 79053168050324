import { MODULE_NAME } from './followerConstants';
import { requestActions } from '../actionsCreator';
import { requestApiCall } from '../connectivity/connectivityActions';
import api from '../../api';

/**
 * Actions constants
 */
export const GET_FOLLOWERS = requestActions(MODULE_NAME, 'GET_FOLLOWERS');
export const GET_FOLLOWING = requestActions(MODULE_NAME, 'GET_FOLLOWING');
export const UNFOLLOW_ACTION = requestActions(MODULE_NAME, 'UNFOLLOW_ACTION');
export const FOLLOW_REQUEST = requestActions(MODULE_NAME, 'FOLLOW_REQUEST');
export const DIRECT_FOLLOW = requestActions(MODULE_NAME, 'DIRECT_FOLLOW');
export const CANCEL_FOLLOW_REQUEST = requestActions(MODULE_NAME, 'CANCEL_FOLLOW_REQUEST');
export const REMOVE_FOLLOWER = requestActions(MODULE_NAME, 'REMOVE_FOLLOWER');
/**
 * Action creators
 */

export const getFollowers = ({ userId, page, searchTerm = '', clear = false, filters, reload = false }) =>
    requestApiCall(api.names.getFollowers, { userId, page, searchTerm, clear, filters, reload }, GET_FOLLOWERS);

export const getFollowing = ({ userId, page, searchTerm = '', clear = false, filters, reload = false }) =>
    requestApiCall(api.names.getFollowing, { userId, page, searchTerm, clear, filters, reload }, GET_FOLLOWING);

export const unFollowUser = ({ userId, isOtherProfile = false }) =>
    requestApiCall(api.names.unFollowUser, { userId, isOtherProfile }, UNFOLLOW_ACTION);
export const removeFollower = ({ userId }) => requestApiCall(api.names.removeFollower, { userId }, REMOVE_FOLLOWER);

export const sendFollowInvite = ({ userId, isOtherProfile = false }) =>
    requestApiCall(api.names.sendFollowInvite, { userId, isOtherProfile }, FOLLOW_REQUEST);

export const directFollow = ({ userId, isOtherProfile = false }) =>
    requestApiCall(api.names.directFollow, { userId, isOtherProfile }, DIRECT_FOLLOW);

export const cancelFollowInvite = ({ userId }) =>
    requestApiCall(api.names.cancelFollowInvite, { userId }, CANCEL_FOLLOW_REQUEST);
