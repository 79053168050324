import { MODULE_NAME } from './groupConstants';
import { requestActions } from '../actionsCreator';
import { requestApiCall } from '../connectivity/connectivityActions';
import api from '../../api';

/**
 * Actions constants
 */
export const GET_GROUPS = requestActions(MODULE_NAME, 'GET_GROUPS');
export const LEAVE_GROUP = requestActions(MODULE_NAME, 'LEAVE_GROUP');
export const GET_GROUP_MEMBERS = requestActions(MODULE_NAME, 'GET_GROUP_MEMBERS');
export const JOIN_GROUP = requestActions(MODULE_NAME, 'JOIN_GROUP');
export const CREATE_GROUP = requestActions(MODULE_NAME, 'CREATE_GROUP');
export const EDIT_GROUP = requestActions(MODULE_NAME, 'EDIT_GROUP');
export const GET_GROUPS_CATEGORIES = requestActions(MODULE_NAME, 'GET_GROUPS_CATEGORIES');
export const INVITE_ACTION = requestActions(MODULE_NAME, 'INVITE_ACTION');
export const GET_GROUPS_SUGGESTIONS = requestActions(MODULE_NAME, 'GET_GROUPS_SUGGESTIONS');
export const GET_GROUPS_INVITATION = requestActions(MODULE_NAME, 'GET_GROUPS_INVITATION');
export const GET_SPECIFIC_GROUP = requestActions(MODULE_NAME, 'GET_SPECIFIC_GROUP');
export const UPLOAD_PROFILE_PHOTO = requestActions(MODULE_NAME, 'UPLOAD_PROFILE_PHOTO');
export const UPLOAD_BACKGROUND_PHOTO = requestActions(MODULE_NAME, 'UPLOAD_BACKGROUND_PHOTO');
export const GET_FEED = requestActions(MODULE_NAME, 'GET_FEED');
export const DELETE_GROUP = requestActions(MODULE_NAME, 'DELETE_GROUP');
export const BLOCK_UNBLOCK_MEMBER = requestActions(MODULE_NAME, 'BLOCK_UNBLOCK_MEMBER');
export const DELETE_MEMBER = requestActions(MODULE_NAME, 'DELETE_MEMBER');
export const CHANGE_USER_ROLE = requestActions(MODULE_NAME, 'CHANGE_USER_ROLE');
export const LIST_USER_TO_INVITE = requestActions(MODULE_NAME, 'LIST_USER_TO_INVITE');
export const SEND_GROUP_INVITATION = requestActions(MODULE_NAME, 'SEND_GROUP_INVITATION');
export const CLEAN_SPECIFIC_GROUP = 'CLEAN_SPECIFIC_GROUP';
export const HIDE = 'HIDE';
/**
 * Actions creators
 */
export const hide = ({ type, groupId }) => ({ type: HIDE, payload: { type, groupId } });
export const getUserGroups = ({ userId, page, searchTerm, reload = false }) =>
    requestApiCall(api.names.getUserGroups, { userId, page, searchTerm, reload }, GET_GROUPS);

export const getUserGroupSuggestions = ({ userId, page, searchTerm, reload = false }) =>
    requestApiCall(api.names.getUserGroupSuggestions, { userId, page, searchTerm, reload }, GET_GROUPS_SUGGESTIONS);

export const lisUserToInvite = ({ groupId, page, searchTerm, reload = false }) =>
    requestApiCall(api.names.lisUserToInvite, { groupId, page, searchTerm, reload }, LIST_USER_TO_INVITE);

export const getUserGroupInvitation = ({ userId, page, searchTerm, reload = false, dir }) =>
    requestApiCall(api.names.getUserGroupInvitation, { userId, page, searchTerm, reload, dir }, GET_GROUPS_INVITATION);

export const createGroup = ({ userId, name, privacy, category, subcategory, description, post_group }) =>
    requestApiCall(
        api.names.createGroup,
        { userId, name, privacy, category, subcategory, description, post_group },
        CREATE_GROUP
    );

export const deleteGroup = ({ groupId }) => requestApiCall(api.names.deleteGroup, { groupId }, DELETE_GROUP);

export const blockAndUnblockMember = ({ groupId, userId, blockType }) =>
    requestApiCall(api.names.blockAndUnblockMember, { groupId, userId, blockType }, BLOCK_UNBLOCK_MEMBER);

export const deleteMember = ({ groupId, userId, leave = false }) =>
    requestApiCall(api.names.deleteMember, { groupId, userId, leave }, DELETE_MEMBER);

export const changeUserRole = ({ groupId, userId, role }) =>
    requestApiCall(api.names.changeUserRole, { groupId, userId, role }, CHANGE_USER_ROLE);

export const sendGroupInvite = ({ groupId, userId }) =>
    requestApiCall(api.names.sendGroupInvite, { groupId, userId }, SEND_GROUP_INVITATION);

export const editGroup = ({
    userId,
    name,
    privacy,
    category,
    subcategory,
    description,
    groupId,
    permalink,
    location,
    links,
    pictures,
    contacts,
    wall,
    post_group,
}) =>
    requestApiCall(
        api.names.editGroup,
        {
            groupId,
            userId,
            name,
            privacy,
            category,
            subcategory,
            description,
            permalink,
            location,
            links,
            pictures,
            contacts,
            wall,
            post_group,
        },
        EDIT_GROUP
    );

export const leaveGroup = ({ groupId, leave = false }) =>
    requestApiCall(api.names.leaveGroup, { groupId, leave }, LEAVE_GROUP);
export const joinGroup = ({ groupId, group = {} }) =>
    requestApiCall(api.names.joinGroup, { groupId, group }, JOIN_GROUP);
export const getGroupMembers = ({
    groupId,
    searchTerm = '',
    page = 1,
    reload = false,
    reloadAllMembers = false,
    filters,
}) =>
    requestApiCall(
        api.names.getGroupMembers,
        { groupId, searchTerm, page, reload, reloadAllMembers, filters },
        GET_GROUP_MEMBERS
    );

export const getGroupCategories = () => requestApiCall(api.names.getGroupCategories, {}, GET_GROUPS_CATEGORIES);

export const actionOnInvite = ({ groupId, action }) =>
    requestApiCall(api.names.actionOnInvite, { groupId, action }, INVITE_ACTION);

export const getSpecificGroup = ({ permaLink }) =>
    requestApiCall(api.names.getSpecificGroup, { permaLink }, GET_SPECIFIC_GROUP);

export const uploadGroupPhoto = (file, type) =>
    requestApiCall(
        api.names.uploadProfilePhoto,
        { file },
        type === 'profile' ? UPLOAD_PROFILE_PHOTO : UPLOAD_BACKGROUND_PHOTO
    );

export const cleanGroup = () => ({ type: CLEAN_SPECIFIC_GROUP });
