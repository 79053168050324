import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

const createTextComponent = type =>
    injectIntl(props => {
        const {
            intl: { formatMessage: t },
            children,
            className,
            ...others
        } = props;

        if (!children) return <></>;

        const Component = type ?? React.Fragment;
        const text = t({ id: children }, others);

        if (className) {
            return <Component className={className}>{text}</Component>;
        }
        return <Component>{text}</Component>;
    });

const propTypes = {
    intl: PropTypes.shape({
        formatMessage: PropTypes.func,
    }),
    type: PropTypes.string,
    className: PropTypes.string,
};

const Text = createTextComponent();
Text.propTypes = propTypes;

const Span = createTextComponent('span');
Span.propTypes = propTypes;

const Label = createTextComponent('label');
Label.propTypes = propTypes;

export { Text, Span, Label };
