import produce from 'immer';
import * as actions from './followerActions';
import { getErrorFrom } from '../utils';
import { merge } from 'lodash-es';

const initialState = {
    followers: {
        isLoading: true,
        error: undefined,
        success: false,
        followers: [],
        hasMore: true,
        page: 1,
    },
    following: {
        isLoading: true,
        error: undefined,
        success: false,
        following: [],
        hasMore: true,
        page: 1,
    },
    actionSuccess: false,
    actionError: undefined,
};

const getFollower = (list, follower) => list.find(item => item.id === follower.id);

export const reducer = produce((state = initialState, { type, payload }) => {
    const data = payload?.data;
    switch (type) {
        case actions.REMOVE_FOLLOWER.SUCCEEDED:
            state.followers.followers = state.followers.followers?.filter(item => item?.id !== payload?.args?.userId);
            break;
        case actions.UNFOLLOW_ACTION.SUCCEEDED: {
            if (payload?.args?.isOtherProfile) {
                const following = getFollower(state?.following?.following, { id: payload?.args?.userId });
                const follower = getFollower(state?.followers?.followers, { id: payload?.args?.userId });

                if (following) {
                    following.isFollowing = false;
                }
                if (follower) {
                    follower.isFollowing = false;
                }
                break;
            }
            state.actionSuccess = true;
            state.actionError = true;
            state.following.following = state.following.following?.filter(item => item.id !== payload?.args?.userId);

            break;
        }
        case actions.UNFOLLOW_ACTION.FAILED:
            state.actionSuccess = false;
            state.actionError = getErrorFrom(payload);
            break;
        case actions.GET_FOLLOWING.REQUESTED:
            if (payload.args?.clear) {
                state.following.following = [];
            }
            state.following.isLoading = true;
            state.following.error = undefined;
            state.following.success = false;
            state.following.page = payload.args.page;
            state.following.hasMore = true;
            break;
        case actions.GET_FOLLOWING.SUCCEEDED:
            if (payload?.args?.reload) {
                state.following.following = [];
            }
            state.following.isLoading = false;
            state.following.error = undefined;
            state.following.success = true;

            if (data?.list && data?.list?.length > 0) {
                data.list[data?.list?.length - 1].position = data.list.length;
                state.following.following = [...state.following.following, ...data?.list];
            } else {
                state.following.hasMore = false;
            }

            break;
        case actions.GET_FOLLOWING.FAILED:
            state.following.isLoading = false;
            state.following.error = getErrorFrom(payload);
            state.following.success = false;
            break;
        case actions.GET_FOLLOWERS.REQUESTED:
            if (payload.args?.clear) {
                state.followers.followers = [];
            }
            state.followers.isLoading = true;
            state.followers.error = undefined;
            state.followers.success = false;
            state.followers.page = payload.args.page;
            state.followers.hasMore = true;
            break;
        case actions.GET_FOLLOWERS.SUCCEEDED:
            if (payload?.args?.reload) {
                state.followers.followers = [];
            }
            state.followers.isLoading = false;
            state.followers.error = undefined;
            state.followers.success = true;

            if (data?.list && data?.list?.length > 0) {
                data.list[data?.list?.length - 1].position = data.list.length;
                state.followers.followers = [...state.followers.followers, ...data?.list];
            } else {
                state.followers.hasMore = false;
            }
            break;
        case actions.GET_FOLLOWERS.FAILED:
            state.followers.isLoading = false;
            state.followers.error = getErrorFrom(payload);
            state.followers.success = false;
            break;

        case actions.DIRECT_FOLLOW.SUCCEEDED: {
            if (payload?.args?.isOtherProfile) {
                const follower = getFollower(state?.followers?.followers, { id: payload?.args?.userId });
                const following = getFollower(state?.following?.following, { id: payload?.args?.userId });

                if (follower) {
                    follower.isFollowing = true;
                }
                if (following) {
                    following.isFollowing = true;
                }
            }
            break;
        }

        case actions.FOLLOW_REQUEST.SUCCEEDED: {
            if (payload?.args?.isOtherProfile) {
                const follower = getFollower(state?.followers?.followers, { id: payload?.args?.userId });
                const following = getFollower(state?.following?.following, { id: payload?.args?.userId });

                if (follower) {
                    follower.followStatus = 'sent';
                }
                if (following) {
                    following.followStatus = 'sent';
                }
            }
            break;
        }
        default:
            return state;
    }
    return state;
});

export default reducer;
