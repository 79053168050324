import React, { memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
// const { required, minLength, emailFormat, useForm, maxLength }  = {};
const useForm = () => {}, required = () => {}, minLength = () => {};
const emailFormat = () => {}, maxLength = () => {};
import { isNil } from 'lodash';
import { actions as authActions } from '../../state/auth';
import { LoginWrapper } from '../../components/Login';
import { Login } from '../../components/Login/Login';
import { Login2FA } from '../../components/Login/Login2FA';
import { FORGOT_PASSWORD, SIGN_UP } from '../../routesMap';

const type2FAEnum = {
    PHONE: 'phone',
    APP: 'app',
};

export const ConvertPage = memo(props => {
    const dispatch = useDispatch();
    const error = useSelector(state => state.auth?.login?.error);
    const isLoading = useSelector(state => state.auth?.login?.isLoading);
    const is2FA = useSelector(state => state.auth?.login?.is2FA);
    const error2FA = useSelector(state => state.auth?.login?.error2FA);
    const app = useSelector(state => state.auth?.login?.app);
    const phone = useSelector(state => state.auth?.login?.phone);
    const appId = useSelector(state => state.auth?.login?.appId);
    const phoneId = useSelector(state => state.auth?.login?.phoneId);

    const fields = {}, form = {};
    // const [fields, form] = useForm({
    //     email: [required(), minLength(8), emailFormat()],
    //     password: [required()],
    // });
    const [type2FA, setType2FA] = useState(type2FAEnum.APP);
    const fields2FA = '', form2FA = '';
    // const [fields2FA, form2FA] = useForm({
    //     code: [required(), minLength(6), maxLength(6)],
    // });

    const onResendToken = () => dispatch(authActions.resendToken({ id: phoneId }));

    useEffect(() => {
        if (!isNil(phone) && type2FA === type2FAEnum.PHONE) onResendToken();
    }, [type2FA, phone]);

    useEffect(() => {
        setType2FA(isNil(phone) ? type2FAEnum.APP : type2FAEnum.PHONE);
    }, [phone, app]);

    const onLogin = () => {
        if (form.isValid()) {
            dispatch(authActions.login(form.value));
        } else {
            form.showErrors();
        }
    };

    const onVerify = () => {
        if (form2FA.isValid()) {
            const id = type2FA === type2FAEnum.PHONE ? phoneId : appId;
            dispatch(authActions.verify2FA({ id, token: form2FA.value.code, type: type2FA }));
        } else {
            form2FA.showErrors();
        }
    };

    const onChangeType = () => {
        setType2FA(type2FA === type2FAEnum.PHONE ? type2FAEnum.APP : type2FAEnum.PHONE);
    };

    const onGoToForgotPassword = () => dispatch(push(FORGOT_PASSWORD, null));
    const onGoToSignUp = () => dispatch(push(SIGN_UP, null));

    return (
        <LoginWrapper {...props}>
            <Login
                isLoading={isLoading}
                onLogin={onLogin}
                fields={fields}
                onGoToForgotPassword={onGoToForgotPassword}
                onGoToSignUp={onGoToSignUp}
                error={error}
            />
        </LoginWrapper>
    );
});

ConvertPage.displayName = 'ConvertPage';
