export const ROOT = '/';
export const LOGIN = '/login';
export const MAINTENANCE = '/maintenance';
export const FORGOT_PASSWORD = '/forgotPassword';
export const SIGN_UP = '/signUp';
export const PENDING_CONFIRMATION = '/pendingConfirmation';
export const PASSWORD_UPDATED = '/passwordUpdated';
export const EMAIL_CONFIRMED = '/confirm-email';
export const UPDATE_PASSWORD = '/auth/change-password';
export const SETTINGS = '/settings';
export const SETTINGS_BASIC = '/settings/basic';
export const LEGAL_ROUTES = '/legal';
export const LEGAL_DISCLOSURES = '/legal/disclosures';
export const LEGAL_REFUNDS = '/legal/refunds';
export const LEGAL_PRIVACY = '/legal/privacy';
export const LEGAL_COOKIES = '/legal/cookies';
export const LEGAL_TERMS = '/legal/terms';
export const SEARCH_PAGE = '/search';
export const SEARCH_POSTS = '/search/posts';
export const USER_PROFILE = '/:username';
export const SINGLE_POST_PAGE = '/post/:post_id';
export const FOLLOWERS = '/followers';
export const FOLLOWING = '/following';
export const COMPANIES = '/companies';
export const GROUPS = '/groups';
export const SPECIFIC_GROUP = '/group/:group_id';
export const SPECIFIC_COMPANY = '/company/:group_id';
export const NOTIFICATIONS = '/notifications';
export const PHOTOS = '/photos';
export const DASHBOARD = 'https://www.someesocial.com/';
export const INFLUENCER = 'https://on.somee.social/onenameglobalinc/form/SoMeeInfluencerApplication/formperma/Lj4AOof1zpeQzGhepGs8GqbVHqSG-RtKuni3nkQlwK4';
