import { MODULE_NAME } from './twoFactorConstants';
import { requestActions } from '../actionsCreator';
import { requestApiCall } from '../connectivity/connectivityActions';
import api from '../../api';

/**
 * Actions constants
 */
export const GET_2FA = requestActions(MODULE_NAME, 'GET_2FA');
export const GENERATE_QRCODE = requestActions(MODULE_NAME, 'GENERATE_QRCODE');
export const REGISTER_QRCODE = requestActions(MODULE_NAME, 'REGISTER_QRCODE');
export const REGISTER_PHONE = requestActions(MODULE_NAME, 'REGISTER_PHONE');
export const SUBMIT_CODE = requestActions(MODULE_NAME, 'SUBMIT_CODE');
export const RESEND_CODE = requestActions(MODULE_NAME, 'RESEND_CODE');
export const VERIFY_APP_AUTH = requestActions(MODULE_NAME, 'VERIFY_APP_AUTH');
export const DELETE_APP_AUTH = requestActions(MODULE_NAME, 'DELETE_APP_AUTH');
export const DELETE_PHONE_AUTH = requestActions(MODULE_NAME, 'DELETE_PHONE_AUTH');
export const VERIFY_PHONE_APP_CODE = requestActions(MODULE_NAME, 'VERIFY_PHONE_APP_CODE');
export const VERIFY_AUTH_APP_CODE = requestActions(MODULE_NAME, 'VERIFY_AUTH_APP_CODE');
export const CLEAN_2FA = 'CLEAN_2FA';

/**
 * Action creators
 */

export const clean2FA = () => ({ type: CLEAN_2FA });
export const get2FA = () => requestApiCall(api.names.get2FA, {}, GET_2FA);
export const deleteAppAuth = ({ code }) =>
    requestApiCall(
        api.names.deleteAppAuth,
        { code, notification: { text: 'SecuritSettings.googleAuthRemoved' } },
        DELETE_APP_AUTH
    );
export const deletePhoneAuth = ({ code }) =>
    requestApiCall(
        api.names.deletePhoneAuth,
        { code, notification: { text: 'SecuritSettings.textMessageAuthRemoved' } },
        DELETE_PHONE_AUTH
    );

export const generateQrCode = () => requestApiCall(api.names.getQRCode, {}, GENERATE_QRCODE);
export const registerQRCode = secret => requestApiCall(api.names.registerQRCode, { secret }, REGISTER_QRCODE);

export const registerPhone = ({ country, phone }) =>
    requestApiCall(
        api.names.registerPhone,
        {
            country,
            phone,
        },
        REGISTER_PHONE
    );
export const submitCode = ({ code, authPhone }) =>
    requestApiCall(
        api.names.submitCode,
        {
            code,
            authPhone,
            notification: { text: 'SecuritSettings.twoFaEnabled' },
        },
        SUBMIT_CODE
    );

export const verifyMobileAppCode = ({ code, authPhone }) =>
    requestApiCall(
        api.names.verifyMobileAppCode,
        {
            code,
            authPhone,
        },
        VERIFY_PHONE_APP_CODE
    );

export const verifyAppAuthCodeToDelete = ({ code, token }) =>
    requestApiCall(
        api.names.verifyAppAuthCodeToDelete,
        {
            code,
            token,
        },
        VERIFY_AUTH_APP_CODE
    );

export const resendCode = ({ code }) =>
    requestApiCall(
        api.names.resendCode,
        {
            code,
            notification: { text: 'SecuritSettings.codeSend' },
        },
        RESEND_CODE
    );

export const verifyAppAuth = ({ code, token }) =>
    requestApiCall(
        api.names.verifyAppAuth,
        {
            code,
            token,
            notification: { text: 'SecuritSettings.twoFaEnabled' },
        },
        VERIFY_APP_AUTH
    );
