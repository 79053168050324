import produce from 'immer';
import { merge } from 'lodash';
import * as actions from './profileActions';
import * as feedActions from '../feed/feedActions';
import * as followerActions from '../followers/followerActions';

const initialState = {
    user: {},
    userFetchSucess: false,
    userFetchError: false,
    userFetchLoading: false,
    uploadProfileLoading: false,
    uploadProfileSuccess: false,
    uploadProfileError: false,
    uploadBackgroundLoading: false,
    uploadBackgroundSuccess: false,
    uploadBackgroundError: false,
    delteImageSuccess: false,
    delteImageLoading: false,
    delteImageError: false,
    userPosts: [],
    userPostsLoading: false,
    userPostsSuccess: false,
    userPostsError: false,
    postsHasMore: true,
    currentPage: 0,
    replyLevel1: {
        newReplyCommentDataToUploadImage: {},
        editReplyCommentImageFile: '',
    },
    replyLevel2: {
        newReplyCommentDataToUploadImage: {},
        editReplyCommentImageFile: '',
    },
    replyLevel3: {
        newReplyCommentDataToUploadImage: {},
        editReplyCommentImageFile: '',
    },
};

const getPost = (list, post) => list.find(item => item.id === post.id);
const getPostIndex = (list, post) => list.findIndex(item => item.id === post.id);
const getCommentIndex = (list, comment) => list.findIndex(item => item.id === comment.id);
const updatePost = (list, postChanges, clearComments) => {
    const index = getPostIndex(list, postChanges);
    const post = list[index];

    // merge new comments with old ones
    if (typeof postChanges === 'undefined') return [];
    if (postChanges.commentsList)
        postChanges.commentsList = post.commentsList
            ? [...post.commentsList, ...postChanges.commentsList]
            : postChanges.commentsList;
    if (clearComments) post.commentsList = [];

    list[index] = merge({}, post, postChanges);
    return list;
};
const updateComment = (list, commentChanges) => {
    const index = getCommentIndex(list, commentChanges);
    const comment = list[index];
    if (commentChanges.reload && comment.commentsReplyList) comment.commentsReplyList = [];
    if (commentChanges.commentsReplyList) {
        const allNewComment = comment.commentsReplyList
            ? [...commentChanges.commentsReplyList, ...comment.commentsReplyList]
            : commentChanges.commentsReplyList;

        let myArrSerialized = allNewComment.map(e => JSON.stringify(e));
        const mySetSerialized = new Set(myArrSerialized);

        const myUniqueArrSerialized = [...mySetSerialized];

        commentChanges.commentsReplyList = myUniqueArrSerialized.map(e => JSON.parse(e));
    }
    list[index] = merge({}, comment, commentChanges);
    return list;
};

export const reducer = produce((state = initialState, { type, payload }) => {
    const data = payload?.data;

    switch (type) {
        case followerActions.UNFOLLOW_ACTION.SUCCEEDED: {
            if (payload.args?.userId === state.user?.id) {
                state.user.isFollowing = false;
                state.userPostsLoading = false;
                state.userPostsSuccess = false;
                state.postsHasMore = true;
                state.userPostsError = false;
                state.currentPage = 0;
                state.userPosts = [];
            }
            break;
        }
        case followerActions.DIRECT_FOLLOW.SUCCEEDED: {
            if (payload?.args?.isOtherProfile) {
                break;
            }
            state.user.isFollowing = true;
            break;
        }
        case feedActions.DOWNVOTE.SUCCEEDED: {
            if (payload?.args?.isProfile) {
                const post = getPost(state.userPosts, { id: payload.args.postId });
                if (post.voted === 'like') {
                    post.votesUp -= 1;
                    post.votesDown += 1;
                    post.voted = 'dislike';
                } else if (post.voted === 'dislike') {
                    post.votesDown -= 1;
                    post.voted = null;
                } else {
                    post.votesDown += 1;
                    post.voted = 'dislike';
                }
            }
            break;
        }
        case feedActions.UPVOTE.SUCCEEDED: {
            if (payload?.args?.isProfile) {
                const post = getPost(state.userPosts, { id: payload.args.postId });
                if (post.voted === 'dislike') {
                    post.votesDown -= 1;
                    post.votesUp += 1;
                    post.voted = 'like';
                } else if (post.voted === 'like') {
                    post.votesUp -= 1;
                    post.voted = null;
                } else {
                    post.votesUp += 1;
                    post.voted = 'like';
                }
            }
            break;
        }
        case followerActions.FOLLOW_REQUEST.SUCCEEDED: {
            if (payload?.args?.isOtherProfile) {
                break;
            }
            state.user.followStatus = 'sent';
            break;
        }
        case followerActions.CANCEL_FOLLOW_REQUEST.SUCCEEDED: {
            if (payload?.args?.isOtherProfile) {
                break;
            }
            state.user.followStatus = null;
            break;
        }
        case feedActions.CREATE_POST.REQUESTED: {
            state.postsHasMore = true;
            state.currentPage = 0;
            break;
        }
        case actions.CLEAN_POSTS: {
            state.userPosts = [];
            state.postsHasMore = true;
            state.currentPage = 0;
            break;
        }
        case actions.CLEAN_PROFILE: {
            state.userFetchError = false;
            state.userFetchSucess = false;
            state.userFetchLoading = false;
            state.uploadProfileError = false;
            state.uploadProfileSuccess = false;
            state.uploadProfileLoading = false;
            state.delteImageError = false;
            state.delteImageSuccess = false;
            state.delteImageLoading = false;
            state.userPostsLoading = false;
            state.userPostsSuccess = false;
            state.userPostsError = false;
            state.postsHasMore = true;
            break;
        }
        case actions.CLEAN_EVERYTHING: {
            state.userFetchError = false;
            state.userFetchSucess = false;
            state.userFetchLoading = false;
            state.uploadProfileError = false;
            state.uploadProfileSuccess = false;
            state.uploadProfileLoading = false;
            state.delteImageError = false;
            state.delteImageSuccess = false;
            state.delteImageLoading = false;
            state.userPostsLoading = false;
            state.userPostsSuccess = false;
            state.userPostsError = false;
            state.postsHasMore = true;
            state.currentPage = 0;
            state.user = {};
            state.userPosts = [];
            break;
        }
        case actions.GET_USER_POSTS.REQUESTED: {
            if (payload?.args?.reload) {
                state.userPosts = [];
                state.userPostsLoading = true;
            }
            state.userPostsLoading = true;
            state.userPostsSuccess = false;
            state.userPostsError = false;
            break;
        }
        case actions.GET_USER_POSTS.SUCCEEDED: {
            if (payload?.args?.reload) {
                state.userPosts = [];
                // state.userPostsLoading = true;
            }
            if (data?.list && data?.list?.length > 0) {
                state.postsHasMore = true;
                data.list[data.list.length - 1].position = state.userPosts.length;
                state.userPosts = [...state.userPosts, ...data?.list];
            } else {
                state.postsHasMore = false;
            }

            state.userPostsLoading = false;
            state.userPostsSuccess = true;
            state.userPostsError = false;
            state.currentPage = payload.args?.page;
            break;
        }
        case actions.GET_USER_POSTS.FAILED: {
            state.userPostsLoading = false;
            state.userPostsSuccess = false;
            state.userPostsError = true;
            break;
        }
        case actions.DELETE_IMAGE.REQUESTED: {
            if (payload.args.type === 'profile') {
                state.uploadProfileError = false;
                state.uploadProfileSuccess = false;
                state.uploadProfileLoading = true;
            }
            if (payload.args.type === 'background') {
                state.uploadBackgroundError = false;
                state.uploadBackgroundSuccess = false;
                state.uploadBackgroundLoading = true;
            }
            break;
        }
        case actions.DELETE_IMAGE.SUCCEEDED: {
            if (payload.args.type === 'profile') {
                state.user = { ...state.user, avatar: null };
                state.uploadProfileError = false;
                state.uploadProfileSuccess = true;
                state.uploadProfileLoading = false;
            }
            if (payload.args.type === 'background') {
                state.user = { ...state.user, cover: null };
                state.uploadBackgroundError = false;
                state.uploadBackgroundSuccess = true;
                state.uploadBackgroundLoading = false;
            }
            state.delteImageError = false;
            state.delteImageSuccess = true;
            state.delteImageLoading = false;
            break;
        }
        case actions.DELETE_IMAGE.FAILED: {
            if (payload.args.type === 'background') {
                state.uploadBackgroundError = true;
                state.uploadBackgroundSuccess = false;
                state.uploadBackgroundLoading = false;
            }
            if (payload.args.type === 'profile') {
                state.uploadProfileError = true;
                state.uploadProfileSuccess = false;
                state.uploadProfileLoading = false;
            }
            state.delteImageError = true;
            state.delteImageSuccess = false;
            state.delteImageLoading = false;
            break;
        }
        case actions.UPLOAD_BACKGROUND_PHOTO.REQUESTED: {
            state.uploadBackgroundError = false;
            state.uploadBackgroundSuccess = false;
            state.uploadBackgroundLoading = true;
            break;
        }
        case actions.UPLOAD_BACKGROUND_PHOTO.SUCCEEDED: {
            state.uploadBackgroundError = false;
            state.uploadBackgroundSuccess = true;
            state.uploadBackgroundLoading = false;
            state.user = { ...state.user, cover: { key: data?.fileId } };
            break;
        }
        case actions.UPLOAD_BACKGROUND_PHOTO.FAILED: {
            state.uploadBackgroundError = true;
            state.uploadBackgroundSuccess = false;
            state.uploadBackgroundLoading = false;
            break;
        }
        case actions.UPLOAD_PROFILE_PHOTO.REQUESTED: {
            state.uploadProfileError = false;
            state.uploadProfileSuccess = false;
            state.uploadProfileLoading = true;
            break;
        }
        case actions.UPLOAD_PROFILE_PHOTO.SUCCEEDED: {
            state.uploadProfileError = false;
            state.uploadProfileSuccess = true;
            state.uploadProfileLoading = false;
            state.user = { ...state.user, avatar: { key: data?.fileId } };
            break;
        }
        case actions.UPLOAD_PROFILE_PHOTO.FAILED: {
            state.uploadProfileError = true;
            state.uploadProfileSuccess = false;
            state.uploadProfileLoading = false;
            break;
        }
        case actions.GET_USER_PROFILE.REQUESTED: {
            if (payload.args.noLoading) {
                return;
            }
            state.user = {};
            state.userFetchError = false;
            state.userFetchSucess = false;
            state.userFetchLoading = true;
            state.userPosts = [];
            state.currentPage = 0;
            break;
        }
        case actions.GET_USER_PROFILE.SUCCEEDED: {
            if (data == null) {
                state.user = null;
                state.userFetchError = true;
                state.userFetchSucess = false;
                state.userFetchLoading = false;
                break;
            }
            state.user = data;
            state.userFetchError = false;
            state.userFetchSucess = true;
            state.userFetchLoading = false;
            break;
        }
        case actions.GET_USER_PROFILE.FAILED: {
            state.user = {};
            state.userFetchError = true;
            state.userFetchSucess = false;
            state.userFetchLoading = false;
            break;
        }
        case feedActions.SAVE_POST.SUCCEEDED: {
            if (payload.args?.isProfile) {
                const post = getPost(state.userPosts, { id: payload.args.postId });
                post.bookmarked = !post.bookmarked;
            }
            break;
        }
        case feedActions.POST_DELETE_ACTION.SUCCEEDED: {
            if (payload.args?.isProfile) {
                const post = getPost(state.userPosts, { id: payload.args.postId });
                post.deleted = true;
            }
            break;
        }
        case feedActions.EDIT_POST.SUCCEEDED: {
            if (payload.args?.isProfile) {
                const postIndex = state.userPosts.findIndex(post => post.id === payload.args.entityId);

                state.userPosts.splice(postIndex, 1, data);
            }

            break;
        }
        case feedActions.GET_COMMENTS.REQUESTED: {
            if (payload?.args?.isProfile) {
                state.userPosts = updatePost(state.userPosts, {
                    id: payload.args.postId,
                    showComments: true,
                    isLoadingComments: true,
                    commentsPage: payload.args.page,
                });
            }

            break;
        }
        case feedActions.GET_COMMENTS.SUCCEEDED: {
            if (payload?.args?.isProfile) {
                state.userPosts = updatePost(state.userPosts, {
                    id: payload.args.postId,
                    commentsList: data.list,
                    isLoadingComments: false,
                });
            }
            break;
        }
        case feedActions.GET_COMMENTS.FAILED: {
            if (payload?.args?.isProfile) {
                state.userPosts = updatePost(state.userPosts, {
                    id: payload.args.postId,
                    isLoadingComments: false,
                });
            }

            break;
        }
        case feedActions.GET_REPLY_COMMENTS.REQUESTED: {
            if (payload?.args?.isProfile) {
                if (payload.args.level === 1) {
                    const postIndex = state.userPosts?.findIndex(item => item?.id === payload?.args?.postId);
                    const commentList = state.userPosts[postIndex].commentsList;
                    state.userPosts[postIndex].commentsList = updateComment(commentList, {
                        id: payload.args.commentId,
                        isLoadingReplyComments: true,
                        commentsReplyPage: payload.args.page,
                        reload: payload.args.reload,
                    });
                    break;
                }
                if (payload.args.level === 2) {
                    const postIndex = state.userPosts?.findIndex(item => item?.id === payload?.args?.postId);
                    const parentCommentIndex = state.userPosts[postIndex]?.commentsList?.findIndex(
                        item => item?.id === payload?.args?.parentCommentId
                    );
                    const commentList = state.userPosts[postIndex].commentsList[parentCommentIndex].commentsReplyList;
                    state.userPosts[postIndex].commentsList[parentCommentIndex].commentsReplyList = updateComment(
                        commentList,
                        {
                            id: payload.args.commentId,
                            isLoadingReplyComments: true,
                            commentsReplyPage: payload.args.page,
                            reload: payload.args.reload,
                        }
                    );
                    break;
                }
                if (payload.args.level === 3) {
                    const postIndex = state.userPosts?.findIndex(item => item?.id === payload?.args?.postId);
                    const parentCommentIndex = state.userPosts[postIndex]?.commentsList?.findIndex(
                        item => item?.id === payload?.args?.parentCommentId
                    );
                    const level1CommentIndex = state.userPosts[postIndex]?.commentsList[
                        parentCommentIndex
                    ].commentsReplyList?.findIndex(item => item?.id === payload?.args?.level1CommentId);
                    const commentList =
                        state.userPosts[postIndex].commentsList[parentCommentIndex].commentsReplyList[
                            level1CommentIndex
                        ].commentsReplyList;
                    state.userPosts[postIndex].commentsList[parentCommentIndex].commentsReplyList[
                        level1CommentIndex
                    ].commentsReplyList = updateComment(commentList, {
                        id: payload.args.commentId,
                        isLoadingReplyComments: true,
                        commentsReplyPage: payload.args.page,
                        reload: payload.args.reload,
                    });
                    break;
                }
            }
            break;
        }
        case feedActions.GET_REPLY_COMMENTS.SUCCEEDED: {
            if (payload?.args?.isProfile) {
                if (payload.args.level === 1) {
                    const postIndex = state.userPosts?.findIndex(item => item?.id === payload?.args?.postId);
                    const commentList = state.userPosts[postIndex].commentsList;
                    const replyCommentList = data.list.reverse();
                    state.userPosts[postIndex].commentsList = updateComment(commentList, {
                        id: payload.args.commentId,
                        isLoadingReplyComments: false,
                        commentsReplyList: replyCommentList,
                    });
                    break;
                }
                if (payload.args.level === 2) {
                    const postIndex = state.userPosts?.findIndex(item => item?.id === payload?.args?.postId);
                    const parentCommentIndex = state.userPosts[postIndex]?.commentsList?.findIndex(
                        item => item?.id === payload?.args?.parentCommentId
                    );
                    const commentList = state.userPosts[postIndex].commentsList[parentCommentIndex].commentsReplyList;
                    const replyCommentList = data.list.reverse();
                    state.userPosts[postIndex].commentsList[parentCommentIndex].commentsReplyList = updateComment(
                        commentList,
                        {
                            id: payload.args.commentId,
                            isLoadingReplyComments: false,
                            commentsReplyList: replyCommentList,
                        }
                    );
                    break;
                }
                if (payload.args.level === 3) {
                    const postIndex = state.userPosts?.findIndex(item => item?.id === payload?.args?.postId);
                    const parentCommentIndex = state.userPosts[postIndex]?.commentsList?.findIndex(
                        item => item?.id === payload?.args?.parentCommentId
                    );
                    const level1CommentIndex = state.userPosts[postIndex]?.commentsList[
                        parentCommentIndex
                    ].commentsReplyList?.findIndex(item => item?.id === payload?.args?.level1CommentId);
                    const commentList =
                        state.userPosts[postIndex].commentsList[parentCommentIndex].commentsReplyList[
                            level1CommentIndex
                        ].commentsReplyList;
                    const replyCommentList = data?.list.reverse();
                    state.userPosts[postIndex].commentsList[parentCommentIndex].commentsReplyList[
                        level1CommentIndex
                    ].commentsReplyList = updateComment(commentList, {
                        id: payload.args.commentId,
                        isLoadingReplyComments: false,
                        commentsReplyList: replyCommentList,
                    });
                    break;
                }
                break;
            }

            break;
        }

        case feedActions.REPLY_TO_COMMENTS.SUCCEEDED: {
            if (payload.args.isProfile) {
                if (payload.args.level === 1) {
                    state.replyLevel1.newReplyCommentDataToUploadImage = payload?.data;
                    const postIndex = state.userPosts?.findIndex(item => item?.id === payload?.args.postId);
                    const commentIndex = state.userPosts[postIndex]?.commentsList?.findIndex(
                        item => item?.id === payload.args?.commentId
                    );
                    if (payload?.args?.isImageUpload) break;
                    state.userPosts[postIndex].commentsList[commentIndex].commentsReplyList.push(payload?.data);
                    break;
                }
                if (payload.args.level === 2) {
                    state.replyLevel2.newReplyCommentDataToUploadImage = payload?.data;

                    const postIndex = state.userPosts?.findIndex(item => item?.id === payload?.args?.postId);
                    const parentCommentIndex = state.userPosts[postIndex]?.commentsList?.findIndex(
                        item => item?.id === payload.args.parentCommentId
                    );
                    const commentIndex = state.userPosts[postIndex]?.commentsList[
                        parentCommentIndex
                    ]?.commentsReplyList?.findIndex(item => item?.id === payload.args?.commentId);
                    if (payload?.args?.isImageUpload) break;
                    state.userPosts[postIndex].commentsList[parentCommentIndex].commentsReplyList[
                        commentIndex
                    ].commentsReplyList.push(payload?.data);
                    break;
                }
                if (payload.args.level === 3) {
                    state.replyLevel3.newReplyCommentDataToUploadImage = payload?.data;

                    const postIndex = state.userPosts?.findIndex(item => item?.id === payload?.args?.postId);
                    const parentCommentIndex = state.userPosts[postIndex]?.commentsList?.findIndex(
                        item => item?.id === payload.args.parentCommentId
                    );
                    const level1CommentIndex = state.userPosts[postIndex]?.commentsList[
                        parentCommentIndex
                    ]?.commentsReplyList?.findIndex(item => item?.id === payload.args?.level1CommentId);
                    const commentIndex = state.userPosts[postIndex]?.commentsList[
                        parentCommentIndex
                    ]?.commentsReplyList[level1CommentIndex]?.commentsReplyList.findIndex(
                        item => item?.id === payload.args?.commentId
                    );
                    if (payload?.args?.isImageUpload) return;
                    state.userPosts[postIndex].commentsList[parentCommentIndex].commentsReplyList[
                        level1CommentIndex
                    ].commentsReplyList[commentIndex].commentsReplyList.push(payload?.data);
                    break;
                }
            }
            break;
        }
        case feedActions.UPLOAD_REPLY_COMMENT_PHOTO.SUCCEEDED: {
            if (payload.args.isProfile) {
                if (payload.args.level === 1) {
                    const postIndex = state.userPosts?.findIndex(item => item?.id === payload?.args.postId);
                    const commentIndex = state.userPosts[postIndex]?.commentsList?.findIndex(
                        item => item?.id === payload.args?.commentId
                    );
                    if (state.replyLevel1.newReplyCommentDataToUploadImage) {
                        state.replyLevel1.newReplyCommentDataToUploadImage.photo = [{ key: payload?.data?.fileId }];
                        state.userPosts[postIndex].commentsList[commentIndex].commentsReplyList.push(
                            state.replyLevel1.newReplyCommentDataToUploadImage
                        );
                    }
                    state.replyLevel1.newReplyCommentDataToUploadImage = {};
                    break;
                }
                if (payload.args.level === 2) {
                    const postIndex = state.userPosts?.findIndex(item => item?.id === payload?.args?.postId);
                    const parentCommentIndex = state.userPosts[postIndex]?.commentsList?.findIndex(
                        item => item?.id === payload.args.parentCommentId
                    );
                    const commentIndex = state.userPosts[postIndex]?.commentsList[
                        parentCommentIndex
                    ]?.commentsReplyList?.findIndex(item => item?.id === payload.args?.commentId);
                    if (state.replyLevel2.newReplyCommentDataToUploadImage) {
                        state.replyLevel2.newReplyCommentDataToUploadImage.photo = [{ key: payload?.data?.fileId }];
                        state.userPosts[postIndex].commentsList[parentCommentIndex].commentsReplyList[
                            commentIndex
                        ].commentsReplyList.push(state.replyLevel2.newReplyCommentDataToUploadImage);
                    }
                    state.replyLevel2.newReplyCommentDataToUploadImage = {};
                    break;
                }
                if (payload.args.level === 3) {
                    const postIndex = state.userPosts?.findIndex(item => item?.id === payload?.args?.postId);
                    const parentCommentIndex = state.userPosts[postIndex]?.commentsList?.findIndex(
                        item => item?.id === payload.args.parentCommentId
                    );
                    const level1CommentIndex = state.userPosts[postIndex]?.commentsList[
                        parentCommentIndex
                    ]?.commentsReplyList?.findIndex(item => item?.id === payload.args?.level1CommentId);
                    const commentIndex = state.userPosts[postIndex]?.commentsList[
                        parentCommentIndex
                    ]?.commentsReplyList[level1CommentIndex]?.commentsReplyList.findIndex(
                        item => item?.id === payload.args?.commentId
                    );
                    if (state.replyLevel3.newReplyCommentDataToUploadImage) {
                        state.replyLevel3.newReplyCommentDataToUploadImage.photo = [{ key: payload?.data?.fileId }];
                        state.userPosts[postIndex].commentsList[parentCommentIndex].commentsReplyList[
                            level1CommentIndex
                        ].commentsReplyList[commentIndex].commentsReplyList.push(
                            state.replyLevel3.newReplyCommentDataToUploadImage
                        );
                    }
                    state.replyLevel3.newReplyCommentDataToUploadImage = {};
                    break;
                }
            }

            break;
        }

        case feedActions.EDIT_REPLY_COMMENTS.SUCCEEDED: {
            if (payload.args.isProfile) {
                if (payload.args.level === 1) {
                    const postIndex = state.userPosts?.findIndex(item => item?.id === payload?.args?.postId);
                    const parentCommentIndex = state.userPosts[postIndex]?.commentsList?.findIndex(
                        item => item?.id === payload.args?.parentCommentId
                    );
                    const commentIndex = state.userPosts[postIndex]?.commentsList[
                        parentCommentIndex
                    ]?.commentsReplyList.findIndex(item => item?.id === payload.args?.commentId);
                    if (payload?.args?.isImageUpload) {
                        if (state.replyLevel1.editReplyCommentImageFile) {
                            state.userPosts[postIndex].commentsList[parentCommentIndex].commentsReplyList[
                                commentIndex
                            ].photo.push({ key: state.replyLevel1.editReplyCommentImageFile });
                        }
                        state.replyLevel1.editReplyCommentImageFile = '';
                    }
                    state.userPosts[postIndex]?.commentsList[parentCommentIndex].commentsReplyList.splice(
                        commentIndex,
                        1,
                        data
                    );
                    break;
                }
                if (payload.args.level === 2) {
                    const postIndex = state.userPosts?.findIndex(item => item?.id === payload?.args?.postId);
                    const parentCommentIndex = state.userPosts[postIndex]?.commentsList?.findIndex(
                        item => item?.id === payload.args?.parentCommentId
                    );
                    const level1CommentIndex = state.userPosts[postIndex]?.commentsList[
                        parentCommentIndex
                    ]?.commentsReplyList.findIndex(item => item?.id === payload.args?.level1CommentId);
                    const commentIndex = state.userPosts[postIndex]?.commentsList[
                        parentCommentIndex
                    ]?.commentsReplyList[level1CommentIndex].commentsReplyList.findIndex(
                        item => item?.id === payload.args?.commentId
                    );
                    if (payload?.args?.isImageUpload) {
                        if (state.replyLevel2.editReplyCommentImageFile) {
                            state.userPosts[postIndex]?.commentsList[parentCommentIndex].commentsReplyList[
                                level1CommentIndex
                            ].commentsReplyList[commentIndex].photo.push({
                                key: state.replyLevel2.editReplyCommentImageFile,
                            });
                        }
                        state.replyLevel2.editReplyCommentImageFile = '';
                    }
                    state.userPosts[postIndex]?.commentsList[parentCommentIndex].commentsReplyList[
                        level1CommentIndex
                    ].commentsReplyList.splice(commentIndex, 1, data);
                    break;
                }
                if (payload.args.level === 3) {
                    const postIndex = state.userPosts?.findIndex(item => item?.id === payload?.args?.postId);
                    const parentCommentIndex = state.userPosts[postIndex]?.commentsList?.findIndex(
                        item => item?.id === payload.args?.parentCommentId
                    );
                    const level1CommentIndex = state.userPosts[postIndex]?.commentsList[
                        parentCommentIndex
                    ]?.commentsReplyList.findIndex(item => item?.id === payload.args?.level1CommentId);
                    const level2CommentIndex = state.userPosts[postIndex]?.commentsList[
                        parentCommentIndex
                    ]?.commentsReplyList[level1CommentIndex].commentsReplyList.findIndex(
                        item => item?.id === payload.args?.level2CommentId
                    );
                    const commentIndex = state.userPosts[postIndex]?.commentsList[
                        parentCommentIndex
                    ]?.commentsReplyList[level1CommentIndex].commentsReplyList[
                        level2CommentIndex
                    ].commentsReplyList.findIndex(item => item?.id === payload.args?.commentId);
                    if (payload?.args?.isImageUpload) {
                        if (state.replyLevel3.editReplyCommentImageFile) {
                            state.userPosts[postIndex]?.commentsList[parentCommentIndex].commentsReplyList[
                                level1CommentIndex
                            ].commentsReplyList[level2CommentIndex].commentsReplyList[commentIndex].photo.push({
                                key: state.replyLevel3.editReplyCommentImageFile,
                            });
                        }
                        state.replyLevel3.editReplyCommentImageFile = '';
                    }
                    state.userPosts[postIndex]?.commentsList[parentCommentIndex].commentsReplyList[
                        level1CommentIndex
                    ].commentsReplyList[level2CommentIndex].commentsReplyList.splice(commentIndex, 1, data);
                    break;
                }
            }
            break;
        }

        case feedActions.DELETE_REPLY_COMMENT_PHOTO.SUCCEEDED: {
            if (payload.args.isProfile) {
                if (payload.args.level === 1) {
                    const postIndex = state.userPosts?.findIndex(item => item?.id === payload?.args?.postId);
                    const parentCommentIndex = state.userPosts[postIndex]?.commentsList?.findIndex(
                        item => item?.id === payload.args?.parentCommentId
                    );
                    const commentIndex = state.userPosts[postIndex]?.commentsList[
                        parentCommentIndex
                    ]?.commentsReplyList.findIndex(item => item?.id === payload.args?.commentId);
                    state.userPosts[postIndex].commentsList[parentCommentIndex].commentsReplyList[
                        commentIndex
                    ].photo = [];
                }
                if (payload.args.level === 2) {
                    state.replyLevel2.deleteReplyCommentPhotoSuccess = true;
                    const postIndex = state.userPosts?.findIndex(item => item?.id === payload?.args?.postId);
                    const parentCommentIndex = state.userPosts[postIndex]?.commentsList?.findIndex(
                        item => item?.id === payload.args?.parentCommentId
                    );
                    const level1CommentIndex = state.userPosts[postIndex]?.commentsList[
                        parentCommentIndex
                    ]?.commentsReplyList.findIndex(item => item?.id === payload.args?.level1CommentId);
                    const commentIndex = state.userPosts[postIndex]?.commentsList[
                        parentCommentIndex
                    ]?.commentsReplyList[level1CommentIndex].commentsReplyList.findIndex(
                        item => item?.id === payload.args?.commentId
                    );
                    state.userPosts[postIndex].commentsList[parentCommentIndex].commentsReplyList[
                        level1CommentIndex
                    ].commentsReplyList[commentIndex].photo = [];
                    break;
                }
                if (payload.args.level === 3) {
                    state.replyLevel3.deleteReplyCommentPhotoSuccess = true;
                    const postIndex = state.userPosts?.findIndex(item => item?.id === payload?.args?.postId);
                    const parentCommentIndex = state.userPosts[postIndex]?.commentsList?.findIndex(
                        item => item?.id === payload.args?.parentCommentId
                    );
                    const level1CommentIndex = state.userPosts[postIndex]?.commentsList[
                        parentCommentIndex
                    ]?.commentsReplyList.findIndex(item => item?.id === payload.args?.level1CommentId);
                    const level2CommentIndex = state.userPosts[postIndex]?.commentsList[
                        parentCommentIndex
                    ]?.commentsReplyList[level1CommentIndex].commentsReplyList.findIndex(
                        item => item?.id === payload.args?.level2CommentId
                    );
                    const commentIndex = state.userPosts[postIndex]?.commentsList[
                        parentCommentIndex
                    ]?.commentsReplyList[level1CommentIndex].commentsReplyList[
                        level2CommentIndex
                    ].commentsReplyList.findIndex(item => item?.id === payload.args?.commentId);
                    state.userPosts[postIndex].commentsList[parentCommentIndex].commentsReplyList[
                        level1CommentIndex
                    ].commentsReplyList[level2CommentIndex].commentsReplyList[commentIndex].photo = [];
                    break;
                }
            }
            break;
        }

        case feedActions.UPLOAD_REPLY_COMMENT_PHOTO_UPDATE.SUCCEEDED: {
            if (payload.args.isProfile) {
                if (payload.args.level === 1) {
                    state.replyLevel1.editReplyCommentImageFile = data.fileId;
                }
                if (payload.args.level === 2) {
                    state.replyLevel2.editReplyCommentImageFile = data.fileId;
                }
                if (payload.args.level === 3) {
                    state.replyLevel3.editReplyCommentImageFile = data.fileId;
                }
            }

            break;
        }

        case feedActions.ADD_NEW_COMMENTS.SUCCEEDED: {
            if (payload?.args?.isProfile) {
                const postIndex = state.userPosts?.findIndex(item => item?.id === payload?.args?.postId);
                state.userPosts[postIndex].commentsList = [];
                state.userPosts[postIndex].comments += 1;
            }

            break;
        }
        case feedActions.EDIT_COMMENTS.SUCCEEDED: {
            if (payload?.args?.isProfile) {
                const postIndex = state.userPosts?.findIndex(item => item?.id === payload?.args?.postId);
                const commentIndex = state.userPosts[postIndex]?.commentsList?.findIndex(
                    item => item?.id === payload.args?.commentId
                );
                state.userPosts[postIndex]?.commentsList.splice(commentIndex, 1, data);
            }
            break;
        }
        case feedActions.DELETE_COMMENTS.SUCCEEDED: {
            if (payload?.args?.isProfile) {
                const postIndex = state.userPosts?.findIndex(item => item?.id === payload?.args?.postId);
                const commentIndex = state.userPosts[postIndex]?.commentsList?.findIndex(
                    item => item?.id === payload.args?.commentId
                );
                state.userPosts[postIndex]?.commentsList.splice(commentIndex, 1);
                state.userPosts[postIndex].comments -= 1;
            }

            break;
        }
        case feedActions.HIDE_COMMENTS: {
            if (payload.isProfile) {
                const clearComments = true;
                state.userPosts = updatePost(
                    state.userPosts,
                    {
                        id: payload.postId,
                        showComments: false,
                    },
                    clearComments
                );
            }

            break;
        }
        case feedActions.PLAY_VIDEO: {
            if (payload.isProfile) {
                state.userPosts = updatePost(state.userPosts, {
                    id: payload.postId,
                    playVideo: true,
                });
            }
            break;
        }
        case feedActions.PLAY_COMMENT_VIDEO: {
            if (payload.isProfile) {
                const postIndex = state.userPosts?.findIndex(item => item?.id === payload?.postId);
                const commentIndex = state.userPosts[postIndex]?.commentsList?.findIndex(
                    item => item?.id === payload?.commentId
                );
                const currentComment = state.userPosts[postIndex]?.commentsList[commentIndex];
                currentComment.playVideo = true;
                state.userPosts[postIndex]?.commentsList.splice(commentIndex, currentComment);
            }
            break;
        }
        case feedActions.DELETE_REPLY_COMMENTS.SUCCEEDED: {
            if (payload.args?.isProfile) {
                if (payload.args.level === 1) {
                    const postIndex = state.userPosts?.findIndex(item => item?.id === payload?.args.postId);
                    const parentCommentIndex = state.userPosts[postIndex]?.commentsList?.findIndex(
                        item => item?.id === payload.args?.parentCommentId
                    );
                    const commentIndex = state.userPosts[postIndex]?.commentsList[
                        parentCommentIndex
                    ]?.commentsReplyList.findIndex(item => item?.id === payload.args?.commentId);
                    state.userPosts[postIndex]?.commentsList[parentCommentIndex].commentsReplyList.splice(
                        commentIndex,
                        1
                    );
                    break;
                }
                if (payload.args.level === 2) {
                    const postIndex = state.userPosts?.findIndex(item => item?.id === payload?.args.postId);
                    const parentCommentIndex = state.userPosts[postIndex]?.commentsList?.findIndex(
                        item => item?.id === payload.args?.parentCommentId
                    );
                    const level1CommentIndex = state.userPosts[postIndex]?.commentsList[
                        parentCommentIndex
                    ]?.commentsReplyList.findIndex(item => item?.id === payload.args?.level1CommentId);
                    const commentIndex = state.userPosts[postIndex]?.commentsList[
                        parentCommentIndex
                    ]?.commentsReplyList[level1CommentIndex].commentsReplyList.findIndex(
                        item => item?.id === payload.args?.commentId
                    );
                    state.userPosts[postIndex]?.commentsList[parentCommentIndex].commentsReplyList[
                        level1CommentIndex
                    ].commentsReplyList.splice(commentIndex, 1);
                    break;
                }
                if (payload.args.level === 3) {
                    const postIndex = state.userPosts?.findIndex(item => item?.id === payload?.args.postId);
                    const parentCommentIndex = state.userPosts[postIndex]?.commentsList?.findIndex(
                        item => item?.id === payload.args?.parentCommentId
                    );
                    const level1CommentIndex = state.userPosts[postIndex]?.commentsList[
                        parentCommentIndex
                    ]?.commentsReplyList.findIndex(item => item?.id === payload.args?.level1CommentId);
                    const level2CommentIndex = state.userPosts[postIndex]?.commentsList[
                        parentCommentIndex
                    ]?.commentsReplyList[level1CommentIndex].commentsReplyList.findIndex(
                        item => item?.id === payload.args?.level2CommentId
                    );
                    const commentIndex = state.userPosts[postIndex]?.commentsList[
                        parentCommentIndex
                    ]?.commentsReplyList[level1CommentIndex].commentsReplyList[
                        level2CommentIndex
                    ].commentsReplyList.findIndex(item => item?.id === payload.args?.commentId);
                    state.userPosts[postIndex]?.commentsList[parentCommentIndex].commentsReplyList[
                        level1CommentIndex
                    ].commentsReplyList[level2CommentIndex].commentsReplyList.splice(commentIndex, 1);
                    break;
                }
            }
            break;
        }

        default:
            return state;
    }

    return state;
});

export default reducer;
