import { requestActions } from '../actionsCreator';
import { requestApiCall } from '../connectivity/connectivityActions';
import { MODULE_NAME } from './statusConstants';
import api from '../../api';

/**
 * Constants
 */
export const GET_STATUS = requestActions(MODULE_NAME, 'GET_STATUS');

/**
 * Creators
 */
export const getStatus = () => requestApiCall(api.names.getStatus, { }, GET_STATUS);
