import { combineReducers } from 'redux';
import * as connectivity from './connectivity';
import * as auth from './auth';
import * as router from './router';
import * as user from './user';
import * as feed from './feed';
import * as twoFactor from './twoFactor';
import * as notifications from './notification';
import * as coins from './coins';
import * as profile from './profile';
import * as search from './search';
import * as followers from './followers';
import * as companies from './companies';
import * as groups from './groups';
import * as album from './album';
import * as shortVideo from './shortVideo';
import * as status from './status';
import * as communication from './communication';

export default {
    connectivity,
    auth,
    user,
    feed,
    twoFactor,
    router,
    notifications,
    profile,
    search,
    followers,
    groups,
    album,
    shortVideo,
    status,
    communication
};

export const createRootReducer = history =>
    combineReducers({
        connectivity: connectivity.reducers.reducer,
        auth: auth.reducers.reducer,
        user: user.reducers.reducer,
        feed: feed.reducers.reducer,
        router: router.reducers.reducer(history),
        twoFactor: twoFactor.reducers.reducer,
        coins: coins.reducers.reducer,
        notifications: notifications.reducers.reducer,
        profile: profile.reducers.reducer,
        search: search.reducers.reducer,
        followers: followers.reducers.reducer,
        companies: companies.reducers.reducer,
        groups: groups.reducers.reducer,
        album: album.reducers.reducer,
        shortVideo: shortVideo.reducers.reducer,
        status: status.reducers.reducer,
        communication: communication.reducers.reducer
    });
