import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import styles from './LoginWrapper.module.scss';
import logo from '../../assets/logo_login.svg';
import { Footer } from '../Footer/Footer';
import { Span } from '../controls';

export const LoginWrapper = props => {
    const { children, onGoToLogin } = props;

    return (
        <Fragment>
            <div className={styles.login}>
                <div className={styles.loginDetails}>
                    <div className="grid-x grid-padding-x">
                        <div className="cell auto show-for-large" />
                        <div className={`${styles.fixed}`}>
                            <div className="grid-x grid-padding-x">
                                <div className="cell medium-6">
                                    <img onClick={onGoToLogin} src={logo} className={styles.logo} alt="Logo" />
                                    <div className={styles.headline}>
                                        <Span className={styles.title}>LoginWrapper.headlineTitle</Span>
                                        <Span className={styles.text}>LoginWrapper.headlineText</Span>
                                    </div>
                                </div>
                                <div className={`${styles.loginInner} cell medium-6`}>{children}</div>
                            </div>
                        </div>
                        <div className="cell auto show-for-large" />
                    </div>
                </div>

                <Footer openLinksInNewTab={true} />
            </div>
        </Fragment>
    );
};

LoginWrapper.propTypes = {
    children: PropTypes.any,
    onGoToLogin: PropTypes.func,
};
